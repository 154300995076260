import { Injectable } from "@angular/core";
import { Http, ResponseContentType } from "@angular/http";
import { Observable } from "rxjs/Observable";

import { PanierCommandeDuplicata2ndExemplaire } from "./models/panier-commande-duplicata-2nd-exemplaire";
import { ReponseCalculCommandeDuplicata2ndExemplaire } from "./models/reponse-calcul-commande-duplicata-2nd-exemplaire";
import { ReponseCommandeDuplicata2ndExemplaire } from "./models/reponse-commande-duplicata-2nd-exemplaire";
import { Tarification } from "./models/tarification";
import { Titre } from "./models/titre";

@Injectable()
export class DemandeService {
  private panier: PanierCommandeDuplicata2ndExemplaire = null;

  constructor(private http: Http) {}

  setPanier(panier: PanierCommandeDuplicata2ndExemplaire) {
    this.panier = panier;
  }

  getPanier(): PanierCommandeDuplicata2ndExemplaire {
    return this.panier;
  }

  /**
   * Méthode à appeler pour récupérer les informations de tarification
   */
  tarification(): Promise<Tarification> {
    console.log("[DemandeService] tarification");
    return new Promise((resolve, reject) => {
      this.http
        .get("/api/organismeBeneficiaire/duplicata/tarification")
        .subscribe(
          (response) => {
            if (response.status !== 200) {
              reject(`Pas de tarification, statut ${response.status}`);
            } else {
              resolve(response.json());
            }
          },
          (err) => reject(err)
        );
    });
  }

  /**
   * Méthode à appeler pour récupérer la liste des titres éligibles pour une commande de duplicata
   */
  titreEligibleDuplicata(identifiantBeneficiaire: string): Observable<Titre[]> {
    console.log(
      "[DemandeService] titreEligibleDuplicata",
      identifiantBeneficiaire
    );
    return this.http
      .get(
        `/api/organismeBeneficiaire/duplicata/titreEligibleDuplicata/${identifiantBeneficiaire}`
      )
      .map((response) => response.json() as Titre[]);
  }

  /**
   * Méthode à appeler pour récupérer la liste des titres éligibles pour une commande de second exemplaire
   */
  titreEligibleSecondExemplaire(
    identifiantBeneficiaire: string
  ): Observable<Titre[]> {
    console.log(
      "[DemandeService] titreEligibleSecondExemplaire",
      identifiantBeneficiaire
    );
    return this.http
      .get(
        `/api/organismeBeneficiaire/duplicata/titreEligible2ndExemplaire/${identifiantBeneficiaire}`
      )
      .map((response) => response.json() as Titre[]);
  }

  /**
   * Méthode à appeler pour récupérer le montant d'une commande de duplicata ou de second exemplaire
   */
  calculerMontantCommande(
    identifiantBeneficiaire: string,
    panier: PanierCommandeDuplicata2ndExemplaire
  ): Promise<ReponseCalculCommandeDuplicata2ndExemplaire> {
    console.log(
      "[DemandeService] calculerMontantCommande",
      identifiantBeneficiaire,
      panier
    );
    return new Promise((resolve, reject) => {
      this.http
        .post(
          `/api/organismeBeneficiaire/duplicata/calculerMontantCommande/${identifiantBeneficiaire}`,
          panier
        )
        .subscribe(
          (response) => {
            if (response.status !== 200) {
              reject(`Pas de calcul montant, statut ${response.status}`);
            } else {
              resolve(response.json());
            }
          },
          (err) => reject(err)
        );
    });
  }

  /**
   * Méthode à appeler pour récupérer les informations de la commande
   */
  getCommande(
    identifiantCommande: string
  ): Observable<ReponseCommandeDuplicata2ndExemplaire> {
    console.log("[DemandeService] getCommande", identifiantCommande);
    return this.http
      .get(
        `/api/organismeBeneficiaire/duplicata/getCommande/${identifiantCommande}`
      )
      .map(
        (response) => response.json() as ReponseCommandeDuplicata2ndExemplaire
      );
  }

  /**
   * Méthode à appeler pour initialiser le règlement par chèque
   */
  effectuerPaiementCheque(
    identifiantBeneficiaire: string,
    panier: PanierCommandeDuplicata2ndExemplaire
  ): Promise<ReponseCommandeDuplicata2ndExemplaire> {
    console.log(
      "[DemandeService] effectuerPaiementCheque",
      identifiantBeneficiaire,
      panier
    );
    return this.http
      .post(`/api/front/effectuerPaiement/${identifiantBeneficiaire}`, panier)
      .toPromise()
      .then(
        (response) => response.json() as ReponseCommandeDuplicata2ndExemplaire
      );
  }

  /**
   * Méthode à appeler pour récupèrer le coupon de règlement par chèque
   */
  couponReglementCheque(identifiantCommande: number) {
    console.log("[DemandeService] couponReglementCheque", identifiantCommande);
    return this.http
      .get(`/api/front/couponReglementCheque/${identifiantCommande}`, {
        responseType: ResponseContentType.Blob,
      })
      .toPromise();
  }

  /**
   * Méthode à appeler pour récupèrer l'attestation de la demande
   */
  getAttestation(identifiantCommande: number) {
    console.log("[DemandeService] getAttestation", identifiantCommande);
    return this.http
      .get(`/api/front/uploadAttestation/${identifiantCommande}`, {
        responseType: ResponseContentType.Blob,
      })
      .toPromise();
  }
}
