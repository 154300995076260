import {
  AfterContentInit,
  Component,
  ContentChildren,
  Input,
  QueryList,
} from "@angular/core";

import { TabComponent } from "./tab/tab.component";

@Component({
  selector: "in-tabs",
  templateUrl: "./tabs.component.html",
  styles: [".clickable { cursor: pointer; }"],
})
export class TabsComponent implements AfterContentInit {
  @ContentChildren(TabComponent) tabs: QueryList<TabComponent>;

  @Input() clickable: boolean = false;

  ngAfterContentInit() {
    const activeTabs = this.tabs.filter((tab) => tab.active);

    if (activeTabs.length === 0) {
      this.activeTab(this.tabs.first);
    }
  }

  clickTab(t: TabComponent) {
    if (this.clickable) {
      this.activeTab(t);
    }
  }

  activeTab(t: TabComponent) {
    let trouve = false;
    this.tabs.toArray().forEach((tab) => {
      tab.active = false;
      if (t.titre === tab.titre) {
        trouve = true;
        tab.active = true;
      }
      tab.past = !trouve;
    });
  }

  activeTabByName(s: string) {
    this.activeTab(this.tabs.filter((t) => t.titre === s).pop());
  }

  activeOrCloseTabByName(s: string) {
    if (this.tabs.filter((t) => t.titre === s).pop().active) {
      this.closeTabs();
    } else {
      this.activeTabByName(s);
    }
  }

  closeTabs() {
    this.tabs.toArray().forEach((tab) => {
      tab.active = false;
      tab.past = true;
    });
  }
}
