import {
  AfterViewChecked,
  Component,
  OnInit,
  Renderer2,
  ViewChild,
} from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { PropertiesService } from "../authentification/properties.service";
import { TabsComponent } from "../common/components/tabs/tabs.component";
import { FaqService } from "./faq.service";
import { Question } from "./models/question";

declare var $: any;

@Component({
  selector: "in-faq",
  templateUrl: "./faq.component.html",
})
export class FaqComponent implements OnInit, AfterViewChecked {
  @ViewChild(TabsComponent) tabs;
  public questionsPP: Question[] = [];
  public questionsPM: Question[] = [];
  private faqChargee: boolean = false;
  public zfeEnabled: boolean = false;

  public constructor(
    private faqService: FaqService,
    private props: PropertiesService,
    private route: ActivatedRoute,
    private renderer: Renderer2
  ) {}

  public ngOnInit() {
    // get props first
    this.props.getValeur("zfeEnabled").then((zfeEnabled) => {
      // get faq
      this.faqService.questions().subscribe((data) => {
        this.questionsPP = data["questionfaqs"].filter(
          (q) =>
            q.categorie === "PERSONNE_PHYSIQUE" &&
            (q.zfe == false || (!!zfeEnabled && q.zfe))
        );
        this.questionsPP.sort((a, b) =>
          a.order > b.order ? 1 : a.order === b.order ? 0 : -1
        );
        this.questionsPM = data["questionfaqs"].filter(
          (q) =>
            q.categorie === "PERSONNE_MORALE" &&
            (q.zfe == false || (!!zfeEnabled && q.zfe))
        );
        this.questionsPM.sort((a, b) =>
          a.order > b.order ? 1 : a.order === b.order ? 0 : -1
        );
        this.faqChargee = true;
      });
    });
    // pour catcher les liens internes dans la FAQ, stockés comme suit dans la FAQ :
    // <a class='internlink cdsds'>text faq bla bla</a>
    // "internlink" pour détecter le lien et "cdsds" est l'ancre vers laquelle il faut rediriger le user
    this.renderer.listen("document", "click", (event) => {
      let elt = event.target as HTMLElement;
      if (elt.classList.contains("internlink")) {
        this.gotoAncre(elt.classList[1].toString());
      }
    });
  }

  public ngAfterViewChecked() {
    if (this.faqChargee) {
      this.faqChargee = false;
      $(".js-accordion").accordion();

      // ancre ?
      if (this.route.snapshot.fragment) {
        this.gotoAncre(this.route.snapshot.fragment);
      }
    }
  }

  // le code "accordion" attribue des id par défaut afin de lier div et button
  // en donnant un id au div questions, le code "accordion" attribut un id
  // au button associé à la div égal à l'id du div + "_tab"
  // id div = "abc" > id button = "abc_tab"
  gotoAncre(ancre: string) {
    let buttonId: string = "#q" + ancre + "_tab";
    $(buttonId).click();
    //$(buttonId).setAttribute("aria-expanded", "true");
    window.scrollTo(0, document.body.scrollHeight); // scroll down
  }

  getQuestionId(q: Question): string {
    if (q.ancre) {
      return "q" + q.ancre;
    } else {
      return "";
    }
  }

  activerTab(lien: any, tabFAQ: string) {
    this.tabs.activeOrCloseTabByName(tabFAQ);
    $(".tabs-liens-faq").find("a").removeClass("active");
    $("#" + lien).addClass("active");
  }

  transformReponse(reponse: string): string {
    let str = reponse.replace(
      new RegExp("__LIMITE_VEHICULES_ACTIFS__", "g"),
      "3"
    );
    str = str.replace(new RegExp("__LIMITE_NOMBRE_VEHICULES__", "g"), "5");
    return str;
  }
}
