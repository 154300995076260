import { Component, Input } from "@angular/core";

import { AuthentificationService } from "src/app/authentification/authentification.service";
import { TabsConfig } from "src/app/authentification/models/tabs-config";

@Component({
  selector: "in-messages",
  templateUrl: "./messages.component.html",
})
export class MessagesComponent {
  public showingInfoValidityEnding: boolean = false;
  public showingWarningValidityEnding: boolean = false;
  public showingWarningCardExpired: boolean = false;
  public validityEndingDelay: number;
  public strValidityEndingDelay: string = "?";

  @Input()
  cardExpired: string;

  constructor(private authentificationService: AuthentificationService) {}

  // init
  ngOnInit() {
    // get conf
    const tabs: TabsConfig =
      this.authentificationService.getTabsConfigConnecte();
    if (tabs && tabs.vehiculesTab) {
      this.showingInfoValidityEnding =
        tabs.vehiculesTab.showingInfoValidityEndingBanner;
      this.showingWarningValidityEnding =
        tabs.vehiculesTab.showingWarningValidityEndingBanner;
      this.validityEndingDelay = tabs.vehiculesTab.validityEndingDelay;
      this.showingWarningCardExpired =
        this.cardExpired == "true" && tabs.vehiculesTab.readOnly;
      if (this.validityEndingDelay < 2) {
        this.strValidityEndingDelay = "moins d'un";
      } else {
        this.strValidityEndingDelay = "moins de " + this.validityEndingDelay;
      }
    }
  }
}
