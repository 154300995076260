import { Directive, forwardRef, Attribute } from "@angular/core";
import {
  Validator,
  AbstractControl,
  NG_VALIDATORS,
  ValidatorFn,
} from "@angular/forms";

/*
 * Méthode de validation.
 */
export function immatriculationValidator(pays: string): ValidatorFn {
  return function (c: AbstractControl): { [key: string]: boolean } {
    const v: string = c.value;
    if (v === "undefined" || v == null) return null;
    let immat = v.toUpperCase();
    const p = c.root.get(pays);
    if (p == null) return null;

    // country code
    // note: empty when pays is set on default value with no user changes..
    let countryCode = "F";
    if (p.value != "") countryCode = p.value;

    const departements = [
      "01",
      "02",
      "03",
      "04",
      "05",
      "06",
      "07",
      "08",
      "09",
      "10",
      "11",
      "12",
      "13",
      "14",
      "15",
      "16",
      "17",
      "18",
      "19",
      "20",
      "2A",
      "2B",
      "21",
      "22",
      "23",
      "24",
      "25",
      "26",
      "27",
      "28",
      "29",
      "30",
      "31",
      "32",
      "33",
      "34",
      "35",
      "36",
      "37",
      "38",
      "39",
      "40",
      "41",
      "42",
      "43",
      "44",
      "45",
      "46",
      "47",
      "48",
      "49",
      "50",
      "51",
      "52",
      "53",
      "54",
      "55",
      "56",
      "57",
      "58",
      "59",
      "60",
      "61",
      "62",
      "63",
      "64",
      "65",
      "66",
      "67",
      "68",
      "69",
      "70",
      "71",
      "72",
      "73",
      "74",
      "75",
      "76",
      "77",
      "78",
      "79",
      "80",
      "81",
      "82",
      "83",
      "84",
      "85",
      "86",
      "87",
      "88",
      "89",
      "90",
      "91",
      "92",
      "93",
      "94",
      "95",
      "971",
      "972",
      "973",
      "974",
      "975",
      "976",
      "977",
      "978",
      "984",
      "986",
      "987",
      "988",
      "989",
      "69D",
      "69M",
      "20R",
      "6AE",
      "75C",
      "972R",
      "973R",
      "976R",
    ];

    let correct = false;
    if (countryCode === "F") {
      // Format SIV de type AA-999-AA
      if (immat.match("^[A-Z]{2}-[0-9]{3}-[A-Z]{2}$")) {
        correct = true;
      }

      // Format 2/3 roues : 1 à 2 lettres _puis_ 2 à 3 chiffres _puis_ 1 lettre
      if (immat.match("^[A-Z]{1,2} [0-9]{2,3} [A-Z]{1}$")) {
        correct = true;
      }

      // Ancien format FNI : un à 4 chiffres _puis_ une à trois lettres _puis_ le code département
      if (immat.match("^[0-9]{1,4} [A-Z]{1,3} [0-9A-Z]{2,4}$")) {
        let dep = immat.split(" ")[2];
        for (let i = 0; i < departements.length; i++) {
          if (dep == departements[i]) correct = true;
        }
      }

      // Les lettres suivantes doivent être interdites à la saisie : I, O, U et SS
      if (immat.indexOf("I") != -1) correct = false;
      if (immat.indexOf("O") != -1) correct = false;
      if (immat.indexOf("U") != -1) correct = false;
      if (immat.indexOf("SS") != -1) correct = false;
    } else {
      // plaque étrangères
      //	- Minimum 1 caractères
      //	- Maximum 15 caractères
      //	- Uniquement Majuscules et chiffres [A-Z0-9]
      //	– Et espace autorisés mais pas en début et fin de chaîne
      //  - Ä Ö Ü autorisés
      if (immat.length >= 1 && immat.length <= 15) {
        correct = true;
        for (let i = 0; i < immat.length; i++) {
          let pattern = "[0-9A-Z -]{1}";
          if (i == 0 || i == immat.length - 1) {
            pattern = "[0-9A-Z]{1}";
          }
          if (!immat[i].match(pattern) && "ÄÖÜ".indexOf(immat[i]) == -1) {
            correct = false;
          }
        }
      }
      // OU
      // if immat.length == 1 : ^[0-9A-Z]{1}$
      // else : ^[0-9A-Z]{1}[0-9A-Z -]{0,13}[0-9A-Z]{1}$
    }

    if (!correct) {
      return {
        validateImmatriculation: false,
      };
    }
    return null;
  };
}

@Directive({
  selector:
    "[immatriculation][formControlName],[immatriculation][formControl],[immatriculation][ngModel]",
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => ImmatriculationValidator),
      multi: true,
    },
  ],
})
export class ImmatriculationValidator implements Validator {
  constructor(@Attribute("immatriculation") public immatriculation: string) {}

  validate(c: AbstractControl): { [key: string]: boolean } {
    return immatriculationValidator(this.immatriculation)(c);
  }
}
