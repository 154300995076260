import { NgModule } from "@angular/core";

import { ADroitDirective } from "./a-droit.directive";

@NgModule({
  imports: [],
  exports: [ADroitDirective],
  declarations: [ADroitDirective],
  providers: [],
})
export class ADroitModule {}
