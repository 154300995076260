import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { FranceConnectService } from "./franceconnect.service";

@Component({
  selector: "fc-logout-callback",
  template: "",
})
export class FCLogoutCallbackComponent implements OnInit {
  uiid: string;
  channel: string;
  state: string;

  constructor(
    private franceconnectService: FranceConnectService,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit() {
    this.uiid = this.route.snapshot.queryParamMap.get("uiid");
    this.channel = this.route.snapshot.queryParamMap.get("channel");
    this.state = this.route.snapshot.queryParamMap.get("state");

    if (this.franceconnectService.checkState(this.state)) {
      sessionStorage.clear();

      if (this.channel == this.franceconnectService.CHANNEL_PORTAL) {
        this.router.navigateByUrl("/");
      } else if (this.channel == this.franceconnectService.CHANNEL_MOBILE) {
        this.franceconnectService.returnLogoutJson(true, this.uiid);
      }
    }
  }
}
