import { Component, OnDestroy, OnInit } from "@angular/core";
import { Http } from "@angular/http";
import { Router } from "@angular/router";
import { Subscription } from "rxjs";

import { AuthentificationService } from "../../authentification/authentification.service";
import { Utilisateur } from "../../authentification/models/utilisateur";
import { DemandeService } from "../demande.service";
import { STEPS } from "../enums/steps";
import { GenericStepComponent } from "../generic-step.component";
import { Tarification } from "../models/tarification";

@Component({
  selector: "in-step-engagement",
  templateUrl: "./step-engagement.component.html",
})
export class StepEngagementComponent
  extends GenericStepComponent
  implements OnInit, OnDestroy
{
  public tarification: Tarification;
  public utilisateur: Utilisateur = null;

  public engagement = false;
  public soumis = false;

  utilisateurSubscription: Subscription;

  constructor(
    private router: Router,
    private http: Http,
    private authentificationService: AuthentificationService,
    private demandeService: DemandeService
  ) {
    super();
  }

  ngOnInit() {
    this.loadStep();

    this.utilisateurSubscription = this.authentificationService
      .utilisateurConnecte()
      .subscribe((utilisateur) => {
        this.utilisateur = utilisateur;
      });
  }

  public loadStep() {
    this.engagement = false;
    this.soumis = false;

    this.demandeService
      .tarification()
      .then((tarification) => {
        this.tarification = tarification;
        if (this.tarification != null) {
          console.log(
            "[StepEngagementComponent] tarification",
            this.tarification
          );
        }
      })
      .catch((err) => {
        console.log("[StepEngagementComponent] Erreur tarification", err);
        this.router.navigateByUrl("/erreurService");
      });
  }

  goToChoixDemande() {
    this.soumis = true;
    if (this.engagement) {
      this.onGoToStep.emit(STEPS.CHOIXDEMANDE);
    }
  }

  public get montantTarification() {
    if (this.tarification) {
      return Math.round(
        (this.tarification.montantTarifDuplicata2ndExemplaire * 1.2 +
          this.tarification.montantAcheminement) /
          100
      );
    } else {
      return 0;
    }
  }

  ngOnDestroy() {
    this.utilisateurSubscription.unsubscribe();
  }
}
