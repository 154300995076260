import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { BreadcrumbsModule } from "ng2-breadcrumbs";

import { RouterModule } from "@angular/router";
import { ADroitModule } from "../../directives/a-droit/a-droit.module";
import { HeaderComponent } from "./header.component";
import { NavigationComponent } from "./navigation/navigation.component";

@NgModule({
  imports: [CommonModule, BreadcrumbsModule, RouterModule, ADroitModule],
  exports: [HeaderComponent],
  declarations: [HeaderComponent, NavigationComponent],
  providers: [],
})
export class HeaderModule {}
