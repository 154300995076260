import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { AuthentificationService } from "../authentification.service";
import { FranceConnectService } from "./franceconnect.service";

@Component({
  selector: "fc-login-callback",
  template: "",
})
export class FCLoginCallbackComponent implements OnInit {
  channel: string;
  code: string;
  infos: string;
  state: string;
  nonce: string;
  id_token: string;
  uiid: string;

  constructor(
    private franceconnectService: FranceConnectService,
    private authentificationService: AuthentificationService,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit() {
    this.code = this.route.snapshot.queryParamMap.get("code");
    this.channel = this.route.snapshot.queryParamMap.get("channel");
    this.state = this.route.snapshot.queryParamMap.get("state");
    this.uiid = this.route.snapshot.queryParamMap.get("uiid");

    if (this.franceconnectService.checkState(this.state)) {
      this.franceconnectService.loadProps().then((ok) => {
        if (ok) {
          // get token
          this.franceconnectService.getToken(this.code, this.channel).subscribe(
            (res) => {
              if (res.status === 200 && res.body.access_token) {
                if (this.franceconnectService.checkNonce(res.body.id_token)) {
                  this.id_token = res.body.id_token;
                  sessionStorage.setItem("token_hint", res.body.id_token);

                  this.franceconnectService
                    .getUserInfo(res.body.access_token)
                    .subscribe((res) => {
                      if (
                        this.channel == this.franceconnectService.CHANNEL_PORTAL
                      ) {
                        // vérification connaissance du compte côté back IN
                        this.authentificationService
                          .getUtilisateurByFCId(res["sub"], res["email"])
                          .then((infcuser) => {
                            sessionStorage.setItem("fc_mail", res["email"]);
                            this.authentificationService.connectAndRedirect(
                              infcuser
                            );
                          })
                          .catch((err) => {
                            if (err.status == 404) {
                              sessionStorage.setItem(
                                "fc_infos",
                                JSON.stringify(res)
                              );
                              this.router.navigateByUrl("fc-numerotitre");
                            } else {
                              this.error();
                            }
                          });
                      } else if (
                        this.channel == this.franceconnectService.CHANNEL_MOBILE
                      ) {
                        this.franceconnectService.returnUserJson(
                          res,
                          this.id_token,
                          this.uiid
                        );
                      } else {
                        this.error();
                      }
                    });
                } else {
                  this.error();
                }
              } else {
                this.error();
              }
            },
            (_err) => {
              this.error();
            }
          );
        } else {
          if (this.channel == this.franceconnectService.CHANNEL_MOBILE) {
            this.franceconnectService.returnUserJson({}, null, this.uiid);
          } else {
            this.error();
          }
        }
      });
    } else {
      this.error();
    }
  }

  error() {
    sessionStorage.setItem("fcerror", "1");
    this.router.navigateByUrl("/");
  }
}
