import "rxjs/add/operator/map";
import "rxjs/add/operator/mergeMap";
import "rxjs/add/operator/toPromise";

import { Injectable } from "@angular/core";
import { Http } from "@angular/http";

import { AuthentificationService } from "../authentification/authentification.service";
import { Organisme } from "./models/organisme";

@Injectable()
export class AdministrateurService {
  constructor(
    private http: Http,
    private authentificationService: AuthentificationService
  ) {}

  /**
   * Méthode à appeler pour récupérer les informations de l'organisme
   */
  informationsOrganisme(): Promise<Organisme> {
    // identifiant de l'organisme présent dans le payload du token
    if (this.authentificationService.hasPayload()) {
      const payload: any = this.authentificationService.getPayload();
      const identifiantOrganisme: string = payload.organisme;
      console.log(
        "[AdministrateurService] identifiantOrganisme",
        identifiantOrganisme
      );
      return this.http
        .get(
          `/api/organismeBeneficiaire/organisme/code/${identifiantOrganisme}`
        )
        .toPromise()
        .then((response) => response.json() as Organisme);
    } else {
      const organismeParDefaut: Organisme = {
        nom: "MDPH59",
        adresse: {
          destinataire: "MDPH59",
          adresse1: "11 RUE DU PARC",
          adresse2: "PARC SAINT SULPICE",
          adresse3: "",
          adresse4: "",
          commune: "SECLIN",
          codePostal: "59113",
          departement: "Pas de calais",
          pays: "FRANCE",
        },
        signature: "",
        logo: "",
        recevoirNotification: false,
      };

      console.log("[AdministrateurService] Payload indéterminé.");
      return Promise.resolve(organismeParDefaut);
    }
  }
}
