import { Injectable } from "@angular/core";
import { CanActivate, Router } from "@angular/router";

import { AuthentificationService } from "./authentification.service";

@Injectable()
export class CanActivateUtilisateurConnecteGuard implements CanActivate {
  private isConnecte: boolean = false;

  constructor(
    private authService: AuthentificationService,
    private router: Router
  ) {
    this.authService
      .utilisateurConnecte()
      .subscribe((utilisateur) => (this.isConnecte = utilisateur != null));
  }

  canActivate() {
    if (!this.isConnecte) {
      this.router.navigateByUrl("/authentification");
      return false;
    }

    return true;
  }
}
