import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";

import { equalsValidator } from "../../common/validators/equals.validator";
import { notEqualsValidator } from "../../common/validators/not-equals.validator";
import { passwordValidator } from "../../common/validators/password.validator";
import { AuthentificationService } from "../authentification.service";
import { Utilisateur } from "../models/utilisateur";
import { PremierConnexion } from "./premier-connexion";

@Component({
  selector: "in-premiereConnection",
  templateUrl: "./premiere-connexion.component.html",
})
export class PremiereConnexionComponent implements OnInit {
  erreurActivation: boolean = false;
  conflitDetecte: boolean = false;
  nonRespectReglesSecurite: boolean = false;
  motDePasseProvisoireIncorrect: boolean = false;
  public showPassword = { tmpPass: false, newPass: false, newPassBis: false };

  public utilisateur: Utilisateur;

  authentificationForm: FormGroup;

  constructor(
    private authentificationService: AuthentificationService,
    private formBuilder: FormBuilder,
    private router: Router
  ) {
    authentificationService
      .utilisateurConnecte()
      .subscribe((u) => (this.utilisateur = u));
  }

  ngOnInit() {
    // le mail envoyé par le backend peut ne pas être un mail
    let email = this.utilisateur.adresseMail;
    if (email.indexOf("@") === -1) {
      email = "";
    }

    let tmp = localStorage.getItem("tmp");
    this.authentificationForm = this.formBuilder.group({
      tmpPass: [
        tmp,
        Validators.compose([Validators.required, Validators.minLength(1)]),
      ],
      newPass: [
        "",
        Validators.compose([
          Validators.required,
          Validators.minLength(12),
          passwordValidator(),
          notEqualsValidator("tmpPass"),
        ]),
      ],
      newPassBis: [
        "",
        Validators.compose([Validators.required, equalsValidator("newPass")]),
      ],
      email: [
        email,
        Validators.compose([
          Validators.required,
          Validators.maxLength(49),
          Validators.email,
        ]),
      ],
      emailBis: [
        "",
        Validators.compose([Validators.required, equalsValidator("email")]),
      ],
    });

    this.authentificationForm.controls["tmpPass"].valueChanges.subscribe(
      (data) =>
        this.authentificationForm.controls["newPass"].updateValueAndValidity()
    );
    this.authentificationForm.controls["newPass"].valueChanges.subscribe(
      (data) =>
        this.authentificationForm.controls[
          "newPassBis"
        ].updateValueAndValidity()
    );
    this.authentificationForm.controls["email"].valueChanges.subscribe((data) =>
      this.authentificationForm.controls["emailBis"].updateValueAndValidity()
    );

    this.showPassword = { tmpPass: false, newPass: false, newPassBis: false };
  }

  ngOnDestroy() {
    localStorage.removeItem("tmp");
  }

  onSubmit(event) {
    event.stopPropagation();

    if (this.authentificationForm.valid) {
      this.valider();
    } else {
      // Pour afficher les messages d'erreur en cas de formulaire vide
      for (const control in this.authentificationForm.controls) {
        if (typeof control === "string") {
          this.authentificationForm.controls[control].markAsTouched();
        }
      }
    }

    return false;
  }

  valider() {
    const premierConnexion: PremierConnexion = new PremierConnexion();
    premierConnexion.ancienMotDePasse = this.authentificationForm.value.tmpPass;
    premierConnexion.nouveauMotDePasse =
      this.authentificationForm.value.newPass;
    premierConnexion.confirmMotDePasse =
      this.authentificationForm.value.newPassBis;
    premierConnexion.mail = this.authentificationForm.value.email;

    this.authentificationService
      .premiereConnexion(premierConnexion)
      .then(() => {
        console.log(
          "[PremiereConnexionComponent] Premiere connexion utilisateur OK"
        );
        console.log(`[AuthentificationComponent] Redirection.`);
        this.router.navigateByUrl("/");
      })
      .catch((reponse) => {
        console.warn(
          "[PremiereConnexionComponent] Erreur lors de la premiere connexion",
          reponse
        );
        if (reponse.status === 401) {
          this.nonRespectReglesSecurite = true;
        } else if (reponse.status === 409) {
          this.conflitDetecte = true;
        } else if (reponse.status === 406) {
          this.motDePasseProvisoireIncorrect = true;
        } else {
          this.erreurActivation = true;
        }
      });
  }

  toggleShowPassword(password: "tmpPass" | "newPass" | "newPassBis") {
    this.showPassword[password] = !this.showPassword[password];
  }
}
