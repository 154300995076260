import { Component, OnInit } from "@angular/core";

import { AideService } from "./aide.service";
import { LienAide } from "./models/lien-aide";

@Component({
  selector: "in-aide",
  templateUrl: "./aide.component.html",
})
export class AideComponent implements OnInit {
  liensModesOperatoiresPP: LienAide[];
  liensModesOperatoiresPM: LienAide[];
  liensModelesCourrier: LienAide[];

  constructor(private aideService: AideService) {}

  ngOnInit() {
    this.aideService.liens().subscribe((liens) => {
      this.liensModesOperatoiresPP = liens.filter(
        (a) => a.categorie === "PERSONNE_PHYSIQUE"
      );
      this.liensModesOperatoiresPM = liens.filter(
        (a) => a.categorie === "PERSONNE_MORALE"
      );
      this.liensModelesCourrier = liens.filter(
        (a) => a.categorie === "MODELE_COURRIER"
      );
    });
  }
}
