import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { FranceConnectService } from "./franceconnect.service";

declare var $: any;

@Component({
  selector: "fc-logout-mobile",
  template: "",
})
export class FCLogoutMobileComponent implements OnInit {
  uiid: string;
  fc_token: string;

  constructor(
    private franceconnectService: FranceConnectService,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.uiid = this.route.snapshot.queryParamMap.get("uiid");
    this.fc_token = this.route.snapshot.queryParamMap.get("fc_token");
    if (this.uiid) {
      sessionStorage.setItem("portal_uiid", this.uiid);
    }

    this.franceconnectService.loadProps().then((ok) => {
      if (ok) {
        this.franceconnectService.logout(
          this.fc_token,
          this.franceconnectService.CHANNEL_MOBILE
        );
      } else {
        this.franceconnectService.returnLogoutJson(false, this.uiid);
      }
    });
  }
}
