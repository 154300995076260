import { NgModule } from "@angular/core";

import { SpinnerGeneralComponent } from "./spinner-general.component";
import { SpinnerGeneralService } from "./spinner-general.service";

@NgModule({
  imports: [],
  exports: [SpinnerGeneralComponent],
  declarations: [SpinnerGeneralComponent],
  providers: [SpinnerGeneralService],
})
export class SpinnerGeneralModule {}
