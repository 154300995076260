import { NgModule } from "@angular/core";

import { MentionsLegalesComponent } from "./mentions-legales.component";

@NgModule({
  imports: [],
  exports: [MentionsLegalesComponent],
  declarations: [MentionsLegalesComponent],
  providers: [],
})
export class MentionsLegalesModule {}
