import "rxjs/add/observable/empty";
import "rxjs/add/observable/throw";
import "rxjs/add/operator/catch";
import "rxjs/add/operator/map";
import "rxjs/add/operator/mergeMap";

import { Injectable } from "@angular/core";
import {
  ConnectionBackend,
  Headers,
  Http,
  RequestOptions,
  RequestOptionsArgs,
  Response,
} from "@angular/http";
import { Router } from "@angular/router";
import { Observable } from "rxjs/Observable";

@Injectable()
export class SecuredHttpService extends Http {
  constructor(
    backend: ConnectionBackend,
    defaultOptions: RequestOptions,
    private router: Router
  ) {
    super(backend, defaultOptions);
  }

  get(url: string, options?: RequestOptionsArgs): Observable<Response> {
    console.log(`[SecureHttpService] GET ${url}`);
    return this.intercept(super.get(url, this.surcharge(options)));
  }

  post(
    url: string,
    body: any,
    options?: RequestOptionsArgs
  ): Observable<Response> {
    console.log(`[SecureHttpService] POST ${url}`);
    return this.intercept(super.post(url, body, this.surcharge(options)));
  }

  put(
    url: string,
    body: any,
    options?: RequestOptionsArgs
  ): Observable<Response> {
    console.log(`[SecureHttpService] PUT ${url}`);
    return this.intercept(super.put(url, body, this.surcharge(options)));
  }

  delete(url: string, options?: RequestOptionsArgs): Observable<Response> {
    console.log(`[SecureHttpService] DELETE ${url}`);
    return this.intercept(super.delete(url, this.surcharge(options)));
  }

  private surcharge(options?: RequestOptionsArgs) {
    if (options == null) {
      options = new RequestOptions();
    }
    if (options.headers == null) {
      options.headers = new Headers();
    }
    // On récupère le token
    const token: string = sessionStorage.getItem("token");

    if (token != null) {
      options.headers.append("Authorization", "Bearer " + token);
    }
    return options;
  }

  private intercept(observable: Observable<Response>): Observable<Response> {
    return observable.map(this.refreshToken).catch((err) => {
      if (err.status === 401) {
        if (
          err.url.endsWith(
            "/api/authentification/authentification/connexion"
          ) ||
          err.url.endsWith(
            "/api/authentification/authentification/motDePasseOublie"
          ) ||
          err.url.endsWith(
            "/api/authentification/authentification/loginOublie"
          ) ||
          err.url.endsWith(
            "/api/authentification/authentification/validateToken"
          ) ||
          err.url.endsWith("/tabConfigs")
        ) {
          return Observable.throw(err);
        }

        console.warn(
          "[SecureHttpService] Token invalide ou expire, on redirige vers l'authentification"
        );
        sessionStorage.removeItem("token");
        this.router.navigateByUrl("/authentification/expired");
        return Observable.empty();
      }

      if (err.status === 500 || err.status === 503 || err.status === 504) {
        if (err.url.startsWith("/api/authentification/utilisateur")) {
          return Observable.throw(err);
        }

        console.warn(
          `[SecureHttpService] Service indisponible depuis la page ${err.url}, on redirige vers la page d'indisponibilité`
        );
        this.router.navigateByUrl("/erreurService");
        return Observable.throw(err);
      }

      return Observable.throw(err);
    });
  }

  private refreshToken(reponse: Response): Response {
    // Rafraichissement du token de la réponse stocké en session
    if (
      reponse.headers.has("Authorization") &&
      !reponse.url.endsWith(
        "/api/authentification/authentification/validateToken"
      )
    ) {
      sessionStorage.setItem(
        "token",
        reponse.headers.get("Authorization").substr(7)
      );
    }
    return reponse;
  }
}
