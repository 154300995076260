import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";

import { AccessibiliteComponent } from "./accessibilite/accessibilite.component";
import { AideComponent } from "./aide/aide.component";
import { AlertesComponent } from "./alertes/alertes.component";
import { AuthentificationComponent } from "./authentification/authentification.component";
import { FCAuthorizeMobileComponent } from "./authentification/franceconnect/fc-authorize-mobile.component";
import { FCLoginCallbackComponent } from "./authentification/franceconnect/fc-login-callback.component";
import { FCLogoutCallbackComponent } from "./authentification/franceconnect/fc-logout-callback.component";
import { FCLogoutMobileComponent } from "./authentification/franceconnect/fc-logout-mobile.component";
import { FCMailComponent } from "./authentification/franceconnect/fc-mail.component";
import { FCNumeroTitreComponent } from "./authentification/franceconnect/fc-numerotitre.component";
import { LoginOublieComponent } from "./authentification/login-oublie/login-oublie.component";
import { DROITS } from "./authentification/models/droits.constants";
import { MotDePasseOublieComponent } from "./authentification/mot-de-passe-oublie/mot-de-passe-oublie.component";
import { NouveauMotPasseComponent } from "./authentification/nouveau-mot-de-passe/nouveau-mot-passe.component";
import { CgruComponent } from "./cgru/cgru.component";
import { CguComponent } from "./cgu/cgu.component";
import { CanActivateADroitGuard } from "./common/directives/a-droit/a-droit.guard";
import { ContactComponent } from "./contact/contact.component";
import { DemandeComponent } from "./demande/demande.component";
import { ErreurServiceComponent } from "./erreur-service/erreur-service.component";
import { FaqComponent } from "./faq/faq.component";
import { MentionsLegalesComponent } from "./mentions-legales/mentions-legales.component";
import { MesDonneesComponent } from "./mes-donnees/mes-donnees.component";
import { MesInformationsComponent } from "./mes-donnees/mes-informations/mes-informations.component";
import { SuiviDemandeComponent } from "./mes-donnees/suivi-demande/suivi-demande.component";
import { PhotoComponent } from "./photo/photo.component";
import { PlanComponent } from "./plan/plan.component";
import { VehiculesComponent } from "./vehicules/vehicules.component";

const routes: Routes = [
  {
    path: "",
    redirectTo: "/authentification",
    pathMatch: "full",
  },
  {
    path: "accessibilite",
    component: AccessibiliteComponent,
    data: {
      breadcrumb: "Accessibilité",
    },
  },
  {
    path: "aide",
    component: AideComponent,
    canActivate: [CanActivateADroitGuard],
    data: {
      breadcrumb: "Aide",
      droit: DROITS.AIDE,
    },
  },
  {
    path: "alertes",
    component: AlertesComponent,
    canActivate: [CanActivateADroitGuard],
    data: {
      breadcrumb: "Alertes",
      droit: DROITS.ALERTES,
    },
  },
  {
    path: "authentification",
    children: [
      {
        path: "",
        component: AuthentificationComponent,
      },
      {
        path: "expired",
        component: AuthentificationComponent,
        data: {
          breadcrumb: "Session expirée",
        },
      },
      {
        path: "nouveauMotDePasse",
        component: NouveauMotPasseComponent,
        data: {
          breadcrumb: "Nouveau mot de passe",
        },
      },
      {
        path: "loginOublie",
        component: LoginOublieComponent,
        data: {
          breadcrumb: "Identifiant oublié",
        },
      },
      {
        path: "motDePasseOublie",
        component: MotDePasseOublieComponent,
        data: {
          breadcrumb: "Mot de passe oublié",
        },
      },
    ],
    data: {
      breadcrumb: "Authentification",
    },
  },
  {
    path: "cgru",
    component: CgruComponent,
    data: {
      breadcrumb: "Conditions Générales de Remise et d'Usage",
    },
  },
  {
    path: "cgu",
    component: CguComponent,
    data: {
      breadcrumb: "Conditions Générales d'Utilisation",
    },
  },
  {
    path: "contact",
    canActivate: [CanActivateADroitGuard],
    component: ContactComponent,
    data: {
      breadcrumb: "Contact",
      droit: DROITS.CONTACT,
    },
  },
  {
    path: "demande",
    children: [
      {
        path: "",
        component: DemandeComponent,
        canActivate: [CanActivateADroitGuard],
        data: {
          breadcrumb: "Demande",
          droit: DROITS.DUPLICATA,
        },
      },
      {
        path: ":numeroIdCommandeDuplicata",
        component: DemandeComponent,
        canActivate: [CanActivateADroitGuard],
        data: {
          breadcrumb: "Récapitulatif",
          droit: DROITS.DUPLICATA,
        },
      },
    ],
  },
  {
    path: "erreurService",
    component: ErreurServiceComponent,
    data: {
      breadcrumb: "Service indisponible",
    },
  },
  {
    path: "faq",
    component: FaqComponent,
    canActivate: [CanActivateADroitGuard],
    data: {
      breadcrumb: "FAQ",
      droit: DROITS.FAQ,
    },
  },
  {
    path: "fc-authorize-mobile",
    component: FCAuthorizeMobileComponent,
  },
  {
    path: "fc-logout-mobile",
    component: FCLogoutMobileComponent,
  },
  {
    path: "fc-mail",
    component: FCMailComponent,
    data: {
      breadcrumb: "Mise à jour des emails",
    },
  },
  {
    path: "fc-numerotitre",
    component: FCNumeroTitreComponent,
    data: {
      breadcrumb: "Numéro de titre",
    },
  },
  {
    path: "login-callback",
    component: FCLoginCallbackComponent,
  },
  {
    path: "logout-callback",
    component: FCLogoutCallbackComponent,
  },
  {
    path: "mentionsLegales",
    component: MentionsLegalesComponent,
    data: {
      breadcrumb: "Mentions légales",
    },
  },
  {
    path: "mesdonnees",
    children: [
      {
        path: "",
        component: MesDonneesComponent,
      },
      {
        path: "mesinformations",
        canActivate: [CanActivateADroitGuard],
        component: MesInformationsComponent,
        data: {
          breadcrumb: "Mes informations",
          droit: DROITS.MESINFOS,
        },
      },
      {
        path: "suividemande",
        canActivate: [CanActivateADroitGuard],
        component: SuiviDemandeComponent,
        data: {
          breadcrumb: "Suivi de demande",
          droit: DROITS.SUIVIDEMANDE,
        },
      },
    ],
    data: {
      breadcrumb: "Mes données",
    },
  },
  {
    path: "photo",
    component: PhotoComponent,
    canActivate: [CanActivateADroitGuard],
    data: {
      breadcrumb: "Téléversement photo",
      droit: DROITS.PHOTO,
    },
  },
  {
    path: "plan",
    component: PlanComponent,
    data: {
      breadcrumb: "Plan du site",
    },
  },
  {
    path: "vehicules",
    component: VehiculesComponent,
    canActivate: [CanActivateADroitGuard],
    data: {
      breadcrumb: "Véhicules",
      droit: DROITS.VEHICULES,
    },
  },
  {
    path: "**",
    redirectTo: "/erreurService",
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class BeneficiaireRoutingModule {}
