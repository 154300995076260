import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";

import { MessagesModule } from "../common/components/messages/messages.module";
import { TooltipModule } from "../common/directives/tooltip/tooltip.module";
import { MesDonneesComponent } from "./mes-donnees.component";
import { MesDonneesService } from "./mes-donnees.service";
import { MesInformationsComponent } from "./mes-informations/mes-informations.component";
import { SuiviDemandeComponent } from "./suivi-demande/suivi-demande.component";

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    ReactiveFormsModule,
    TooltipModule,
    MessagesModule,
  ],
  exports: [
    MesDonneesComponent,
    MesInformationsComponent,
    SuiviDemandeComponent,
  ],
  declarations: [
    MesDonneesComponent,
    MesInformationsComponent,
    SuiviDemandeComponent,
  ],
  providers: [MesDonneesService],
})
export class MesDonneesModule {}
