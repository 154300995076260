import { NgModule } from "@angular/core";

import { AccessibiliteComponent } from "./accessibilite.component";

@NgModule({
  imports: [],
  exports: [AccessibiliteComponent],
  declarations: [AccessibiliteComponent],
  providers: [],
})
export class AccessibiliteModule {}
