import "rxjs/add/operator/map";
import "rxjs/add/operator/mergeMap";

import { Injectable } from "@angular/core";
import { Http } from "@angular/http";

import { StatusTeleversementPhoto } from "./models/status-televersement-photo";

@Injectable()
export class PhotoService {
  constructor(private http: Http) {}

  getNombreJoursBlocage(): Promise<number> {
    console.log("[PhotoService] getNombreJoursBlocage");
    return new Promise((resolve, reject) => {
      this.http
        .get("/api/organismeBeneficiaire/photo/nombreJoursBlocage")
        .subscribe(
          (response) => {
            if (response.status !== 200) {
              reject(
                `Pas de nombre de jours de blocage, statut ${response.status}`
              );
            } else {
              resolve(response.json());
            }
          },
          (err) => reject(err)
        );
    });
  }

  getStatusTeleversementPhoto(
    identifiantBeneficiaire: string
  ): Promise<StatusTeleversementPhoto> {
    console.log(
      "[PhotoService] getStatusTeleversementPhoto ",
      identifiantBeneficiaire
    );
    return new Promise((resolve, reject) => {
      this.http
        .get(
          "/api/organismeBeneficiaire/photo/isEligibleTeleversement/" +
            identifiantBeneficiaire
        )
        .subscribe(
          (response) => {
            if (response.status !== 200) {
              reject(
                `Pas de status eligible au televersement photo, statut ${response.status}`
              );
            } else {
              resolve(response.json());
            }
          },
          (err) => reject(err)
        );
    });
  }
}
