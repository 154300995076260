import { Component, OnDestroy, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { Subscription } from "rxjs";

import { AuthentificationService } from "../authentification/authentification.service";
import { TabsConfig } from "../authentification/models/tabs-config";
import { Utilisateur } from "../authentification/models/utilisateur";
import { PaysEnum } from "../common/models/pays";
import { immatriculationValidator } from "../common/validators/immatriculation.validator";
import { Vehicule } from "./models/vehicule";
import { VehiculesService } from "./vehicules.service";

// https://api.jqueryui.com/dialog/#method-destroy
// 'destroy' rather than 'close'

declare var $: any;

@Component({
  selector: "in-vehicules",
  templateUrl: "./vehicules.component.html",
  styleUrls: ["./vehicules.component.scss"],
})
export class VehiculesComponent implements OnInit, OnDestroy {
  private francePatterns: string =
    "(Exemple : AA-999-AA ; A 11 A ; 1234 AB 75 ; 123 ABC 75)";
  public vehiculesBeneficiaire: Vehicule[] = null;
  public vehiculesBeneficiaireActifs: Vehicule[] = null;
  public utilisateur: Utilisateur = null;
  utilisateurSubscription: Subscription;
  public isPageReadOnly: boolean = false;
  public nbMaxVehicle: number;
  public nbMaxActive: number;
  public vehicleNameMaxLength: number = 20;
  public suppressionVehiculeSuccess: boolean = false;
  public suppressionVehiculeFailed: boolean = false;
  public modificationVehiculeFailed: boolean = false;
  public messageVehiculeFailed: string = "";
  // variables messages
  public messageName: string = "";
  public messageImmat: string = "";
  // les messages activation et desactivation peuvent s'afficher en même temps
  // il nous faut donc une second set de variables (utilisées uniquement pour le message de désactivation)
  public messageNameDes: string = "";
  public messageImmatDes: string = "";
  // variables modifier vehicule
  public modifierName: string = "";
  public modifierImmat: string = "";
  public modifierImmatCountry: string = "";
  // autres
  public activerVehiculeSuccess: boolean = false;
  public activerVehiculeFailed: boolean = false;
  public activerVehiculeFailedReason: string = "";
  public desactiverVehiculeSuccess: boolean = false;
  public desactiverVehiculeFailed: boolean = false;
  public desactiverVehiculeFailedReason: string = "";
  public ajouterVehiculeForm: FormGroup;
  public messageActiverLimiteError: boolean = false;
  public defaultCountry: string = "F";
  public ajouterActiverVehiculeSuccess: boolean = false;
  public ajouterVehiculeSuccess: boolean = false;
  public ajouterVehiculeFailed: boolean = false;
  public ajouterVehiculeDoublonFailed: boolean = false;
  public ajouterVehiculeMaxFailed: boolean = false;
  public wsCodeErreur: string = "";
  public nameAlreadyUsed: boolean = false;
  public nameUsed: string = "";
  public immatriculationUsed: string = "";
  public nameVehiculeRequiredError: boolean = false;
  public immatriculationVehiculeRequiredError: boolean = false;
  public newVehicule: Vehicule;
  private currentPopup: string = "";
  public countryPattern: string = this.francePatterns;

  constructor(
    private vehiculesService: VehiculesService,
    private authentificationService: AuthentificationService
  ) {}

  ngOnDestroy() {
    $("#popinAjouterVehicule").dialog("destroy");
    $("#popinModifierVehicule").dialog("destroy");
    $("#popinActiverVehiculeLimiteNew").dialog("destroy");
    $(this.currentPopup).dialog("destroy");
  }

  // init
  ngOnInit() {
    // get vehicules
    this.utilisateurSubscription = this.authentificationService
      .utilisateurConnecte()
      .subscribe((utilisateur) => {
        this.utilisateur = utilisateur;
        if (this.utilisateur != null) {
          this.vehiculesService
            .vehiculesBeneficiaire(this.utilisateur.identifiant)
            .subscribe((vehiculesBeneficiaire) => {
              this.vehiculesBeneficiaire = this.sortVehicules(
                vehiculesBeneficiaire
              );
            });
        }
      });

    // get conf
    const tabs: TabsConfig =
      this.authentificationService.getTabsConfigConnecte();
    if (tabs && tabs.vehiculesTab) {
      // si les champs nbMaxVehicle et nbMaxActive sont définis, alors on lit la valeur de isPageReadOnly
      this.isPageReadOnly =
        tabs.vehiculesTab.nbMaxVehicle === undefined ||
        tabs.vehiculesTab.nbMaxActive === undefined ||
        tabs.vehiculesTab.readOnly === undefined
          ? true
          : tabs.vehiculesTab.readOnly;

      this.nbMaxVehicle = tabs.vehiculesTab.nbMaxVehicle;
      this.nbMaxActive = tabs.vehiculesTab.nbMaxActive;
      this.vehicleNameMaxLength = tabs.vehiculesTab.vehicleNameMaxLength;
    }

    // form
    this.ajouterVehiculeForm = new FormGroup({
      nameVehicule: new FormControl(
        "",
        Validators.compose([Validators.maxLength(this.vehicleNameMaxLength)])
      ),
      immatCountry: new FormControl("", Validators.compose([])),
      immatriculation: new FormControl(
        "",
        Validators.compose([immatriculationValidator("immatCountry")])
      ),
      activation: new FormControl("", Validators.compose([])),
    });

    this.ajouterVehiculeForm.markAsPristine();
    this.ajouterVehiculeForm.controls["immatCountry"].setValue(
      this.defaultCountry
    );
  }

  // get counters
  counterAjouter(): string {
    let counter: string = "0 / " + this.vehicleNameMaxLength;
    if (this.ajouterVehiculeForm.controls.nameVehicule.value) {
      counter =
        this.ajouterVehiculeForm.controls.nameVehicule.value.length +
        " / " +
        this.vehicleNameMaxLength;
    }
    return counter;
  }

  counterModifier(): string {
    let counter: string = "0 / " + this.vehicleNameMaxLength;
    if (this.modifierName) {
      counter = this.modifierName.length + " / " + this.vehicleNameMaxLength;
    }
    return counter;
  }

  // reload list vehicules from api
  refreshListVehicules() {
    // get vehicules
    this.vehiculesService
      .vehiculesBeneficiaire(this.utilisateur.identifiant)
      .subscribe((vehiculesBeneficiaire) => {
        this.vehiculesBeneficiaire = this.sortVehicules(vehiculesBeneficiaire);
      });
  }

  // get vehicule by immat
  getVehiculeByImmat(
    vehicules: Vehicule[],
    immat: string,
    immatCountry: string
  ): Vehicule {
    for (let veh of vehicules) {
      if (veh.immatriculation == immat && veh.immatCountry == immatCountry)
        return veh;
    }
    return null;
  }

  // sort vehicules
  sortVehicules(arrV: Vehicule[]): Vehicule[] {
    if (arrV) {
      arrV.sort((a: Vehicule, b: Vehicule) => {
        return (
          new Date(a.createdDate).getTime() - new Date(b.createdDate).getTime()
        );
      });
    }
    return arrV;
  }

  // clean messages
  clean() {
    this.suppressionVehiculeSuccess = false;
    this.suppressionVehiculeFailed = false;
    this.activerVehiculeSuccess = false;
    this.activerVehiculeFailed = false;
    this.activerVehiculeFailedReason = "";
    this.desactiverVehiculeSuccess = false;
    this.desactiverVehiculeFailed = false;
    this.desactiverVehiculeFailedReason = "";
    this.messageActiverLimiteError = false;
    this.ajouterActiverVehiculeSuccess = false;
    this.ajouterVehiculeSuccess = false;
    this.ajouterVehiculeFailed = false;
    this.ajouterVehiculeDoublonFailed = false;
    this.ajouterVehiculeMaxFailed = false;
    this.wsCodeErreur = "";
    this.nameAlreadyUsed = false;
    this.nameUsed = "";
    this.immatriculationUsed = "";
    this.nameVehiculeRequiredError = false;
    this.immatriculationVehiculeRequiredError = false;
    this.newVehicule = null;
  }

  // log error
  getWsCodeErreur(err: any): string {
    try {
      return err.json().code;
    } catch (error) {
      return "";
    }
  }
  logError(err: any) {
    const code: string = this.getWsCodeErreur(err);
    if (code != "") {
      console.log("Code erreur:" + code);
    }
  }

  // get pays
  getPays(): Array<{ label: string; code: string }> {
    return PaysEnum;
  }

  // get pays label
  getLabelPays(code: string): string {
    for (let elt of PaysEnum) {
      if (elt.code == code) return elt.label;
    }
    return "";
  }

  // limite nombre vehicules max atteinte ?
  limiteVehiculeAtteinte(): boolean {
    if (this.vehiculesBeneficiaire)
      return this.vehiculesBeneficiaire.length >= this.nbMaxVehicle;
    return false;
  }

  messageVehiculeAtteinte(): string {
    let msg =
      "Vous avez " +
      this.vehiculesBeneficiaire.length +
      " véhicules enregistrés.";

    let nbToDel: number =
      this.vehiculesBeneficiaire.length - this.nbMaxVehicle + 1;
    if (nbToDel == 1) {
      msg = msg + " Supprimez un véhicule existant pour en ajouter un.";
    } else {
      msg =
        msg +
        " Supprimez " +
        nbToDel +
        " véhicules existants pour en ajouter un.";
    }

    return msg;
  }

  // limite vehicules actifs atteinte ?
  limiteVehiculeActifAtteinte(): boolean {
    let nb: number = 0;
    if (this.vehiculesBeneficiaire) {
      for (let veh of this.vehiculesBeneficiaire) {
        if (veh.isActive) nb++;
      }
    }
    return nb >= this.nbMaxActive;
  }

  // traite nom vehicule
  processVehiculeName(name_in: string): string {
    let name_out: string = name_in.trim();
    name_out = name_out.replace(/\t/g, " ");
    name_out = name_out.replace(/\s\s+/g, " ");
    return name_out;
  }

  // nom deja utilise
  isNameAlreadyUsed(immatriculation: string, name: string): boolean {
    if (!this.vehiculesBeneficiaire) return false;
    for (let veh of this.vehiculesBeneficiaire) {
      if (
        veh.nameVehicule.toLocaleLowerCase().trim() ==
          name.toLocaleLowerCase().trim() &&
        veh.immatriculation != immatriculation
      ) {
        this.nameAlreadyUsed = true;
        this.nameUsed = name.toLocaleUpperCase().trim();
        this.immatriculationUsed = veh.immatriculation;
        return true;
      }
    }
    return false;
  }

  // nom inchangé
  isNameUnchanged(immatriculation: string, name: string): boolean {
    if (!this.vehiculesBeneficiaire) return false;
    for (let veh of this.vehiculesBeneficiaire) {
      if (veh.nameVehicule == name && veh.immatriculation == immatriculation) {
        return true;
      }
    }
    return false;
  }

  // mettre à jour la liste des vehicules actifs
  updateListVehiculesActifs() {
    this.vehiculesBeneficiaireActifs = [];
    for (let veh of this.vehiculesBeneficiaire) {
      let v: Vehicule = Object.assign({}, veh);
      if (v.isActive) this.vehiculesBeneficiaireActifs.push(v);
    }
  }

  // modal ajouter un vehicule
  showModalAjouterVehicule() {
    this.clean();

    // form
    this.resetAjouterVehiculeForm();
    // default
    this.countryPattern = this.francePatterns;

    $("#popinAjouterVehicule").dialog({
      resizable: false,
      height: "auto",
      width: 450,
      modal: true,
    });
  }

  closeModalAjouterVehicule() {
    this.ajouterVehiculeForm.markAsPristine();
    this.ajouterVehiculeForm.reset();
    // close modal
    $("#popinAjouterVehicule").dialog("destroy");
  }

  ajouterVehicule() {
    // securite
    if (this.isPageReadOnly) {
      $("#popinAjouterVehicule").dialog("destroy");
      return;
    }

    // check required
    if (
      this.ajouterVehiculeForm.value.nameVehicule == null ||
      this.ajouterVehiculeForm.value.nameVehicule == ""
    ) {
      this.nameVehiculeRequiredError = true;
    }
    if (this.ajouterVehiculeForm.value.immatriculation == null) {
      this.immatriculationVehiculeRequiredError = true;
    }
    if (
      this.nameVehiculeRequiredError ||
      this.immatriculationVehiculeRequiredError
    )
      return;

    // ajouter vehicule
    if (this.onSubmitAjouterVehiculeForm()) {
      $("#popinAjouterVehicule").dialog("destroy");
    }
  }

  changeName() {
    this.nameVehiculeRequiredError = false;
  }

  changeImmatriculation() {
    this.immatriculationVehiculeRequiredError = false;
  }

  changeCountry() {
    this.ajouterVehiculeForm.controls[
      "immatriculation"
    ].updateValueAndValidity();
    if (this.ajouterVehiculeForm.value.immatCountry == "F") {
      this.countryPattern = this.francePatterns;
    } else {
      this.countryPattern = "";
    }
  }

  resetAjouterVehiculeForm() {
    this.ajouterVehiculeForm.markAsPristine();
    this.ajouterVehiculeForm.reset();
    this.ajouterVehiculeForm.controls["immatCountry"].setValue(
      this.defaultCountry
    );
  }

  onSubmitAjouterVehiculeForm(): boolean {
    if (this.ajouterVehiculeForm.valid) {
      let nameVehicule: string = this.ajouterVehiculeForm.value.nameVehicule;
      nameVehicule = this.processVehiculeName(nameVehicule);
      let immatCountry = this.ajouterVehiculeForm.value.immatCountry;
      let immatriculation: string =
        this.ajouterVehiculeForm.value.immatriculation;
      immatriculation = immatriculation.toUpperCase();
      this.messageName = nameVehicule;
      this.messageImmat = immatriculation;

      // si limite pas atteinte : activation auto côté back in
      let activation =
        this.ajouterVehiculeForm.value.activation ||
        !this.limiteVehiculeActifAtteinte();

      // controle sur le nom mais pas sur l'immatriculation
      if (this.isNameAlreadyUsed("", nameVehicule)) {
        return false;
      }

      this.vehiculesService
        .ajouterVehicule(
          this.utilisateur.identifiant,
          immatriculation,
          immatCountry,
          nameVehicule
        )
        .then(() => {
          // activer vehicule ou non
          this.vehiculesService
            .vehiculesBeneficiaire(this.utilisateur.identifiant)
            .subscribe((vehiculesBeneficiaire) => {
              this.newVehicule = this.getVehiculeByImmat(
                vehiculesBeneficiaire,
                immatriculation,
                immatCountry
              );
              if (activation) {
                this.vehiculesBeneficiaire = this.sortVehicules(
                  vehiculesBeneficiaire
                );
                if (this.newVehicule.isActive) {
                  this.ajouterActiverVehiculeSuccess = true;
                }
                // si limite atteinte : activation possible en déselectionnant un autre vehicule
                else if (this.limiteVehiculeActifAtteinte()) {
                  this.ajouterVehiculeSuccess = true;
                  this.showModalActiverVehiculeLimiteNew();
                } else {
                  this.ajouterVehiculeSuccess = true;
                }
              } else {
                // cas ou le back a activer automatiquement le vehicule et que l'utilisateur ne l'a pas demandé
                if (this.newVehicule.isActive) {
                  this.vehiculesService
                    .desactiverVehicule(
                      this.utilisateur.identifiant,
                      immatriculation,
                      immatCountry
                    )
                    .then(() => {
                      this.endAjouterVehicule();
                      // nothing
                    })
                    .catch((err) => {
                      this.vehiculesBeneficiaire = this.sortVehicules(
                        vehiculesBeneficiaire
                      );
                      this.logError(err);
                    });
                } else {
                  this.vehiculesBeneficiaire = this.sortVehicules(
                    vehiculesBeneficiaire
                  );
                }
                this.ajouterVehiculeSuccess = true;
              }
            });
        })
        .catch((err) => {
          this.logError(err);

          // show info message
          if (this.getWsCodeErreur(err) == "ERR08") {
            this.ajouterVehiculeDoublonFailed = true;
          } else if (this.getWsCodeErreur(err) == "ERR04") {
            this.ajouterVehiculeMaxFailed = true;
          } else {
            this.ajouterVehiculeFailed = true;
          }

          // end
          this.endAjouterVehicule();
        });
    } else {
      // Pour afficher les messages d'erreur en cas de formulaire vide
      for (const control in this.ajouterVehiculeForm.controls) {
        if (typeof control === "string") {
          this.ajouterVehiculeForm.controls[control].markAsTouched();
        }
      }
    }

    return this.ajouterVehiculeForm.valid;
  }

  endAjouterVehicule() {
    // refresh list vehicule
    this.refreshListVehicules();
  }

  // modal supprimer un vehicule
  showModalSupprimerVehicule(id: string) {
    this.clean();

    //une popin par véhicule (car sinon la popin ne se met pas tjs bien à jour avec les infos du véhicule sélectionné..)
    this.currentPopup = "#popinSupprimerVehicule" + id;
    $(this.currentPopup).dialog({
      resizable: false,
      height: "auto",
      width: 450,
      modal: true,
    });
  }

  closeModalSupprimerVehicule(id: string) {
    // close modal
    $("#popinSupprimerVehicule" + id).dialog("destroy");
  }

  supprimerVehicule(
    id: string,
    immatriculation: string,
    immatCountry: string,
    name: string
  ) {
    // close modal
    $("#popinSupprimerVehicule" + id).dialog("destroy");

    // securite
    if (this.isPageReadOnly) {
      return;
    }

    // suprimer vehicule
    this.vehiculesService
      .supprimerVehicule(
        this.utilisateur.identifiant,
        immatriculation,
        immatCountry
      )
      .then(() => {
        // show info message
        this.messageName = name;
        this.messageImmat = immatriculation;
        this.suppressionVehiculeSuccess = true;

        // refresh list vehicule
        this.refreshListVehicules();
      })
      .catch((err) => {
        this.logError(err);

        // show info message
        this.messageName = name;
        this.messageImmat = immatriculation;
        if (err.status == 404 && this.getWsCodeErreur(err) == "ERR03") {
          this.messageVehiculeFailed = " est déjà supprimé de la liste.";
        } else if (err.status == 403 && this.getWsCodeErreur(err) == "ERR09") {
          this.messageVehiculeFailed =
            " n'a pas pu être supprimé car il est en cours de modification.";
        } else {
          this.messageVehiculeFailed = " n'a pas pu être supprimé.";
        }
        this.suppressionVehiculeFailed = true;

        // refresh list vehicule
        this.refreshListVehicules();
      });
  }

  // modal modifier un vehicule
  startModify(
    immatriculation: string,
    immatCountry: string,
    isBeingModified: string
  ) {
    this.vehiculesService
      .startModifyVehicule(
        this.utilisateur.identifiant,
        immatriculation,
        immatCountry,
        isBeingModified
      )
      .then(() => {})
      .catch((err) => {
        this.logError(err);
      });
  }

  // keypress for accessibility
  keypressModalModifierVehicule(
    event: any,
    immatriculation: string,
    immatCountry: string,
    name: string
  ) {
    if (event) {
      event.preventDefault();
      if (event.keyCode == 32 || event.keyCode == 13) {
        this.showModalModifierVehicule(immatriculation, immatCountry, name);
      }
    }
  }

  showModalModifierVehicule(
    immatriculation: string,
    immatCountry: string,
    name: string
  ) {
    this.clean();
    this.modifierName = name;
    this.modifierImmat = immatriculation;
    this.modifierImmatCountry = immatCountry;
    this.startModify(immatriculation, immatCountry, "true");

    /* NOTE
     *  si le startModify renvoie une erreur on autorise qd meme l'affichage de la popup;
     *  au pire la modification ne sera pas prise en compte côté back IN
     */

    let parent = this;
    $("#popinModifierVehicule").dialog({
      resizable: false,
      height: "auto",
      width: 450,
      modal: true,
      close: function () {
        parent.closeModalModifierVehicule();
      },
    });
  }

  closeModalModifierVehicule() {
    this.startModify(this.modifierImmat, this.modifierImmatCountry, "false");
    // close modal
    $("#popinModifierVehicule").dialog("destroy");
  }

  modifierVehicule() {
    if (!this.modifierName) {
      this.nameVehiculeRequiredError = true;
      return;
    }
    this.modifierName = this.processVehiculeName(this.modifierName);
    if (this.isNameUnchanged(this.modifierImmat, this.modifierName)) {
      this.startModify(this.modifierImmat, this.modifierImmatCountry, "false");
      // close modal
      $("#popinModifierVehicule").dialog("destroy");
    } else if (!this.isNameAlreadyUsed(this.modifierImmat, this.modifierName)) {
      // close modal
      $("#popinModifierVehicule").dialog("destroy");

      // securite
      if (this.isPageReadOnly) {
        return;
      }

      // modifier vehicule
      this.vehiculesService
        .modifierVehicule(
          this.utilisateur.identifiant,
          this.modifierImmat,
          this.modifierImmatCountry,
          this.modifierName
        )
        .then(() => {
          this.startModify(
            this.modifierImmat,
            this.modifierImmatCountry,
            "false"
          );

          // refresh list vehicule
          this.refreshListVehicules();
        })
        .catch((err) => {
          this.logError(err);
          this.startModify(
            this.modifierImmat,
            this.modifierImmatCountry,
            "false"
          );

          // show info message
          if (err.status == 404 && this.getWsCodeErreur(err) == "ERR03") {
            this.messageVehiculeFailed =
              " n'a pas pu être modifié car l'immatriculation n'a pas été trouvée.";
          } else {
            this.messageVehiculeFailed = " n'a pas pu être modifié.";
          }

          // show info message
          this.messageName = this.modifierName;
          this.messageImmat = this.modifierImmat;
          this.modificationVehiculeFailed = true;

          // refresh list vehicule
          this.refreshListVehicules();
        });
    }
  }

  // modals activer un vehicule
  keypressModalActiverVehicule(event: any, id: string) {
    if (event) {
      if (event.keyCode == 32 || event.keyCode == 13)
        this.showModalActiverVehicule(event, id);
    }
  }

  showModalActiverVehicule(event: any, id: string) {
    if (this.isPageReadOnly) return;
    if (event) event.preventDefault();
    this.clean();

    //une popin par véhicule (car sinon la popin ne se met pas tjs bien à jour avec les infos du véhicule sélectionné..)
    if (this.limiteVehiculeActifAtteinte()) {
      // mettre à jour la liste des vehicules actifs
      this.updateListVehiculesActifs();

      // affiche modal
      this.currentPopup = "#popinActiverVehiculeLimite" + id;
      $(this.currentPopup).dialog({
        resizable: false,
        height: "auto",
        width: 500,
        modal: true,
      });
    } else {
      this.currentPopup = "#popinActiverVehicule" + id;
      $(this.currentPopup).dialog({
        resizable: false,
        height: "auto",
        width: 500,
        modal: true,
      });
    }
  }

  showModalActiverVehiculeLimiteNew() {
    // mettre à jour la liste des vehicules actifs
    this.updateListVehiculesActifs();

    // affiche modal
    $("#popinActiverVehiculeLimiteNew").dialog({
      resizable: false,
      height: "auto",
      width: 500,
      modal: true,
    });
  }

  closeModalActiverVehicule(id: string) {
    // close modal
    $("#popinActiverVehicule" + id).dialog("destroy");
  }

  closeModalActiverVehiculeLimite(id: string) {
    // close modal
    $("#popinActiverVehiculeLimite" + id).dialog("destroy");
  }

  closeModalActiverVehiculeLimiteNew() {
    // close modal
    $("#popinActiverVehiculeLimiteNew").dialog("destroy");
  }

  activerVehiculeLimiteNew() {
    this.activerVehiculeLimite(
      this.newVehicule.id.toString(),
      this.newVehicule.immatriculation,
      this.newVehicule.immatCountry,
      this.newVehicule.nameVehicule
    );
  }

  activerVehicule(
    id: string,
    immatriculation: string,
    immatCountry: string,
    name: string
  ) {
    // close modal
    $("#popinActiverVehicule" + id).dialog("destroy");

    // securite
    if (this.isPageReadOnly) {
      return;
    }

    // activer vehicule
    this.vehiculesService
      .activerVehicule(
        this.utilisateur.identifiant,
        immatriculation,
        immatCountry
      )
      .then(() => {
        // show info message
        this.messageName = name;
        this.messageImmat = immatriculation;
        this.activerVehiculeSuccess = true;

        // refresh list vehicule
        this.refreshListVehicules();
      })
      .catch((err) => {
        this.logError(err);

        if (err.status == 403 && this.getWsCodeErreur(err) == "ERR10") {
          this.activerVehiculeFailedReason = "Le véhicule est déjà activé.";
        } else if (err.status == 403 && this.getWsCodeErreur(err) == "ERR06") {
          this.activerVehiculeFailedReason =
            "Le nombre maximal de véhicules activés est atteint.";
        } else if (err.status == 404 && this.getWsCodeErreur(err) == "ERR03") {
          this.activerVehiculeFailedReason = "Immatriculation non trouvée.";
        } else {
          this.activerVehiculeFailedReason = "";
        }

        // show info message
        this.messageName = name;
        this.messageImmat = immatriculation;
        this.activerVehiculeFailed = true;

        // refresh list vehicule
        this.refreshListVehicules();
      });
  }

  activerVehiculeLimite(
    id: string,
    immatriculation: string,
    immatCountry: string,
    name: string
  ) {
    // 1- desactiver
    let vad: Vehicule = this.getVehiculeADesactiverLimite();

    let isNewVehToActive =
      id == (this.newVehicule ? this.newVehicule.id.toString() : "");

    if (vad) {
      // close modal
      if (isNewVehToActive) {
        $("#popinActiverVehiculeLimiteNew").dialog("destroy");
      } else {
        $("#popinActiverVehiculeLimite" + id).dialog("destroy");
      }

      // securite
      if (this.isPageReadOnly) {
        return;
      }

      this.vehiculesService
        .desactiverVehicule(
          this.utilisateur.identifiant,
          vad.immatriculation,
          vad.immatCountry
        )
        .then(() => {
          // show info message
          this.messageNameDes = vad.nameVehicule;
          this.messageImmatDes = vad.immatriculation;
          this.desactiverVehiculeSuccess = true;

          // 2 - activer
          this.vehiculesService
            .activerVehicule(
              this.utilisateur.identifiant,
              immatriculation,
              immatCountry
            )
            .then(() => {
              // show info message
              if (isNewVehToActive) {
                this.ajouterVehiculeSuccess = false;
                this.ajouterActiverVehiculeSuccess = true;
              } else {
                this.messageName = name;
                this.messageImmat = immatriculation;
                this.activerVehiculeSuccess = true;
              }

              // refresh list vehicule
              this.refreshListVehicules();
            })
            .catch(() => {
              // show info message
              if (isNewVehToActive) {
                this.ajouterVehiculeSuccess = true;
              } else {
                this.messageName = name;
                this.messageImmat = immatriculation;
                this.activerVehiculeFailed = true;
              }

              // refresh list vehicule
              this.refreshListVehicules();
            });
        })
        .catch((err) => {
          this.logError(err);

          if (err.status == 403 && this.getWsCodeErreur(err) == "ERR11") {
            this.desactiverVehiculeFailedReason =
              "Le véhicule est déjà désactivé.";
          } else if (
            err.status == 404 &&
            this.getWsCodeErreur(err) == "ERR03"
          ) {
            this.desactiverVehiculeFailedReason =
              "Immatriculation non trouvée.";
          } else {
            this.desactiverVehiculeFailedReason = "";
          }

          // show info message
          this.messageNameDes = vad.nameVehicule;
          this.messageImmatDes = vad.immatriculation;
          this.desactiverVehiculeFailed = true;

          // refresh list vehicule
          this.refreshListVehicules();
        });
    } else {
      this.messageActiverLimiteError = true;
    }
  }

  // modal desactiver un vehicule
  keypressModalDesactiverVehicule(event: any, id: string) {
    if (event) {
      if (event.keyCode == 32 || event.keyCode == 13)
        this.showModalDesactiverVehicule(event, id);
    }
  }

  showModalToggleVehicule(event: any, id: string) {
    var veh = this.getVehiculeById(Number(id));
    if (veh.isActive) {
      this.showModalDesactiverVehicule(event, id);
    } else {
      this.showModalActiverVehicule(event, id);
    }
  }

  showModalDesactiverVehicule(event: any, id: string) {
    if (this.isPageReadOnly) return;
    if (event) event.preventDefault();
    this.clean();

    //une popin par véhicule (car sinon la popin ne se met pas tjs bien à jour avec les infos du véhicule sélectionné..)
    this.currentPopup = "#popinDesactiverVehicule" + id;
    $(this.currentPopup).dialog({
      resizable: false,
      height: "auto",
      width: 550,
      modal: true,
    });
  }

  closeModalDesactiverVehicule(id: string) {
    // close modal
    $("#popinDesactiverVehicule" + id).dialog("destroy");
  }

  desactiverVehicule(
    id: string,
    immatriculation: string,
    immatCountry: string,
    name: string
  ) {
    // close modal
    $("#popinDesactiverVehicule" + id).dialog("destroy");

    // securite
    if (this.isPageReadOnly) {
      return;
    }

    // desactiver vehicule
    this.vehiculesService
      .desactiverVehicule(
        this.utilisateur.identifiant,
        immatriculation,
        immatCountry
      )
      .then(() => {
        // show info message
        this.messageNameDes = name;
        this.messageImmatDes = immatriculation;
        this.desactiverVehiculeSuccess = true;

        // refresh list vehicule
        this.refreshListVehicules();
      })
      .catch((err) => {
        this.logError(err);

        if (err.status == 403 && this.getWsCodeErreur(err) == "ERR11") {
          this.desactiverVehiculeFailedReason =
            "Le véhicule est déjà désactivé.";
        } else if (err.status == 404 && this.getWsCodeErreur(err) == "ERR03") {
          this.desactiverVehiculeFailedReason = "Immatriculation non trouvée.";
        } else {
          this.desactiverVehiculeFailedReason = "";
        }

        // show info message
        this.messageNameDes = name;
        this.messageImmatDes = immatriculation;
        this.desactiverVehiculeFailed = true;

        // refresh list vehicule
        this.refreshListVehicules();
      });
  }

  // gestion liste des vehicules actifs modal activer vehicule limite atteinte
  setActiveVehiculeLimite(id: number, value: boolean) {
    for (let veh of this.vehiculesBeneficiaireActifs) {
      if (veh.id == id) {
        veh.isActive = value;
      } else {
        if (!value) veh.isActive = !value;
      }
    }
  }
  getVehiculeADesactiverLimite(): Vehicule {
    for (let veh of this.vehiculesBeneficiaireActifs) {
      if (!veh.isActive) {
        return veh;
      }
    }
  }
  getVehiculeById(id: number): Vehicule {
    for (let veh of this.vehiculesBeneficiaire) {
      if (veh.id === id) {
        return veh;
      }
    }
  }
}
