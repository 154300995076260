import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";

import { equalsValidator } from "src/app/common/validators/equals.validator";
import { passwordValidator } from "src/app/common/validators/password.validator";
import { AuthentificationService } from "../authentification.service";
import { Utilisateur } from "../models/utilisateur";

@Component({
  selector: "in-nouveau-mot-passe",
  templateUrl: "./nouveau-mot-passe.component.html",
})
export class NouveauMotPasseComponent implements OnInit {
  public utilisateur: Utilisateur;
  public token: string;

  public soumis: boolean = false;
  public succes: boolean = true;
  public tokenInvalide: boolean = false;
  public showPassword = { newPass: false, newPassBis: false };

  public nouveauMotDePasseForm: FormGroup;

  constructor(
    private authentificationService: AuthentificationService,
    private formBuilder: FormBuilder,
    private router: Router
  ) {}

  ngOnInit() {
    const elements: string[] = this.router.url.split("#");
    if (elements.length < 2) {
      this.router.navigateByUrl("/authentification");
    } else {
      this.token = elements[1];
      this.validateToken();
    }

    this.nouveauMotDePasseForm = this.formBuilder.group({
      newPass: [
        "",
        Validators.compose([
          Validators.required,
          Validators.minLength(12),
          passwordValidator(),
        ]),
      ],
      newPassBis: [
        "",
        Validators.compose([Validators.required, equalsValidator("newPass")]),
      ],
    });
    this.nouveauMotDePasseForm.controls["newPass"].valueChanges.subscribe(
      (data) =>
        this.nouveauMotDePasseForm.controls[
          "newPassBis"
        ].updateValueAndValidity()
    );

    this.showPassword = { newPass: false, newPassBis: false };
  }

  onSubmit(event: MouseEvent) {
    event.stopPropagation();

    if (this.nouveauMotDePasseForm.valid) {
      this.valider();
    } else {
      // Pour afficher les messages d'erreur en cas de formulaire vide
      for (const control in this.nouveauMotDePasseForm.controls) {
        if (typeof control === "string") {
          this.nouveauMotDePasseForm.controls[control].markAsTouched();
        }
      }
    }

    return false;
  }

  valider() {
    this.authentificationService
      .nouveauMotDePasse(
        this.token,
        this.nouveauMotDePasseForm.controls["newPass"].value
      )
      .then((succes) => {
        this.soumis = true;
        this.succes = succes;
      })
      .catch((err) => {
        this.soumis = true;
        this.succes = false;
      });
  }

  validateToken() {
    this.authentificationService
      .validateToken(this.token)
      .then((tokenValide) => {
        this.tokenInvalide = !tokenValide;
      })
      .catch((err) => {
        this.tokenInvalide = true;
      });
  }

  toggleShowPassword(password: "newPass" | "newPassBis") {
    this.showPassword[password] = !this.showPassword[password];
  }
}
