import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";

import { AuthentificationService } from "../authentification.service";
import { INUtilisateurFC } from "../models/in-fc-utilisateur";
import { FranceConnectService } from "./franceconnect.service";

declare var $: any;

@Component({
  selector: "fc-numerotitre",
  templateUrl: "./fc-numerotitre.component.html",
  styleUrls: ["./fc-numerotitre.component.scss"],
})
export class FCNumeroTitreComponent implements OnInit {
  public numeroTitreForm: FormGroup;
  public numeroTitreRequiredError: boolean = false;
  public notFoundError: boolean = false;
  public technicalError: boolean = false;
  public nbTry: number = 0;
  private userFound: INUtilisateurFC;
  tokenfc: string;

  constructor(
    private franceconnectService: FranceConnectService,
    private authentificationService: AuthentificationService,
    private router: Router
  ) {}

  ngOnInit() {
    // check
    this.tokenfc = sessionStorage.getItem("token_hint");
    let tokenin = sessionStorage.getItem("token");
    if (!this.tokenfc || tokenin) {
      this.router.navigateByUrl("/");
    }
    // form
    this.numeroTitreForm = new FormGroup({
      numeroTitre: new FormControl("", Validators.compose([])),
    });
    this.numeroTitreForm.markAsPristine();
  }

  isDisabled(): boolean {
    return this.nbTry >= 3;
  }

  annuler() {
    this.franceconnectService.logout(
      this.tokenfc,
      this.franceconnectService.CHANNEL_PORTAL
    );
  }

  valider() {
    this.numeroTitreRequiredError = false;
    this.notFoundError = false;
    this.technicalError = false;

    // check required
    //console.log("-------- FCNumeroTitreComponent, this.numeroTitreForm.value.numeroTitre:" + this.numeroTitreForm.value.numeroTitre);
    if (!this.numeroTitreForm.value.numeroTitre) {
      this.numeroTitreRequiredError = true;
    } else {
      this.nbTry = this.nbTry + 1;

      this.authentificationService
        .getUtilisateurByNumeroTitre(this.numeroTitreForm.value.numeroTitre)
        .then((infcuser) => {
          // show popup info and redirect on close
          this.userFound = infcuser;
          this.showModalInfo();
        })
        .catch((err) => {
          if (err.status == 404) {
            this.notFoundError = true;
          } else {
            this.technicalError = true;
          }
        });
    }
  }

  showModalInfo() {
    let parent = this;
    $("#info").dialog({
      resizable: false,
      height: "auto",
      width: 600,
      modal: true,
      close: function () {
        parent.closeModalInfo();
      },
    });
  }

  closeModalInfo() {
    this.authentificationService.connectAndRedirect(this.userFound);
    $("#info").dialog("destroy");
  }
}
