import "rxjs/add/operator/map";
import "rxjs/add/operator/mergeMap";

import { Injectable } from "@angular/core";
import { Http, RequestOptions, URLSearchParams } from "@angular/http";
import { Observable } from "rxjs/Observable";
import { Alertes } from "./models/alertes";

@Injectable()
export class AlertesService {
  constructor(private http: Http) {}

  /**
   * Méthode à appeler pour récupérer les alertes
   */
  getAlertes(identifiantBeneficiaire: string): Observable<Alertes> {
    // build options
    const options = new RequestOptions();
    const parameters: URLSearchParams = new URLSearchParams();
    parameters.set("beneficiaryId", identifiantBeneficiaire);
    options.params = parameters;

    return this.http
      .get(`/api/alerts/alerts/private/manage`, options)
      .map((response) => response.json() as Alertes);
  }

  /**
   * Méthode pour modifier les alertes
   */
  modifierAlertes(
    identifiantBeneficiaire: string,
    body: any
  ): Promise<boolean> {
    // build data
    const options = new RequestOptions();
    const parameters: URLSearchParams = new URLSearchParams();
    parameters.set("beneficiaryId", identifiantBeneficiaire);
    options.params = parameters;

    // call webservice
    return new Promise((resolve, reject) => {
      this.http
        .put(`/api/alerts/alerts/private/update`, body, options)
        .toPromise()
        .then((response) => resolve(response.status === 200))
        .catch(reject);
    });
  }
}
