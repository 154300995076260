import { AfterViewChecked, Component, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute } from "@angular/router";

import { TabsComponent } from "../common/components/tabs/tabs.component";
import { STEPS } from "./enums/steps";
import { StepChoixDemandeComponent } from "./step-choix-demande/step-choix-demande.component";
import { StepEngagementComponent } from "./step-engagement/step-engagement.component";
import { StepLivraisonComponent } from "./step-livraison/step-livraison.component";
import { StepPaiementComponent } from "./step-paiement/step-paiement.component";
import { StepRecapitulatifComponent } from "./step-recapitulatif/step-recapitulatif.component";

@Component({
  selector: "in-demande",
  templateUrl: "./demande.component.html",
})
export class DemandeComponent implements OnInit, AfterViewChecked {
  @ViewChild(TabsComponent) tabs;
  @ViewChild(StepEngagementComponent)
  private stepEngagementComponent: StepEngagementComponent;
  @ViewChild(StepChoixDemandeComponent)
  private stepChoixDemandeComponent: StepChoixDemandeComponent;
  @ViewChild(StepLivraisonComponent)
  private stepLivraisonComponent: StepLivraisonComponent;
  @ViewChild(StepPaiementComponent)
  private stepPaiementComponent: StepPaiementComponent;
  @ViewChild(StepRecapitulatifComponent)
  private stepRecapitulatifComponent: StepRecapitulatifComponent;

  STEPS = STEPS;

  private stepRecapitulatifInitie: boolean = false;

  constructor(private route: ActivatedRoute) {}

  public ngOnInit() {
    this.stepRecapitulatifInitie = false;
  }

  ngAfterViewChecked() {
    if (!this.stepRecapitulatifInitie) {
      const numeroIdCommandeDuplicata =
        this.route.snapshot.params["numeroIdCommandeDuplicata"];
      if (numeroIdCommandeDuplicata) {
        this.onGoToStep(STEPS.RECAPITULATIF);
        this.stepRecapitulatifComponent.loadDataCommande(
          numeroIdCommandeDuplicata
        );
      }
      this.stepRecapitulatifInitie = true;
    }
  }

  onGoToStep(step: string) {
    this.tabs.activeTabByName(step);
    switch (step) {
      case STEPS.ENGAGEMENT:
        this.stepEngagementComponent.loadStep();
        break;
      case STEPS.CHOIXDEMANDE:
        this.stepChoixDemandeComponent.loadStep();
        break;
      case STEPS.LIVRAISON:
        this.stepLivraisonComponent.loadStep();
        break;
      case STEPS.PAIEMENT:
        this.stepPaiementComponent.loadStep();
        break;
      case STEPS.RECAPITULATIF:
        this.stepRecapitulatifComponent.loadStep();
        break;
      default:
        break;
    }
  }
}
