import { Component, NgZone, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { Http } from "@angular/http";
import { Subscription } from "rxjs";

import { AuthentificationService } from "../authentification/authentification.service";
import { ImageService } from "./image.service";
import { StatusTeleversementPhoto } from "./models/status-televersement-photo";
import { PhotoService } from "./photo.service";

import * as pdfjsLib from "pdfjs-dist";
import { SpinnerGeneralService } from "../common/components/spinner-general/spinner-general.service";

pdfjsLib.GlobalWorkerOptions.workerSrc = "./assets/libs/pdf.worker.min.js";

declare var $: any;

@Component({
  selector: "in-photo",
  templateUrl: "./photo.component.html",
  styleUrls: ["./photo.component.scss"],
})
export class PhotoComponent implements OnInit, OnDestroy {
  private benefId: string;

  @ViewChild("imageDropper") imageDropper;
  @ViewChild("imageInput") imageInput;
  @ViewChild("imageInputPath") imageInputPath;
  @ViewChild("imageEditor") imageEditor;

  step: number;
  error?: string;
  image?: HTMLImageElement;
  private ctx?: CanvasRenderingContext2D;
  private canvases: HTMLCanvasElement[] = [];
  cropping?: {
    from: {
      x: number;
      y: number;
    };
    to: {
      x: number;
      y: number;
    };
    moving?: "NO" | "SO" | "SE" | "NE" | "CC";
  };

  nbJoursBlocage: number = 0;
  statusTeleversementPhoto: StatusTeleversementPhoto = null;
  codeRetour = null;
  isPhotoConforme = true;

  utilisateurSubscription: Subscription;

  constructor(
    private zone: NgZone,
    private photoService: PhotoService,
    private imageService: ImageService,
    private http: Http,
    private spinner: SpinnerGeneralService,
    private authentificationService: AuthentificationService
  ) {}

  ngOnInit() {
    this.step = 1;
    this.ctx = this.imageEditor.nativeElement.getContext("2d");

    const that = this;
    this.photoService
      .getNombreJoursBlocage()
      .then((reponse) => (that.nbJoursBlocage = reponse))
      .catch((err) => (that.nbJoursBlocage = 8));

    this.utilisateurSubscription = this.authentificationService
      .utilisateurConnecte()
      .subscribe((u) => {
        const third = that;
        if (u !== null) {
          that.benefId =
            u.identifiant !== null && u.identifiant.length > 5
              ? u.identifiant.substring(5)
              : "0";
          that.photoService
            .getStatusTeleversementPhoto(that.benefId)
            .then((reponse) => {
              third.statusTeleversementPhoto = reponse;
            })
            .catch((err) => {
              third.statusTeleversementPhoto = new StatusTeleversementPhoto();
              third.statusTeleversementPhoto.televersementEligible = false;
              third.statusTeleversementPhoto.motif = "ERREUR_ACCESS_BACK";
            });
        } else {
          that.benefId = null;
        }
      });
  }

  gotoStep(step: number) {
    this.showError(undefined);
    this.step = step;
    if (step === 3) {
      this.checkStep3();
    }
  }

  get nextAvailable(): boolean {
    switch (this.step) {
      case 1:
        return (
          !(
            this.statusTeleversementPhoto &&
            this.statusTeleversementPhoto.televersementEligible === false
          ) && this.codeRetour !== "OK"
        );
      case 2:
        return this.canvases.length > 0;
      case 3:
        return (
          this.isPhotoConforme &&
          this.imageEditor.nativeElement.width *
            this.imageEditor.nativeElement.height >
            0 &&
          this.cropping === undefined
        );
    }
    return false;
  }

  checkStep3() {
    this.checkPhotoConforme();
  }

  /**
   * Vérifie que la photo recadrée est bien au format 35*45
   */
  checkPhotoConforme() {
    this.isPhotoConforme =
      Math.abs(
        35 / 45 -
          this.imageEditor.nativeElement.width /
            this.imageEditor.nativeElement.height
      ) < 0.01;
  }

  showError(msg?: string) {
    this.zone.run(() => {
      this.error = msg;
    });
  }

  onImageDropperDragover(e) {
    e.preventDefault();
    e.stopPropagation();
  }

  onImageDropperDragleave(e) {
    e.preventDefault();
    e.stopPropagation();
  }

  onImageDrop(e) {
    e.preventDefault();
    e.stopPropagation();

    const files = e.dataTransfer.files;

    if (files.length > 0) {
      this.handleFile(files[0]);
    }
  }

  openImageUploader() {
    this.imageInput.nativeElement.click();
  }

  onImageUploaded() {
    const files = this.imageInput.nativeElement.files;

    if (files.length > 0) {
      this.imageInputPath.nativeElement.value = files[0].name;
      this.handleFile(files[0]);
    }
  }

  handleFile(file) {
    this.showError(undefined);
    this.canvases = [];
    this.image = undefined;

    if (!this.imageService.verifierPoidsLeger(file)) {
      this.showError("Taille inférieure aux 50 Ko autorisés");
      return;
    }

    if (!this.imageService.verifierPoidsLourd(file)) {
      this.showError("Taille supérieure aux 2Mo autorisés");
      return;
    }

    this.imageService.getTypeMIME(file).then((mime) => {
      new Promise((resolve, reject) => {
        if (file.name.toLowerCase().indexOf(".pdf") !== -1) {
          // Try to open as PDF
          console.log("Try to open PDF");
          const fileReader = new FileReader();
          fileReader.onload = () => {
            const src = fileReader.result as any;
            const image = new Image();
            image.onload = () => {
              console.log("Succeeded");
              resolve(image);
            };
            image.onerror = (e) => {
              console.error(e);
              reject(
                "PDF corrompu ou illisible. Veuillez en envoyer un autre."
              );
              return;
            };
            pdfjsLib
              .getDocument(new Uint8Array(src))
              .then((pdf) => pdf.getPage(1))
              .then((page) => {
                const viewport = page.getViewport({ scale: 1 });
                const canvas = document.createElement("canvas");
                const ctx = canvas.getContext("2d");
                canvas.width = viewport.width;
                canvas.height = viewport.height;
                page.render({ canvasContext: ctx, viewport }).then(() => {
                  this.zone.run(() => {
                    image.src = canvas.toDataURL("image/jpeg");
                  });
                });
              })
              .catch((e) => {
                console.error(e);
                reject(
                  "PDF corrompu ou illisible. Veuillez en envoyer un autre."
                );
                return;
              });
          };
          fileReader.readAsArrayBuffer(file);
        } else {
          // Try to open as IMG
          console.log("Try to open IMG");
          const fileReader = new FileReader();
          fileReader.onload = () => {
            const src = fileReader.result as any;
            const image = new Image();
            image.onload = () => {
              console.log("Succeeded");
              resolve(image);
            };
            image.onerror = (e) => {
              console.error(e);
              reject(
                "Image corrompue ou illisible. Veuillez en envoyer une autre."
              );
              return;
            };
            this.zone.run(() => {
              image.src = src;
            });
          };
          fileReader.readAsDataURL(file);
        }
      })
        .then(
          (image: HTMLImageElement) =>
            new Promise((resolve, reject) => {
              this.zone.run(() => {
                this.image = image;
                if (
                  !this.imageService.verifierDimension(
                    this.image.height,
                    this.image.width
                  )
                ) {
                  reject(
                    "Dimensions non respectées, veuillez-vous référer au mode opératoire disponible <a href='/api/documentation/download/comment-recadrer-une-photo'>ici</a>."
                  );
                  return;
                }

                const canvas = document.createElement("canvas");
                canvas.width = this.image.width;
                canvas.height = this.image.height;
                const ctx = canvas.getContext("2d");
                ctx.drawImage(this.image, 0, 0);

                this.canvases = [canvas];
                this.updateImageEditor();
                resolve(true);
              });
            })
        )
        .catch((error: string) => {
          console.warn(error);
          this.showError(error);
          return;
        });
    });
  }

  updateImageEditor() {
    if (this.canvases.length) {
      const canvas = this.canvases[this.canvases.length - 1];
      this.imageEditor.nativeElement.width = canvas.width;
      this.imageEditor.nativeElement.height = canvas.height;
      this.ctx.drawImage(canvas, 0, 0);

      if (this.cropping) {
        this.ctx.strokeStyle = "black";
        this.ctx.lineWidth =
          Math.ceil(
            this.imageEditor.nativeElement.width /
              this.imageEditor.nativeElement.clientWidth
          ) + 1;

        this.ctx.beginPath();
        this.ctx.moveTo(this.cropping.from.x, this.cropping.from.y);
        this.ctx.lineTo(this.cropping.to.x, this.cropping.from.y);
        this.ctx.lineTo(this.cropping.to.x, this.cropping.to.y);
        this.ctx.lineTo(this.cropping.from.x, this.cropping.to.y);
        this.ctx.lineTo(this.cropping.from.x, this.cropping.from.y);
        this.ctx.stroke();

        this.ctx.lineWidth =
          Math.ceil(
            (8 * this.imageEditor.nativeElement.width) /
              this.imageEditor.nativeElement.clientWidth
          ) + 1;

        this.ctx.beginPath();

        // NO
        this.ctx.moveTo(
          this.cropping.from.x,
          Math.min(this.cropping.from.y + 40, this.cropping.to.y)
        );
        this.ctx.lineTo(this.cropping.from.x, this.cropping.from.y);
        this.ctx.lineTo(
          Math.min(this.cropping.from.x + 40, this.cropping.to.x),
          this.cropping.from.y
        );

        // NE
        this.ctx.moveTo(
          this.cropping.to.x,
          Math.min(this.cropping.from.y + 40, this.cropping.to.y)
        );
        this.ctx.lineTo(this.cropping.to.x, this.cropping.from.y);
        this.ctx.lineTo(
          Math.max(this.cropping.to.x - 40, this.cropping.from.x),
          this.cropping.from.y
        );

        // SE
        this.ctx.moveTo(
          this.cropping.to.x,
          Math.max(this.cropping.to.y - 40, this.cropping.from.y)
        );
        this.ctx.lineTo(this.cropping.to.x, this.cropping.to.y);
        this.ctx.lineTo(
          Math.max(this.cropping.to.x - 40, this.cropping.from.x),
          this.cropping.to.y
        );

        // SO
        this.ctx.moveTo(
          this.cropping.from.x,
          Math.max(this.cropping.to.y - 40, this.cropping.from.y)
        );
        this.ctx.lineTo(this.cropping.from.x, this.cropping.to.y);
        this.ctx.lineTo(
          Math.min(this.cropping.from.x + 40, this.cropping.to.x),
          this.cropping.to.y
        );

        this.ctx.stroke();
      }
    }
  }

  imageRotate(angle: number) {
    const canvas = document.createElement("canvas");
    canvas.width =
      (Math.abs(angle) / 90) % 2 === 1
        ? this.imageEditor.nativeElement.height
        : this.imageEditor.nativeElement.width;
    canvas.height =
      (Math.abs(angle) / 90) % 2 === 1
        ? this.imageEditor.nativeElement.width
        : this.imageEditor.nativeElement.height;
    const ctx = canvas.getContext("2d");
    ctx.translate(canvas.width / 2, canvas.height / 2);
    ctx.rotate((angle / 180) * Math.PI);
    ctx.drawImage(
      this.imageEditor.nativeElement,
      -this.imageEditor.nativeElement.width / 2,
      -this.imageEditor.nativeElement.height / 2
    );

    this.canvases.push(canvas);
    this.updateImageEditor();
    this.checkPhotoConforme();
  }

  imageCropStart() {
    if (
      Math.abs(
        35 / 45 -
          this.imageEditor.nativeElement.width /
            this.imageEditor.nativeElement.height
      ) < 0.01
    ) {
      this.cropping = {
        from: {
          x: 0,
          y: 0,
        },
        to: {
          x: this.imageEditor.nativeElement.width,
          y: this.imageEditor.nativeElement.height,
        },
      };
    } else {
      this.cropping = {
        from: {
          x: Math.min(30, this.imageEditor.nativeElement.width * (1 / 4)),
          y: Math.min(30, this.imageEditor.nativeElement.height * (1 / 4)),
        },
        to: {
          x: Math.max(
            this.imageEditor.nativeElement.width - 30,
            this.imageEditor.nativeElement.width * (3 / 4)
          ),
          y: Math.max(
            this.imageEditor.nativeElement.height - 30,
            this.imageEditor.nativeElement.height * (3 / 4)
          ),
        },
      };
    }

    if (
      (this.cropping.to.x - this.cropping.from.x) /
        (this.cropping.to.y - this.cropping.from.y) <
      35.0 / 45.0
    ) {
      const offset =
        this.cropping.to.y -
        this.cropping.from.y -
        ((this.cropping.to.x - this.cropping.from.x) * 45.0) / 35.0;
      this.cropping.from.y += offset / 2;
      this.cropping.to.y -= offset / 2;
    } else {
      const offset =
        this.cropping.to.x -
        this.cropping.from.x -
        ((this.cropping.to.y - this.cropping.from.y) * 35.0) / 45.0;
      this.cropping.from.x += offset / 2;
      this.cropping.to.x -= offset / 2;
    }

    this.updateImageEditor();
  }

  imageCropCancel() {
    this.cropping = undefined;
    this.updateImageEditor();
    this.checkPhotoConforme();
  }

  imageCropEnd() {
    const cropping = this.cropping;
    this.cropping = undefined;
    this.updateImageEditor();
    this.cropping = cropping;

    const canvas = document.createElement("canvas");
    canvas.width =
      this.cropping.to.x - this.cropping.from.x ||
      this.imageEditor.nativeElement.width - this.cropping.from.x;
    canvas.height =
      this.cropping.to.y - this.cropping.from.y ||
      this.imageEditor.nativeElement.height - this.cropping.from.y;
    const ctx = canvas.getContext("2d");
    ctx.drawImage(
      this.imageEditor.nativeElement,
      this.cropping.from.x,
      this.cropping.from.y,
      this.cropping.to.x - this.cropping.from.x ||
        this.imageEditor.nativeElement.width - this.cropping.from.x,
      this.cropping.to.y - this.cropping.from.y ||
        this.imageEditor.nativeElement.height - this.cropping.from.y,
      0,
      0,
      this.cropping.to.x - this.cropping.from.x ||
        this.imageEditor.nativeElement.width - this.cropping.from.x,
      this.cropping.to.y - this.cropping.from.y ||
        this.imageEditor.nativeElement.height - this.cropping.from.y
    );

    this.canvases.push(canvas);
    this.cropping = undefined;
    this.updateImageEditor();
    this.checkPhotoConforme();
  }

  imageEditorMousedown(event) {
    if (this.cropping) {
      const coords = {
        x: (event.offsetX * event.target.width) / event.target.clientWidth,
        y: (event.offsetY * event.target.height) / event.target.clientHeight,
      };
      const dists = [
        [
          "NO",
          Math.sqrt(
            Math.pow(this.cropping.from.x - coords.x, 2) +
              Math.pow(this.cropping.from.y - coords.y, 2)
          ),
        ],
        [
          "SO",
          Math.sqrt(
            Math.pow(this.cropping.from.x - coords.x, 2) +
              Math.pow(this.cropping.to.y - coords.y, 2)
          ),
        ],
        [
          "SE",
          Math.sqrt(
            Math.pow(this.cropping.to.x - coords.x, 2) +
              Math.pow(this.cropping.to.y - coords.y, 2)
          ),
        ],
        [
          "NE",
          Math.sqrt(
            Math.pow(this.cropping.to.x - coords.x, 2) +
              Math.pow(this.cropping.from.y - coords.y, 2)
          ),
        ],
        [
          "CC",
          Math.sqrt(
            Math.pow(
              (this.cropping.to.x - this.cropping.from.x) / 2 +
                this.cropping.from.x -
                coords.x,
              2
            ) +
              Math.pow(
                (this.cropping.to.y - this.cropping.from.y) / 2 +
                  this.cropping.from.y -
                  coords.y,
                2
              )
          ),
        ],
      ];

      this.cropping.moving = dists.sort(
        ([_1, a]: [string, number], [_2, b]: [string, number]) => a - b
      )[0][0] as "NO" | "SO" | "SE" | "NE" | "CC";

      this.updateImageEditor();
    }
  }

  imageEditorConstrainCrop() {
    const xor = (a: boolean, b: boolean): boolean => (a || b) && !(a && b);
    const format = (shrink: boolean) => {
      switch (this.cropping.moving) {
        case "NO":
          if (
            xor(
              shrink,
              (this.cropping.to.x - this.cropping.from.x) /
                (this.cropping.to.y - this.cropping.from.y) <
                35.0 / 45.0
            )
          ) {
            this.cropping.from.x =
              this.cropping.to.x -
              (35.0 / 45.0) * (this.cropping.to.y - this.cropping.from.y);
          } else {
            this.cropping.from.y =
              this.cropping.to.y -
              (45.0 / 35.0) * (this.cropping.to.x - this.cropping.from.x);
          }
          break;
        case "SO":
          if (
            xor(
              shrink,
              (this.cropping.to.x - this.cropping.from.x) /
                (this.cropping.to.y - this.cropping.from.y) <
                35.0 / 45.0
            )
          ) {
            this.cropping.from.x =
              this.cropping.to.x -
              (35.0 / 45.0) * (this.cropping.to.y - this.cropping.from.y);
          } else {
            this.cropping.to.y =
              this.cropping.from.y +
              (45.0 / 35.0) * (this.cropping.to.x - this.cropping.from.x);
          }
          break;
        case "SE":
          if (
            xor(
              shrink,
              (this.cropping.to.x - this.cropping.from.x) /
                (this.cropping.to.y - this.cropping.from.y) <
                35.0 / 45.0
            )
          ) {
            this.cropping.to.x =
              this.cropping.from.x +
              (35.0 / 45.0) * (this.cropping.to.y - this.cropping.from.y);
          } else {
            this.cropping.to.y =
              this.cropping.from.y +
              (45.0 / 35.0) * (this.cropping.to.x - this.cropping.from.x);
          }
          break;
        case "NE":
          if (
            xor(
              shrink,
              (this.cropping.to.x - this.cropping.from.x) /
                (this.cropping.to.y - this.cropping.from.y) <
                35.0 / 45.0
            )
          ) {
            this.cropping.to.x =
              this.cropping.from.x +
              (35.0 / 45.0) * (this.cropping.to.y - this.cropping.from.y);
          } else {
            this.cropping.from.y =
              this.cropping.to.y -
              (45.0 / 35.0) * (this.cropping.to.x - this.cropping.from.x);
          }
          break;
      }
    };

    format(false);

    this.cropping.to = {
      x: Math.min(
        this.imageEditor.nativeElement.width,
        Math.max(this.cropping.from.x, this.cropping.to.x)
      ),
      y: Math.min(
        this.imageEditor.nativeElement.height,
        Math.max(this.cropping.from.y, this.cropping.to.y)
      ),
    };
    this.cropping.from = {
      x: Math.max(0, Math.min(this.cropping.from.x, this.cropping.to.x)),
      y: Math.max(0, Math.min(this.cropping.from.y, this.cropping.to.y)),
    };

    format(true);
  }

  imageEditorMousemove(event) {
    if (this.cropping && this.cropping.moving !== undefined) {
      switch (this.cropping.moving) {
        case "NO":
          this.cropping.from.x =
            (event.offsetX * event.target.width) / event.target.clientWidth;
          this.cropping.from.y =
            (event.offsetY * event.target.height) / event.target.clientHeight;
          break;
        case "SO":
          this.cropping.from.x =
            (event.offsetX * event.target.width) / event.target.clientWidth;
          this.cropping.to.y =
            (event.offsetY * event.target.height) / event.target.clientHeight;
          break;
        case "SE":
          this.cropping.to.x =
            (event.offsetX * event.target.width) / event.target.clientWidth;
          this.cropping.to.y =
            (event.offsetY * event.target.height) / event.target.clientHeight;
          break;
        case "NE":
          this.cropping.to.x =
            (event.offsetX * event.target.width) / event.target.clientWidth;
          this.cropping.from.y =
            (event.offsetY * event.target.height) / event.target.clientHeight;
          break;
        case "CC":
          {
            const offset = {
              x:
                (event.offsetX * event.target.width) /
                  event.target.clientWidth -
                ((this.cropping.to.x - this.cropping.from.x) / 2 +
                  this.cropping.from.x),
              y:
                (event.offsetY * event.target.height) /
                  event.target.clientHeight -
                ((this.cropping.to.y - this.cropping.from.y) / 2 +
                  this.cropping.from.y),
            };
            offset.x =
              offset.x > 0
                ? Math.min(
                    offset.x,
                    this.imageEditor.nativeElement.width - this.cropping.to.x
                  )
                : Math.max(offset.x, -this.cropping.from.x);
            offset.y =
              offset.y > 0
                ? Math.min(
                    offset.y,
                    this.imageEditor.nativeElement.height - this.cropping.to.y
                  )
                : Math.max(offset.y, -this.cropping.from.y);

            this.cropping.from.x += offset.x;
            this.cropping.from.y += offset.y;
            this.cropping.to.x += offset.x;
            this.cropping.to.y += offset.y;
          }
          break;
      }

      this.imageEditorConstrainCrop();

      this.updateImageEditor();
    }
  }

  imageEditorMouseup(event) {
    if (this.cropping) {
      this.cropping.moving = undefined;
    }
  }

  imageCancel() {
    if (this.canvases.length > 1) {
      this.canvases.pop();
      this.updateImageEditor();
      this.checkPhotoConforme();
    }
  }

  async reduceWeight(
    image: HTMLCanvasElement,
    maxWeight: number
  ): Promise<string> {
    const shrink = (
      image: HTMLCanvasElement,
      width: number,
      height: number,
      quality: number
    ): Promise<string> =>
      new Promise((resolve) => {
        const resizedImage = new Image(width, height);
        resizedImage.src = image.toDataURL("image/png");
        resizedImage.onload = () => {
          const resizedCanvas = document.createElement("canvas");
          resizedCanvas.width = width;
          resizedCanvas.height = height;
          resizedCanvas
            .getContext("2d")
            .drawImage(resizedImage, 0, 0, width, height);
          resolve(resizedCanvas.toDataURL("image/jpeg", quality));
        };
      });

    const [width, height] = [413, 531];
    let resized = await shrink(image, width, height, 0.92);
    for (const quality of [0.82, 0.72, 0.62, 0.52]) {
      if ((resized.length * 6) / 8 < maxWeight) {
        break;
      }
      resized = await shrink(image, width, height, quality);
    }
    return resized;
  }

  send() {
    const dialog = $("#popinValidate");
    dialog.dialog({
      resizable: false,
      height: "auto",
      width: 400,
      modal: true,
      buttons: {
        Oui: () => {
          dialog.dialog("close");
          this.spinner.show();

          this.reduceWeight(this.imageEditor.nativeElement, 85 * 1024).then(
            (src) => {
              this.http
                .post("/api/beneficiaire/uploadPhoto/" + this.benefId, {
                  filename: "photo.jpeg",
                  data: src,
                })
                .subscribe(
                  (rep) => {
                    this.codeRetour = rep.json();
                    this.spinner.hide();
                    switch (this.codeRetour) {
                      case "OK":
                        this.gotoStep(1);
                        break;
                      case "VIRUS_DETECTE":
                        this.showError("Un virus a été détecté dans la photo.");
                        break;
                      case "FICHIER_MANQUANT":
                        this.showError("Aucune photo présente.");
                        break;
                      case "VOLUME_KO":
                        this.showError(
                          "Suite aux modifications apportées la photo présente une anomalie, le poids n'est désormais plus conforme, veuillez annuler les modifications et réitérer l'opération."
                        );
                        break;
                      default:
                        this.showError(
                          "Une erreur système a eu lieu lors du transfert, veuillez réessayer."
                        );
                    }
                  },
                  (error) => {
                    this.spinner.hide();
                    this.showError(
                      "Une erreur système a eu lieu lors du transfert, veuillez réessayer."
                    );
                  }
                );
            }
          );
        },
        Non: () => {
          dialog.dialog("close");
        },
      },
    });
  }

  ngOnDestroy() {
    this.utilisateurSubscription.unsubscribe();
  }
}
