import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { FranceConnectService } from "./franceconnect.service";

@Component({
  selector: "fc-authorize-mobile",
  template: "",
})
export class FCAuthorizeMobileComponent implements OnInit {
  uiid: string;

  constructor(
    private franceconnectService: FranceConnectService,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.uiid = this.route.snapshot.queryParamMap.get("uiid");
    if (this.uiid) {
      sessionStorage.setItem("portal_uiid", this.uiid);
    }

    this.franceconnectService.loadProps().then((ok) => {
      if (ok) {
        this.franceconnectService.login(
          this.franceconnectService.CHANNEL_MOBILE
        );
      } else {
        this.franceconnectService.returnUserJson({}, null, this.uiid);
      }
    });
  }
}
