import { Attribute, Directive, forwardRef } from "@angular/core";
import {
  AbstractControl,
  NG_VALIDATORS,
  Validator,
  ValidatorFn,
} from "@angular/forms";

/*
 * Méthode de validation.
 */
export function coordonneesTelephoniquesValidator(): ValidatorFn {
  return function (c: AbstractControl): { [key: string]: boolean } {
    const v = c.value;
    if (v == "undefined" || v == null) return null;
    const val = v.replace(/\s/g, "");

    // indicatifs autorisés
    const indicatifs = [
      "+32",
      "+33",
      "+34",
      "+39",
      "+41",
      "+49",
      "+262",
      "+351",
      "+352",
      "+376",
      "+377",
      "+508",
      "+590",
      "+594",
      "+596",
      "+681",
      "+689",
    ];

    // controle indicatif
    let correct = false;
    for (let i = 0; i < indicatifs.length; i++) {
      if (val.indexOf(indicatifs[i]) == 0) correct = true;
    }

    // controle format
    if (correct) {
      correct = false;
      if (val.length == 12) if (val.match("[+]{1}[0-9]{11}")) correct = true;
      if (val.length == 13) if (val.match("[+]{1}[0-9]{12}")) correct = true;
    }

    if (!correct) {
      return {
        validateCoordonneesTelephoniques: false,
      };
    }
    return null;
  };
}

@Directive({
  selector:
    "[coordonneesTelephoniques][formControlName],[coordonneesTelephoniques][formControl],[coordonneesTelephoniques][ngModel]",
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => CoordonneesTelephoniquesValidator),
      multi: true,
    },
  ],
})
export class CoordonneesTelephoniquesValidator implements Validator {
  constructor(@Attribute("coordonneesTelephoniques") public equals: string) {}

  validate(c: AbstractControl): { [key: string]: boolean } {
    return coordonneesTelephoniquesValidator()(c);
  }
}
