import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from "@angular/router";

import { AuthentificationService } from "src/app/authentification/authentification.service";

@Injectable()
export class CanActivateADroitGuard implements CanActivate {
  constructor(
    private authService: AuthentificationService,
    private router: Router
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean> {
    return new Promise((resolve) => {
      const nomDroit = route.data["droit"];

      if (!nomDroit) {
        console.log(
          `[CanActivateViaAuthGuard] Pas de droit defini pour ${state.url}`
        );
        resolve(true);
      } else {
        // appel de l'initialisation pour récupérer les droits de l'utilisateur avant d'effectuer la vérification
        this.authService.initialiserDroits().then(() => {
          console.log(
            `[CanActivateViaAuthGuard] Droit defini pour ${state.url} : ${nomDroit}`
          );

          const droit = this.authService.getDroit(nomDroit);
          console.log(
            "[CanActivateViaAuthGuard] Verification du droit.",
            droit
          );

          if (!droit) {
            console.log(`[CanActivateViaAuthGuard] Redirection.`);
            this.router.navigateByUrl("/");
          }

          resolve(droit);
        });
      }
    });
  }
}
