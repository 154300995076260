import { NgModule } from "@angular/core";
import { HttpModule } from "@angular/http";
import { BrowserModule } from "@angular/platform-browser";

import { AccessibiliteModule } from "./accessibilite/accessibilite.module";
import { AideModule } from "./aide/aide.module";
import { AlertesModule } from "./alertes/alertes.module";
import { AuthentificationModule } from "./authentification/authentification.module";
import { BeneficiaireComponent } from "./beneficiaire.component";
import { BeneficiaireRoutingModule } from "./beneficiaire.routing";
import { CgruModule } from "./cgru/cgru.module";
import { CguModule } from "./cgu/cgu.module";
import { FooterModule } from "./common/components/footer/footer.module";
import { HautDePageModule } from "./common/components/haut-de-page/haut-de-page.module";
import { HeaderModule } from "./common/components/header/header.module";
import { SpinnerGeneralModule } from "./common/components/spinner-general/spinner-general.module";
import { ContactModule } from "./contact/contact.module";
import { DemandeModule } from "./demande/demande.module";
import { ErreurServiceModule } from "./erreur-service/erreur-service.module";
import { FaqModule } from "./faq/faq.module";
import { MentionsLegalesModule } from "./mentions-legales/mentions-legales.module";
import { MesDonneesModule } from "./mes-donnees/mes-donnees.module";
import { PhotoModule } from "./photo/photo.module";
import { PlanModule } from "./plan/plan.module";
import { VehiculesModule } from "./vehicules/vehicules.module";

@NgModule({
  imports: [
    BrowserModule,
    HttpModule,
    BeneficiaireRoutingModule,
    AccessibiliteModule,
    AideModule,
    AlertesModule,
    AuthentificationModule,
    CgruModule,
    CguModule,
    ContactModule,
    DemandeModule,
    ErreurServiceModule,
    FaqModule,
    FooterModule,
    HautDePageModule,
    HeaderModule,
    MentionsLegalesModule,
    MesDonneesModule,
    PhotoModule,
    PlanModule,
    SpinnerGeneralModule,
    VehiculesModule,
  ],
  declarations: [BeneficiaireComponent],
  bootstrap: [BeneficiaireComponent],
})
export class BeneficiaireModule {}
