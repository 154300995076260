import { AfterViewChecked, Component, OnDestroy, OnInit } from "@angular/core";
import { Subscription } from "rxjs/Subscription";

import { first } from "rxjs/operators";
import { AuthentificationService } from "../../authentification/authentification.service";
import { Utilisateur } from "../../authentification/models/utilisateur";
import { DemandeService } from "../../demande/demande.service";
import { MesDonneesService } from "../mes-donnees.service";
import { SuiviDemandeTitre } from "../models/suivi-demande-titre";

declare var $: any;

@Component({
  selector: "in-suivi-demande",
  templateUrl: "./suivi-demande.component.html",
  styleUrls: ["./suivi-demande.component.scss"],
})
export class SuiviDemandeComponent
  implements OnDestroy, OnInit, AfterViewChecked
{
  public titresBeneficiaire: SuiviDemandeTitre[] = null;
  public utilisateur: Utilisateur = null;
  private titresCharges: boolean = false;

  utilisateurSubscription: Subscription;

  constructor(
    private authentificationService: AuthentificationService,
    private mesDonneesService: MesDonneesService,
    private demandeService: DemandeService
  ) {}

  public ngOnInit() {
    this.titresCharges = false;
    this.utilisateurSubscription = this.authentificationService
      .utilisateurConnecte()
      .pipe(first())
      .subscribe((utilisateur) => {
        this.utilisateur = utilisateur;
        if (this.utilisateur != null) {
          this.mesDonneesService
            .titresBeneficiaire(this.utilisateur.identifiant)
            .subscribe((titresBeneficiaire) => {
              this.titresBeneficiaire = titresBeneficiaire;
              this.titresCharges = true;
              if (this.titresBeneficiaire != null) {
                console.log(
                  "[SuiviDemandeComponent] titresBeneficiaire",
                  this.titresBeneficiaire
                );
              }
            });
        }
      });
  }

  public ngAfterViewChecked() {
    if (this.titresCharges) {
      this.titresCharges = false;
      $(".js-accordion").accordion();
      $(".animated-accordion__title").attr("tabindex", -1);
    }
  }

  downloadCoupon(titreNumeroIdCommande: number) {
    console.log("downloadCoupon", titreNumeroIdCommande);
    this.demandeService
      .couponReglementCheque(titreNumeroIdCommande)
      .then((reponseCoupon) => {
        console.log("[SuiviDemandeComponent] reponseCoupon", reponseCoupon);

        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(
            reponseCoupon.blob(),
            "couponReglementCheque.pdf"
          );
        } else {
          const link = document.createElement("a");
          link.href = window.URL.createObjectURL(reponseCoupon.blob());
          link.download = "couponReglementCheque.pdf";
          document.body.appendChild(link);
          link.click();

          // On libère la mémoire après 1s
          setTimeout(() => {
            document.body.removeChild(link);
            window.URL.revokeObjectURL(link.href);
          }, 1000);
        }
      });
  }

  ngOnDestroy() {
    this.utilisateurSubscription.unsubscribe();
  }
}
