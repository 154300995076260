import { Injectable } from "@angular/core";
import { Http, RequestOptions, URLSearchParams } from "@angular/http";
import { Observable } from "rxjs/Observable";

import { Vehicule } from "./models/vehicule";

@Injectable()
export class VehiculesService {
  constructor(private http: Http) {}
  private portal: string = "PORTAL";

  /**
   * Méthode à appeler pour récupérer la liste des véhicules du bénéficiaire
   */
  vehiculesBeneficiaire(
    identifiantBeneficiaire: string
  ): Observable<Vehicule[]> {
    return this.http
      .get(
        `/api/immatriculation/immatriculation/private/${identifiantBeneficiaire}`
      )
      .map((response) => response.json() as Vehicule[]);
  }

  /**
   * Méthode pour supprimer un véhicule du bénéficiaire
   */
  supprimerVehicule(
    identifiantBeneficiaire: string,
    immatriculation: string,
    immatCountry: string
  ): Promise<boolean> {
    // build data
    const options = new RequestOptions();
    const parameters: URLSearchParams = new URLSearchParams();
    parameters.set("beneficiaryId", identifiantBeneficiaire);
    parameters.set("immatriculation", immatriculation);
    parameters.set("immatCountry", immatCountry);
    parameters.set("communicationChannel", this.portal);
    options.params = parameters;

    // call webservice
    return new Promise((resolve, reject) => {
      this.http
        .delete(`/api/immatriculation/immatriculation/private/delete`, options)
        .toPromise()
        .then((response) => resolve(response.status === 204))
        .catch(reject);
    });
  }

  /**
   * Méthode pour locker/délocker la modification d'un véhicule du bénéficiaire
   */
  startModifyVehicule(
    identifiantBeneficiaire: string,
    immatriculation: string,
    immatCountry: string,
    isBeingModified: string
  ): Promise<boolean> {
    // build data
    const options = new RequestOptions();
    const parameters: URLSearchParams = new URLSearchParams();
    parameters.set("beneficiaryId", identifiantBeneficiaire);
    parameters.set("immatriculation", immatriculation);
    parameters.set("immatCountry", immatCountry);
    parameters.set("isBeingModified", isBeingModified);
    options.params = parameters;

    // call webservice
    return new Promise((resolve, reject) => {
      this.http
        .put(
          `/api/immatriculation/immatriculation/private/startModification`,
          null,
          options
        )
        .toPromise()
        .then((response) => resolve(response.status === 204))
        .catch(reject);
    });
  }

  /**
   * Méthode pour modifier un véhicule du bénéficiaire
   */
  modifierVehicule(
    identifiantBeneficiaire: string,
    immatriculation: string,
    immatCountry: string,
    nameVehicule: string
  ): Promise<boolean> {
    // build data
    const options = new RequestOptions();
    const parameters: URLSearchParams = new URLSearchParams();
    parameters.set("beneficiaryId", identifiantBeneficiaire);
    parameters.set("immatriculation", immatriculation);
    parameters.set("immatCountry", immatCountry);
    parameters.set("nameVehicule", nameVehicule);
    parameters.set("communicationChannel", this.portal);
    options.params = parameters;

    // call webservice
    return new Promise((resolve, reject) => {
      this.http
        .put(
          `/api/immatriculation/immatriculation/private/update`,
          null,
          options
        )
        .toPromise()
        .then((response) => resolve(response.status === 200))
        .catch(reject);
    });
  }

  /**
   * Méthode pour activer un véhicule du bénéficiaire
   */
  activerVehicule(
    identifiantBeneficiaire: string,
    immatriculation: string,
    immatCountry: string
  ): Promise<boolean> {
    // build data
    const options = new RequestOptions();
    const parameters: URLSearchParams = new URLSearchParams();
    parameters.set("beneficiaryId", identifiantBeneficiaire);
    parameters.set("immatriculation", immatriculation);
    parameters.set("immatCountry", immatCountry);
    parameters.set("communicationChannel", this.portal);
    options.params = parameters;

    // call webservice
    return new Promise((resolve, reject) => {
      this.http
        .post(
          `/api/immatriculation/immatriculation/private/activate`,
          null,
          options
        )
        .toPromise()
        .then((response) => resolve(response.status === 200))
        .catch(reject);
    });
  }

  /**
   * Méthode pour désactiver un véhicule du bénéficiaire
   */
  desactiverVehicule(
    identifiantBeneficiaire: string,
    immatriculation: string,
    immatCountry: string
  ): Promise<boolean> {
    // build data
    const options = new RequestOptions();
    const parameters: URLSearchParams = new URLSearchParams();
    parameters.set("beneficiaryId", identifiantBeneficiaire);
    parameters.set("immatriculation", immatriculation);
    parameters.set("immatCountry", immatCountry);
    parameters.set("communicationChannel", this.portal);
    options.params = parameters;

    // call webservice
    return new Promise((resolve, reject) => {
      this.http
        .post(
          `/api/immatriculation/immatriculation/private/deactivate`,
          null,
          options
        )
        .toPromise()
        .then((response) => resolve(response.status === 200))
        .catch(reject);
    });
  }

  /**
   * Méthode pour ajouter un véhicule au bénéficiaire
   */
  ajouterVehicule(
    identifiantBeneficiaire: string,
    immatriculation: string,
    immatCountry: string,
    nameVehicule: string
  ): Promise<boolean> {
    // build data
    const options = new RequestOptions();
    const parameters: URLSearchParams = new URLSearchParams();
    parameters.set("communicationChannel", this.portal);
    options.params = parameters;

    let body: any = {
      beneficiaryId: identifiantBeneficiaire,
      immatriculation: immatriculation,
      immatCountry: immatCountry,
      nameVehicule: nameVehicule,
    };

    // call webservice
    return new Promise((resolve, reject) => {
      this.http
        .post(
          `/api/immatriculation/immatriculation/private/supply`,
          body,
          options
        )
        .toPromise()
        .then((response) => resolve(response.status === 201))
        .catch(reject);
    });
  }
}
