import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";

import { Observable } from "rxjs";
import { v4 as uuidv4 } from "uuid";

import { Props } from "../models/props";
import { PropertiesService } from "../properties.service";

@Injectable()
export class FranceConnectService {
  private props: Props = null;

  FS_URL: string;
  STATE: string;
  NONCE: string;

  CHANNEL_PORTAL = "portal";
  CHANNEL_MOBILE = "mobile";

  ACTIONFORMAIL_LIST = "ONE;MAIN;SECONDARY";
  ACTIONFORMAIL_ONE = "ONE";
  ACTIONFORMAIL_MAIN = "MAIN";
  ACTIONFORMAIL_SECONDARY = "SECONDARY";

  constructor(
    private http: HttpClient,
    private propertiesService: PropertiesService
  ) {
    // our url
    this.FS_URL = window.location.origin;
  }

  // when you need all props loaded before continue the code (ex: login & logout callback)
  loadProps(): Promise<boolean> {
    return new Promise((resolve) => {
      this.propertiesService.getProps().then((props) => {
        this.props = props;

        resolve(
          !!this.props.fcAcr &&
            !!this.props.fcCallbackLogin &&
            !!this.props.fcCallbackLogout &&
            !!this.props.fcClientId &&
            !!this.props.fcClientSecret &&
            !!this.props.fcEnabled &&
            !!this.props.fcScopes &&
            !!this.props.fcUrl
        );
      });
    });
  }

  isEnabled(): boolean {
    if (!this.props) {
      return false;
    }

    return this.props.fcEnabled;
  }

  /**
   *  ---------------- FRANCE CONNECT API ----------------
   */

  getLoginCallback(channel: string): string {
    let cb = `${this.FS_URL}/${this.props.fcCallbackLogin}?channel=${channel}`;
    let uiid = sessionStorage.getItem("portal_uiid");
    if (uiid) {
      cb = cb + "&uiid=" + uiid;
    }
    return cb;
  }

  getLogoutCallback(channel: string): string {
    let cb = `${this.FS_URL}/${this.props.fcCallbackLogout}?channel=${channel}`;
    let uiid = sessionStorage.getItem("portal_uiid");
    if (uiid) {
      cb = cb + "&uiid=" + uiid;
    }
    return cb;
  }

  generateUIID() {
    this.STATE = uuidv4();
    sessionStorage.setItem("fcstate", this.STATE);
    this.NONCE = uuidv4();
    sessionStorage.setItem("fcnonce", this.NONCE);
  }

  getState(): string {
    return sessionStorage.getItem("fcstate");
  }
  getNonce(): string {
    return sessionStorage.getItem("fcnonce");
  }
  checkState(state: string): boolean {
    return state == this.getState();
  }
  checkNonce(id_token: string): boolean {
    const parts: string[] = id_token.split(/\./g);
    let data = JSON.parse(atob(parts[1]));
    return data.nonce == this.getNonce();
  }

  /**
   * france connect - login
   * @param channel
   */
  login(channel: string) {
    if (this.isEnabled()) {
      this.generateUIID();
      let cb: string = encodeURIComponent(this.getLoginCallback(channel));
      const url = `${
        this.props.fcUrl
      }/api/v1/authorize?response_type=code&client_id=${
        this.props.fcClientId
      }&redirect_uri=${cb}&scope=${this.props.fcScopes}&acr_values=${
        this.props.fcAcr
      }&state=${this.getState()}&nonce=${this.getNonce()}`;
      window.location.href = url;
    }
  }

  /**
   * france connect - logout
   * @param token_hint
   * @param channel
   */
  logout(token_hint: string, channel: string) {
    if (this.isEnabled()) {
      this.generateUIID();
      let cb: string = encodeURIComponent(this.getLogoutCallback(channel));
      const url = `${
        this.props.fcUrl
      }/api/v1/logout?id_token_hint=${token_hint}&state=${this.getState()}&post_logout_redirect_uri=${cb}`;
      window.location.href = url;
    }
  }

  /**
   * france connect - get token
   * @param code
   * @param channel
   * @returns
   */
  getToken(code: string, channel: string): Observable<any> {
    if (this.isEnabled()) {
      const url = `${this.FS_URL}/api/fc/token`;
      const body = new HttpParams()
        .set("grant_type", "authorization_code")
        .set("redirect_uri", this.getLoginCallback(channel))
        .set("client_id", this.props.fcClientId)
        .set("client_secret", this.props.fcClientSecret)
        .set("code", code);

      return this.http.post<JSON>(url, body, {
        headers: { "content-type": "application/x-www-form-urlencoded" },
        observe: "response",
      });
    }
  }

  /**
   * france connect - get user info
   * @param token
   * @returns
   */
  getUserInfo(token: string) {
    if (this.isEnabled()) {
      const url = `${this.FS_URL}/api/fc/userinfo`;
      return this.http.get<JSON>(url, {
        headers: { Authorization: `Bearer ${token}` },
      });
    }
  }

  /**
   *  ---------------- PORTAIL METHODS ----------------
   */

  returnUserJson(fcuser: any, token: string, uiid: string) {
    if (uiid) {
      fcuser["portal_uiid"] = uiid;
      sessionStorage.removeItem("portal_uiid");
    }
    fcuser["fc_token"] = token;
    // '\ufeff' pour forcer encoding utf8
    const thefile = new Blob(["\ufeff", JSON.stringify(fcuser)], {
      type: "application/json",
    });
    let url = window.URL.createObjectURL(thefile);
    window.location.href = url;
  }

  returnLogoutJson(status: boolean, uiid: string) {
    let json = { disconnect: status };
    //console.log("------ returnLogoutJson, uiid:"+uiid);
    if (uiid) {
      json["portal_uiid"] = uiid;
      sessionStorage.removeItem("portal_uiid");
    }
    //console.log("------ returnLogoutJson, json:"+json);
    const thefile = new Blob([JSON.stringify(json)], {
      type: "application/json",
    });
    let url = window.URL.createObjectURL(thefile);
    window.location.href = url;
  }
}
