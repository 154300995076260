import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";

import { ADroitModule } from "../common/directives/a-droit/a-droit.module";
import { PlanComponent } from "./plan.component";

@NgModule({
  imports: [RouterModule, ADroitModule],
  exports: [PlanComponent],
  declarations: [PlanComponent],
  providers: [],
})
export class PlanModule {}
