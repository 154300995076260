import { Injectable } from "@angular/core";
import { Http } from "@angular/http";
import "rxjs/add/operator/toPromise";
import { Props } from "./models/props";

@Injectable()
export class PropertiesService {
  constructor(private http: Http) {}

  public getProps(): Promise<Props> {
    return new Promise((resolve, reject) => {
      this.http
        .get(`/api/properties`)
        .toPromise()
        .then((res) => {
          resolve(res.json());
        })
        .catch((err) => reject(err));
    });
  }

  public getValeur(cle: string): Promise<string> {
    return new Promise((resolve, reject) => {
      this.http
        .get(`/api/properties`)
        .toPromise()
        .then((res) => {
          resolve(res.json()[cle]);
        })
        .catch((err) => reject(err));
    });
  }
}
