import { Component, OnDestroy, OnInit } from "@angular/core";
import { Subscription } from "rxjs";

import { AuthentificationService } from "../authentification/authentification.service";
import { TabsConfig } from "../authentification/models/tabs-config";
import { Utilisateur } from "../authentification/models/utilisateur";
import { AlertesService } from "./alertes.service";
import { Alertes } from "./models/alertes";

@Component({
  selector: "in-alertes",
  templateUrl: "./alertes.component.html",
  styleUrls: ["./alertes.component.scss"],
})
export class AlertesComponent implements OnInit, OnDestroy {
  public isPageReadOnly: boolean = false;
  public successMessage: boolean = false;
  public failedMessage: boolean = false;
  utilisateurSubscription: Subscription;
  public utilisateur: Utilisateur = null;
  public alertes: Alertes = null;
  public nbOfMonthsForSendingInfoAlertEmail: number;
  public nbOfMonthsForSendingWarningAlertEmail: number;

  constructor(
    private authentificationService: AuthentificationService,
    private alertesService: AlertesService
  ) {}

  ngOnInit() {
    // get alertes
    this.utilisateurSubscription = this.authentificationService
      .utilisateurConnecte()
      .subscribe((utilisateur) => {
        this.utilisateur = utilisateur;
        if (this.utilisateur != null) {
          this.alertesService
            .getAlertes(this.utilisateur.identifiant)
            .subscribe((alertes) => {
              this.alertes = alertes;
            });
        }
      });

    // get conf
    const tabs: TabsConfig =
      this.authentificationService.getTabsConfigConnecte();
    if (tabs) {
      if (tabs.vehiculesTab) {
        this.isPageReadOnly = tabs.vehiculesTab.readOnly;
      }
      if (tabs.alertsTab) {
        this.nbOfMonthsForSendingInfoAlertEmail =
          tabs.alertsTab.nbOfMonthsForSendingInfoAlertEmail;
        this.nbOfMonthsForSendingWarningAlertEmail =
          tabs.alertsTab.nbOfMonthsForSendingWarningAlertEmail;
      }
    }
  }

  ngOnDestroy() {
    // This is intentional
  }

  getClass(value: boolean): String {
    if (value) {
      return "valuecheck";
    } else {
      return "valuenocheck";
    }
  }

  getAriaLabelAlerteExpiration(value: boolean, tmp: number): string {
    return value
      ? `État : actif. Action : désactiver les alertes par email ${tmp} mois avant la fin de validité de votre Carte Mobilité Inclusion.`
      : `État : inactif. Action : activer les alertes par email ${tmp} mois avant la fin de validité de votre Carte Mobilité Inclusion.`;
  }

  getAriaLabelActivDesactivVehicule(value: boolean): string {
    return value
      ? `État : actif. Action : désactiver les alertes par email si un véhicule est activé, désactivé ou supprimé.`
      : `État : inactif. Action : activer les alertes par email si un véhicule est activé, désactivé ou supprimé.`;
  }

  getTitleEtat(value: boolean): string {
    return value ? `État : actif` : `État : inactif`;
  }

  // alertes?.alertInformation
  card6months() {
    this.alertes.alertInformation = !this.alertes.alertInformation;
    this.updateData();
  }
  card6monthsClass(): String {
    if (this.alertes) {
      return this.getClass(this.alertes.alertInformation);
    } else {
      return this.getClass(false);
    }
  }

  // alertes.alertReminder
  card3months() {
    this.alertes.alertReminder = !this.alertes.alertReminder;
    this.updateData();
  }
  card3monthsClass(): String {
    if (this.alertes) {
      return this.getClass(this.alertes.alertReminder);
    } else {
      return this.getClass(false);
    }
  }

  // alertes.alertChange
  alertVehicule() {
    this.alertes.alertChange = !this.alertes.alertChange;
    this.updateData();
  }
  alertVehiculeClass(): String {
    return this.alertes
      ? this.getClass(this.alertes.alertChange)
      : this.getClass(false);
  }

  // call webservice
  updateData() {
    // securite
    if (this.isPageReadOnly) {
      return;
    }

    this.failedMessage = false;
    this.successMessage = false;

    this.alertesService
      .modifierAlertes(this.utilisateur.identifiant, this.alertes)
      .then((succes) => {
        // show info message
        this.successMessage = true;
      })
      .catch((err) => {
        // show info message
        this.failedMessage = true;
      });
  }
}
