import "rxjs/add/operator/map";
import "rxjs/add/operator/mergeMap";
import "rxjs/add/operator/toPromise";

import { Injectable } from "@angular/core";
import { Http } from "@angular/http";
import { Router } from "@angular/router";
import { BehaviorSubject } from "rxjs/BehaviorSubject";
import { Observable } from "rxjs/Observable";

import { FranceConnectService } from "./franceconnect/franceconnect.service";
import { Droit } from "./models/droit";
import { DROITS } from "./models/droits.constants";
import { INUtilisateurFC } from "./models/in-fc-utilisateur";
import { TabsConfig } from "./models/tabs-config";
import { Utilisateur } from "./models/utilisateur";
import { PremierConnexion } from "./premiere-connexion/premier-connexion";
import { PropertiesService } from "./properties.service";

@Injectable()
export class AuthentificationService {
  private listeDroits: BehaviorSubject<Droit[]> = new BehaviorSubject<Droit[]>(
    []
  );
  private droitsParDefaut: boolean = true;
  private utilisateur: BehaviorSubject<Utilisateur> =
    new BehaviorSubject<Utilisateur>(null);
  private tabs: TabsConfig = null;
  private premierConnexionState: boolean = false;

  constructor(
    private http: Http,
    private router: Router,
    private franceConnectService: FranceConnectService,
    private props: PropertiesService
  ) {}

  get estConnecte(): boolean {
    return !!sessionStorage.getItem("token");
  }

  /**
   * Initialise la connexion à partir du WebToken en session et de TabsConfig.
   * À lancer au chargement de l'application.
   * Si les droits ne sont pas déjà chargés, on effectue les requêtes nécessaires,
   * sinon on renvoie la liste des droits actuels.
   */
  initialiserDroits(): Promise<Droit[]> {
    return new Promise((resolve) => {
      if (!this.hasPayload()) {
        resolve(this.chargerDroitsParDefaut());
      }

      if (!this.droitsParDefaut) {
        resolve(this.listeDroits.value);
      }

      // On va recherche l'utilisateur actuellement connecté
      const payload: any = this.getPayload();
      this.getUtilisateur(payload.sub)
        .then((utilisateur) => {
          this.utilisateur.next(utilisateur);
          this.premierConnexionState = utilisateur.premiereConnexion;

          this.props.getValeur("zfeEnabled").then((zfeEnabled) => {
            if (!!zfeEnabled) {
              this.getTabsConfig(payload.sub)
                .then((tabs) => {
                  this.tabs = tabs;
                  resolve(this.chargerDroits());
                })
                .catch((err) => {
                  console.warn(
                    "[AuthentificationService] TabsConfig absent ou invalide"
                  );
                  this.tabs = null;
                  resolve(this.chargerDroits());
                });
            } else {
              this.tabs = null;
              resolve(this.chargerDroits());
            }
          });
        })
        .catch(() => resolve(this.chargerDroitsParDefaut()));
    });
  }

  /**
   * Methode à appeler pour la connexion d'un utilisateur.
   */
  connecter(login: string, motDePasse: string): Promise<Utilisateur> {
    return new Promise((resolve, reject) => {
      sessionStorage.removeItem("token");

      this.http
        .post("/api/authentification/authentification/connexion", {
          login: login,
          motDePasse: motDePasse,
          profil: "beneficiaire",
        })
        .subscribe(
          (res) => {
            // On stocke le token
            const token: string = res.text();
            sessionStorage.setItem("token", token);

            this.initialiserDroits().then(() =>
              resolve(this.utilisateur.value)
            );
          },
          (err) => reject(err)
        );
    });
  }

  nouveauMotDePasse(
    token: string,
    nouveauMotDePasse: string
  ): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.http
        .post("/api/authentification/authentification/nouveauMotDePasse", {
          token,
          nouveauMotDePasse,
        })
        .subscribe((res) => {
          if (res.status !== 200) {
            reject("Echec du changement de mot de passe.");
          }

          resolve(true);
        });
    });
  }

  validerTokenActif(redirectPageExpiredOnError: boolean): Promise<boolean> {
    console.log(
      "[AuthentificationService] validerTokenActif - Verification token"
    );

    return new Promise((resolve, reject) => {
      this.http
        .post("/api/authentification/authentification/validateToken", {})
        .subscribe(
          (res) => {
            if (res.status !== 200) {
              this.expirer();
              reject("Le token est invalide.");
            }

            resolve(true);
          },
          (err) => {
            console.error(
              "[AuthentificationService] validerTokenActif - Le token est invalide"
            );
            sessionStorage.removeItem("token");

            if (redirectPageExpiredOnError) {
              this.expirer();
              this.router.navigateByUrl("/authentification/expired");
              resolve(true);
            }

            resolve(false);
          }
        );
    });
  }

  validerTokenPeriodique(): Promise<boolean> {
    const token: string = sessionStorage.getItem("token");
    if (token != null) {
      console.log(
        "[AuthentificationService] validerTokenPeriodique - Token present"
      );
      return this.validerTokenActif(true);
    }

    console.log(
      "[AuthentificationService] validerTokenPeriodique - Token absent"
    );
    return Promise.resolve(true);
  }

  validateToken(token: string): Promise<boolean> {
    // On stocke le token
    sessionStorage.setItem("token", token);

    return this.validerTokenActif(false);
  }

  modifMotDePasse(
    ancienMotDePasse: string,
    nouveauMotDePasse: string,
    confirmMotDePasse: string
  ): Promise<string | boolean> {
    return new Promise((resolve, reject) => {
      this.http
        .post("/api/authentification/authentification/modifMotDePasse", {
          ancienMotDePasse,
          nouveauMotDePasse,
          confirmMotDePasse,
        })
        .subscribe(
          (res) => {
            if (res.status !== 200) {
              reject("Echec de la modification du mot de passe.");
            }

            if (res["_body"] === "INVALIDE_ANCIEN_MOT_PASSE") {
              resolve("INVALIDE_ANCIEN_MOT_PASSE");
            }

            resolve(true);
          },
          (err) => reject(err)
        );
    });
  }

  loginOublie(adresseEmail: string): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.http
        .post("/api/authentification/authentification/loginOublie", {
          adresseEmail: adresseEmail,
          profile: "beneficiaire",
        })
        .subscribe(
          (res) => {
            if (res.status !== 200) {
              reject("Echec de la requête de login oublié.");
            }

            resolve(true);
          },
          (err) => {
            console.error(
              "[AuthentificationService] loginOublie - Service authentification indisponible"
            );
            reject(err);
          }
        );
    });
  }

  motDePasseOublie(identifiant: string): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.http
        .post("/api/authentification/authentification/motDePasseOublie", {
          identifiant: identifiant,
          profile: "beneficiaire",
        })
        .subscribe(
          (res) => {
            if (res.status !== 200) {
              reject("Echec de la requête de mot de passe oublié.");
            }

            resolve(true);
          },
          (err) => {
            console.error(
              "[AuthentificationService] motDePasseOublie - Service authentification indisponible"
            );
            reject(err);
          }
        );
    });
  }

  /**
   * Méthode à appeler lors du changement d'informations de la première connexion
   */
  premiereConnexion(premierConnexion: PremierConnexion): Promise<boolean> {
    console.log("premiereConnexion ", premierConnexion);
    return new Promise((resolve, reject) => {
      this.http
        .post("/api/authentification/utilisateur", premierConnexion)
        .subscribe(
          (res) => {
            if (res.status !== 200) {
              reject("Enregistrement première connexion échoué");
            }

            this.premierConnexionState = false;
            this.chargerDroits().then(() => resolve(true));
          },
          (err) => reject(err)
        );
    });
  }

  getUtilisateur(identifiant: string): Promise<Utilisateur> {
    return new Promise((resolve, reject) => {
      this.http
        .get(`/api/authentification/utilisateur/${identifiant}`)
        .toPromise()
        .then((res) => {
          const user: Utilisateur = res.json() as Utilisateur;
          user.personnePhysique = user.identifiant.startsWith("0");
          user.personneMorale = user.identifiant.startsWith("1");
          resolve(user);
        })
        .catch((err) => reject(err));
    });
  }

  getTabsConfig(identifiant: string): Promise<TabsConfig> {
    return new Promise((resolve, reject) => {
      this.http
        .get(
          `/api/organismeBeneficiaire/beneficiaire/${identifiant}/tabConfigs`
        )
        .toPromise()
        .then((res) => {
          if (res.status !== 200) {
            reject();
          }

          const tabs: TabsConfig = res.json() as TabsConfig;
          if (!this.checkTabsConfig(tabs)) {
            reject();
          }

          resolve(tabs);
        })
        .catch(() => reject());
    });
  }

  checkTabsConfig(tabs: TabsConfig): boolean {
    if (tabs === null) {
      return false;
    }

    if (!tabs.alertsTab || !tabs.vehiculesTab) {
      return false;
    }

    return true;
  }

  getTabsConfigConnecte(): TabsConfig {
    return this.tabs;
  }

  getFirstWebConnection(identifiant: string): Promise<boolean> {
    return new Promise((resolve) => {
      this.props.getValeur("zfeEnabled").then((zfeEnabled) => {
        if (!!zfeEnabled) {
          let firstWebConnection: string =
            sessionStorage.getItem("firstWebConnection");
          if (
            this.tabs &&
            this.tabs.alertsTab &&
            this.tabs.alertsTab.visible &&
            this.tabs.vehiculesTab.visible &&
            this.tabs.alertsTab.firstWebConnection &&
            firstWebConnection != "0"
          ) {
            // pour ne pas réafficher la popup durant la session actuelle de l'utilisateur
            sessionStorage.setItem("firstWebConnection", "0");
            // appel backend
            this.ackPremiereConnexion(identifiant);
            // return
            resolve(true);
          }
          resolve(false);
        } else {
          resolve(false);
        }
      });
    });
  }

  ackPremiereConnexion(identifiant: string): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.http
        .put(
          `/api/organismeBeneficiaire/beneficiaire/${identifiant}/firstWebConnection`,
          null
        )
        .toPromise()
        .then((response) => resolve(response.status === 200))
        .catch(reject);
    });
  }

  utilisateurConnecte(): Observable<Utilisateur> {
    return this.utilisateur;
  }

  droit(droit: string): Observable<boolean> {
    const droitTab: string[] = droit.split(",");
    return this.droitsUtilisateur().map((droits) =>
      this.aDroit(droits, droitTab)
    );
  }

  droitsUtilisateur(): Observable<Droit[]> {
    return this.listeDroits;
  }

  aDroit(droits: Droit[], droit: string[]): boolean {
    const ds = droits
      .filter((d) => droit.indexOf(d.nom) > -1)
      .filter((d) => d.attribue === true);

    if (ds.length === 0) {
      return false;
    }

    return ds[0].attribue;
  }

  public hasPayload(): boolean {
    return !!sessionStorage.getItem("token");
  }

  public getPayload(): string | null {
    if (!this.hasPayload) {
      return null;
    }

    const token: string = sessionStorage.getItem("token");
    const parts: string[] = token.split(/\./g);
    return JSON.parse(atob(parts[1]));
  }

  getDroit(droit): boolean {
    const d = this.listeDroits.value.find((d) => d.nom === droit);
    return d ? d.attribue : false;
  }

  /**
   * Charge les droits à partir du token.
   */
  chargerDroits(): Promise<Droit[]> {
    // Si pas de token
    if (!this.hasPayload()) {
      return this.chargerDroitsParDefaut();
    }

    const droits: Droit[] = [];

    // droits issus du token
    Object.entries(this.getPayload())
      .filter(([key, value]) => key.startsWith("d."))
      .map(([key, value]) => [key.slice(2), value])
      .forEach(([key, value]) =>
        droits.push({ nom: key, attribue: Boolean(value) })
      );

    // premiere connexion
    droits.push({
      nom: DROITS.PREMIERECONNEXION,
      attribue: this.premierConnexionState,
    });

    // droits issus du webservice 'tabsConfig'
    if (this.checkTabsConfig(this.tabs)) {
      droits.push({
        nom: DROITS.ALERTES,
        attribue: this.tabs.alertsTab.visible,
      });
      droits.push({
        nom: DROITS.VEHICULES,
        attribue: this.tabs.vehiculesTab.visible,
      });
    }

    this.droitsParDefaut = false;
    this.listeDroits.next(droits);

    return Promise.resolve(droits);
  }

  /**
   * Charge la liste de droits par défaut, quand l'utilisateur n'est pas connecté.
   */
  chargerDroitsParDefaut(): Promise<Droit[]> {
    const droitsParDefaut: Droit[] = [
      {
        nom: DROITS.CONTACT,
        attribue: true,
      },
      {
        nom: DROITS.AIDE,
        attribue: true,
      },
      {
        nom: DROITS.FAQ,
        attribue: true,
      },
      {
        nom: DROITS.VEHICULES,
        attribue: false,
      },
      {
        nom: DROITS.ALERTES,
        attribue: false,
      },
    ];

    this.tabs = null;
    this.droitsParDefaut = true;
    this.listeDroits.next(droitsParDefaut);

    return Promise.resolve(droitsParDefaut);
  }

  connectAndRedirect(user: INUtilisateurFC) {
    sessionStorage.setItem("token", user.token);
    this.initialiserDroits().then(() => {
      if (
        this.franceConnectService.ACTIONFORMAIL_LIST.indexOf(
          user.actionForMail
        ) != -1
      ) {
        sessionStorage.setItem("actionForMail", user.actionForMail);
        this.router.navigateByUrl("fc-mail");
      } else {
        sessionStorage.removeItem("fc_mail");
        this.router.navigateByUrl("mesdonnees/mesinformations");
      }
    });
  }

  deconnecter() {
    let fctoken = sessionStorage.getItem("token_hint");
    this.expirer();
    if (fctoken) {
      this.router.navigateByUrl("/authentification");
      this.franceConnectService.logout(
        fctoken,
        this.franceConnectService.CHANNEL_PORTAL
      );
    } else {
      this.router.navigateByUrl("/authentification");
    }
  }

  expirer() {
    this.premierConnexionState = false;
    sessionStorage.clear();
    this.utilisateur.next(null);
    this.chargerDroitsParDefaut();
  }

  /**
   *  ---------------- IN-CMI FRANCE CONNECT API ----------------
   */

  dissocierFC(login: string): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.http
        .delete(
          `/api/organismeBeneficiaire/beneficiaire/${login}/franceConnectId`
        )
        .toPromise()
        .then((response) => resolve(response.status === 200))
        .catch(reject);
    });
  }

  getUtilisateurByFCId(sub: string, email: string): Promise<INUtilisateurFC> {
    let token = sessionStorage.getItem("token_hint");
    let body = { email: email, fcToken: token };
    return new Promise((resolve, reject) => {
      this.http
        .post(
          `/api/organismeBeneficiaire/beneficiaire/franceConnectId/${sub}`,
          body
        )
        .toPromise()
        .then((res) => {
          //console.log('-------- getUtilisateurByFCId, res='+JSON.stringify(res));
          const user: INUtilisateurFC = res.json() as INUtilisateurFC;
          //console.log('-------- getUtilisateurByFCId, user='+user);
          resolve(user);
        })
        .catch(reject);
    });
  }

  getUtilisateurByNumeroTitre(idTitre: string): Promise<INUtilisateurFC> {
    let token = sessionStorage.getItem("token_hint");
    let fcInfos = JSON.parse(sessionStorage.getItem("fc_infos"));
    let sub = fcInfos["sub"];
    if (idTitre.length < 15) {
      let str: string = "";
      for (let i = 0; i < 15 - idTitre.length; i++) {
        str = "0" + str;
      }
      idTitre = str + idTitre;
    }
    //console.log("----------- getUtilisateurByNumeroTitre, sub:" + sub);
    let body = {
      idTitre: idTitre,
      nom: fcInfos["preferred_username"],
      prenom: fcInfos["given_name"],
      dateNaissance: fcInfos["birthdate"],
      nomNaissance: fcInfos["family_name"],
      email: fcInfos["email"],
      fcToken: token,
    };
    //console.log("----------- getUtilisateurByNumeroTitre, body:" + JSON.stringify(body));
    return new Promise((resolve, reject) => {
      this.http
        .put(
          `/api/organismeBeneficiaire/beneficiaire/franceConnectId/${sub}`,
          body
        )
        .toPromise()
        .then((res) => {
          //console.log('-------- getUtilisateurByFCId, res='+JSON.stringify(res));
          const user: INUtilisateurFC = res.json() as INUtilisateurFC;
          sessionStorage.setItem("fc_mail", fcInfos["email"]);
          //console.log('-------- getUtilisateurByFCId, user='+user);
          resolve(user);
        })
        .catch(reject);
    });
  }

  updateMail(
    login: string,
    email: string,
    principal: boolean
  ): Promise<boolean> {
    let body;
    if (principal) {
      body = { login: login, email: email };
    } else {
      body = { login: login, emailSecondaire: email };
    }
    return new Promise((resolve, reject) => {
      this.http
        .put(`/api/organismeBeneficiaire/beneficiaire/${login}/mail`, body)
        .toPromise()
        .then((response) => resolve(response.status === 200))
        .catch(reject);
    });
  }

  getFCToken(login: string): Promise<string> {
    return new Promise((resolve, reject) => {
      this.http
        //.get(`/api/authentification/authentification/logout/france-connect/token?login=${login}`, {responseType: 'text'})
        .get(
          `/api/authentification/authentification/logout/france-connect/token?login=${login}`
        )
        .toPromise()
        .then((res) => {
          resolve(res.text());
        })
        .catch(reject);
    });
  }
}
