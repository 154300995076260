import { Component, OnDestroy, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { Subscription } from "rxjs";

import { AuthentificationService } from "../../authentification/authentification.service";
import { Utilisateur } from "../../authentification/models/utilisateur";
import { Adresse } from "../../common/models/adresse";
import { adressePostaleValidator } from "../../common/validators/adresse-postale.validator";
import { unParmisPlusieursValidator } from "../../common/validators/un-parmis-plusieurs.validator";
import { MesDonneesService } from "../../mes-donnees/mes-donnees.service";
import { Beneficiaire } from "../../mes-donnees/models/beneficiaire";
import { DemandeService } from "../demande.service";
import { STEPS } from "../enums/steps";
import { GenericStepComponent } from "../generic-step.component";
import { PanierCommandeDuplicata2ndExemplaire } from "../models/panier-commande-duplicata-2nd-exemplaire";

declare var $: any;

@Component({
  selector: "in-step-livraison",
  templateUrl: "./step-livraison.component.html",
})
export class StepLivraisonComponent
  extends GenericStepComponent
  implements OnInit, OnDestroy
{
  public utilisateur: Utilisateur = null;

  private panier: PanierCommandeDuplicata2ndExemplaire = null;
  public beneficiaire: Beneficiaire = null;
  private beneficiaireRequete: Beneficiaire = null;
  private adresseLivraisonRequete: Adresse = null;
  public adresseCorrecte: boolean = false;

  public modifierAdresseLivraisonForm: FormGroup;
  public soumis: boolean = false;
  public erreurModifAdresseLivraison: boolean = false;

  donneesSubscription: Subscription;

  constructor(
    private authentificationService: AuthentificationService,
    private mesDonneesService: MesDonneesService,
    private demandeService: DemandeService
  ) {
    super();
  }

  ngOnInit() {
    this.modifierAdresseLivraisonForm = new FormGroup({
      destinataire: new FormControl(
        "",
        Validators.compose([
          Validators.required,
          Validators.maxLength(38),
          Validators.pattern(
            "[0-9A-Za-z! \"$%&'()*+,-./:<=>?@[\\]\\\\^_{|}~ÀÁÂÃÄÅÆÇÈÉÊËÌÍÎÏÐÑÒÓÔÕÖ×ØÙÚÛÜÝÞßŠŒŸŽàáâãäåæçèéêëìíîïðñòóôõöøùúûüýþÿšœž€‚ƒ„…†‡ˆ‰‹‘'“”•–—˜™›¡¢£¤¥¦§¨©ª«¬¬®¯°±²³´µ¶•¸¹º»¼½¾¿÷]*"
          ),
        ])
      ),
      adresse1: new FormControl(
        "",
        Validators.compose([
          Validators.maxLength(38),
          adressePostaleValidator(""),
          unParmisPlusieursValidator(["adresse2", "adresse3", "adresse4"]),
        ])
      ),
      adresse2: new FormControl(
        "",
        Validators.compose([
          Validators.maxLength(38),
          adressePostaleValidator(""),
          unParmisPlusieursValidator(["adresse1", "adresse3", "adresse4"]),
        ])
      ),
      adresse3: new FormControl(
        "",
        Validators.compose([
          Validators.maxLength(38),
          adressePostaleValidator(""),
          unParmisPlusieursValidator(["adresse2", "adresse1", "adresse4"]),
        ])
      ),
      adresse4: new FormControl(
        "",
        Validators.compose([
          Validators.maxLength(38),
          adressePostaleValidator(""),
          unParmisPlusieursValidator(["adresse2", "adresse3", "adresse1"]),
        ])
      ),
      codePostal: new FormControl(
        "",
        Validators.compose([adressePostaleValidator("pays")])
      ),
      commune: new FormControl(
        "",
        Validators.compose([
          Validators.required,
          Validators.maxLength(32),
          adressePostaleValidator(""),
        ])
      ),
      pays: new FormControl(
        "",
        Validators.compose([
          Validators.maxLength(38),
          adressePostaleValidator(""),
        ])
      ),
    });
    this.modifierAdresseLivraisonForm.controls["pays"].valueChanges.subscribe(
      (data) =>
        this.modifierAdresseLivraisonForm.controls[
          "codePostal"
        ].updateValueAndValidity()
    );
  }

  public loadStep() {
    this.soumis = false;
    this.adresseCorrecte = undefined;

    this.beneficiaire = new Beneficiaire();
    this.panier = this.demandeService.getPanier();

    this.donneesSubscription = this.authentificationService
      .utilisateurConnecte()
      .subscribe((utilisateur) => {
        this.utilisateur = utilisateur;
        if (this.utilisateur != null) {
          this.mesDonneesService
            .informationsBeneficiaire(this.utilisateur.identifiant)
            .then((beneficiaire) => {
              this.beneficiaire = beneficiaire;
              if (this.beneficiaire != null) {
                console.log(
                  "[StepLivraisonComponent] beneficiaire",
                  this.beneficiaire
                );
                this.initSoumissionForm();
              }
            });
        }
      });
  }

  private initSoumissionForm() {
    this.adresseLivraisonRequete = Object.assign(
      {},
      this.beneficiaire.adresseLivraison
    );
    this.beneficiaireRequete = Object.assign({}, this.beneficiaire);
    this.erreurModifAdresseLivraison = false;

    this.modifierAdresseLivraisonForm = new FormGroup({
      destinataire: new FormControl(
        this.beneficiaire.adresseLivraison.destinataire,
        Validators.compose([
          Validators.required,
          Validators.maxLength(38),
          Validators.pattern(
            "[0-9A-Za-z! \"$%&'()*+,-./:<=>?@[\\]\\\\^_{|}~ÀÁÂÃÄÅÆÇÈÉÊËÌÍÎÏÐÑÒÓÔÕÖ×ØÙÚÛÜÝÞßŠŒŸŽàáâãäåæçèéêëìíîïðñòóôõöøùúûüýþÿšœž€‚ƒ„…†‡ˆ‰‹‘'“”•–—˜™›¡¢£¤¥¦§¨©ª«¬¬®¯°±²³´µ¶•¸¹º»¼½¾¿÷]*"
          ),
        ])
      ),
      adresse1: new FormControl(
        this.beneficiaire.adresseLivraison.adresse1,
        Validators.compose([
          Validators.maxLength(38),
          adressePostaleValidator(""),
          unParmisPlusieursValidator(["adresse2", "adresse3", "adresse4"]),
        ])
      ),
      adresse2: new FormControl(
        this.beneficiaire.adresseLivraison.adresse2,
        Validators.compose([
          Validators.maxLength(38),
          adressePostaleValidator(""),
          unParmisPlusieursValidator(["adresse1", "adresse3", "adresse4"]),
        ])
      ),
      adresse3: new FormControl(
        this.beneficiaire.adresseLivraison.adresse3,
        Validators.compose([
          Validators.maxLength(38),
          adressePostaleValidator(""),
          unParmisPlusieursValidator(["adresse2", "adresse1", "adresse4"]),
        ])
      ),
      adresse4: new FormControl(
        this.beneficiaire.adresseLivraison.adresse4,
        Validators.compose([
          Validators.maxLength(38),
          adressePostaleValidator(""),
          unParmisPlusieursValidator(["adresse2", "adresse3", "adresse1"]),
        ])
      ),
      codePostal: new FormControl(
        this.beneficiaire.adresseLivraison.codePostal,
        Validators.compose([adressePostaleValidator("pays")])
      ),
      commune: new FormControl(
        this.beneficiaire.adresseLivraison.commune,
        Validators.compose([
          Validators.required,
          Validators.maxLength(32),
          adressePostaleValidator(""),
        ])
      ),
      pays: new FormControl(
        this.beneficiaire.adresseLivraison.pays,
        Validators.compose([
          Validators.maxLength(38),
          adressePostaleValidator(""),
        ])
      ),
    });
    this.modifierAdresseLivraisonForm.controls["pays"].valueChanges.subscribe(
      (data) =>
        this.modifierAdresseLivraisonForm.controls[
          "codePostal"
        ].updateValueAndValidity()
    );
    this.modifierAdresseLivraisonForm.markAsPristine();
  }

  public modifierAdresseLivraison() {
    if (!this.adresseCorrecte) {
      this.initSoumissionForm();
    }
  }

  goToChoixDemande() {
    this.onGoToStep.emit(STEPS.CHOIXDEMANDE);
  }

  goToPaiement() {
    this.soumis = true;
    if (this.adresseCorrecte !== undefined) {
      if (this.adresseCorrecte) {
        this.panier.adresseLivraison = this.adresseLivraisonRequete;
        this.demandeService.setPanier(this.panier);
        this.onGoToStep.emit(STEPS.PAIEMENT);
      } else {
        if (this.modifierAdresseLivraisonForm.valid) {
          this.adresseLivraisonRequete.destinataire =
            this.modifierAdresseLivraisonForm.value.destinataire;
          this.adresseLivraisonRequete.adresse1 =
            this.modifierAdresseLivraisonForm.value.adresse1;
          this.adresseLivraisonRequete.adresse2 =
            this.modifierAdresseLivraisonForm.value.adresse2;
          this.adresseLivraisonRequete.adresse3 =
            this.modifierAdresseLivraisonForm.value.adresse3;
          this.adresseLivraisonRequete.adresse4 =
            this.modifierAdresseLivraisonForm.value.adresse4;
          this.adresseLivraisonRequete.codePostal =
            this.modifierAdresseLivraisonForm.value.codePostal;
          this.adresseLivraisonRequete.commune =
            this.modifierAdresseLivraisonForm.value.commune;
          this.adresseLivraisonRequete.pays =
            this.modifierAdresseLivraisonForm.value.pays;
          this.panier.adresseLivraison = this.adresseLivraisonRequete;
          this.demandeService.setPanier(this.panier);

          this.beneficiaireRequete.adresseLivraison.destinataire =
            this.modifierAdresseLivraisonForm.value.destinataire;
          this.beneficiaireRequete.adresseLivraison.adresse1 =
            this.modifierAdresseLivraisonForm.value.adresse1;
          this.beneficiaireRequete.adresseLivraison.adresse2 =
            this.modifierAdresseLivraisonForm.value.adresse2;
          this.beneficiaireRequete.adresseLivraison.adresse3 =
            this.modifierAdresseLivraisonForm.value.adresse3;
          this.beneficiaireRequete.adresseLivraison.adresse4 =
            this.modifierAdresseLivraisonForm.value.adresse4;
          this.beneficiaireRequete.adresseLivraison.codePostal =
            this.modifierAdresseLivraisonForm.value.codePostal;
          this.beneficiaireRequete.adresseLivraison.commune =
            this.modifierAdresseLivraisonForm.value.commune;
          this.beneficiaireRequete.adresseLivraison.pays =
            this.modifierAdresseLivraisonForm.value.pays;

          const that = this;
          this.mesDonneesService
            .modifyBeneficiaire(
              this.beneficiaireRequete,
              this.utilisateur.identifiant
            )
            .then((succes) => {
              that.showModalAdresseLivraisonModifiee();
            })
            .catch((err) => {
              that.erreurModifAdresseLivraison = true;
              that.atteindreMessages("#adresses-livraison-modifiables");
            });
        }
      }
    }
  }

  showModalAdresseLivraisonModifiee() {
    const composant: StepLivraisonComponent = this;
    $("#popinAdresseLivraisonModifiee").dialog({
      resizable: false,
      height: "auto",
      width: 400,
      modal: true,
      buttons: {
        Ok: function () {
          $(this).dialog("close");

          $("#adresses-livraison-affichees").removeClass("undisplayable");
          $("#adresses-livraison-modifiables").addClass("undisplayable");

          composant.onGoToStep.emit(STEPS.PAIEMENT);
        },
      },
    });
  }

  atteindreMessages(ancre: string) {
    const element = document.querySelector(ancre);
    element.scrollIntoView(true);
  }

  ngOnDestroy() {
    if (this.donneesSubscription) this.donneesSubscription.unsubscribe();
  }
}
