import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";

import { HautDePageComponent } from "./haut-de-page.component";

@NgModule({
  imports: [CommonModule],
  exports: [HautDePageComponent],
  declarations: [HautDePageComponent],
  providers: [],
})
export class HautDePageModule {}
