import { Attribute, Directive, forwardRef } from "@angular/core";
import {
  AbstractControl,
  NG_VALIDATORS,
  Validator,
  ValidatorFn,
} from "@angular/forms";

/*
 * Méthode de validation.
 */
export function unParmisPlusieursValidator(champs: string[]): ValidatorFn {
  return function (c: AbstractControl): { [key: string]: boolean } {
    const v = c.value;
    let res = true;

    if (!v || v === "") {
      const iterator = champs.values();
      let tmpChamps = iterator.next();
      while (!tmpChamps.done) {
        if (
          c.root.get(tmpChamps.value) &&
          c.root.get(tmpChamps.value).value &&
          c.root.get(tmpChamps.value).value !== ""
        ) {
          res = false;
        }
        tmpChamps = iterator.next();
      }
    } else {
      res = false;
    }

    if (!c["visited"]) {
      c["visited"] = true;

      let iterator = champs.values();
      let tmpChamps = iterator.next();
      while (!tmpChamps.done) {
        const tmpControl = c.root.get(tmpChamps.value);

        if (tmpControl) {
          tmpControl["visited"] = true;
          tmpControl.updateValueAndValidity();
        }
        tmpChamps = iterator.next();
      }

      iterator = champs.values();
      tmpChamps = iterator.next();

      c["visited"] = false;

      while (!tmpChamps.done) {
        const tmpControl = c.root.get(tmpChamps.value);
        if (tmpControl) {
          tmpControl["visited"] = false;
        }
        tmpChamps = iterator.next();
      }
    }

    if (res) {
      return {
        validateUnParmisPlusieurs: false,
      };
    }

    return null;
  };
}
