import {
  AfterViewChecked,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { Subscription } from "rxjs/Subscription";

import { AuthentificationService } from "../../authentification/authentification.service";
import { Utilisateur } from "../../authentification/models/utilisateur";
import { adressePostaleValidator } from "../../common/validators/adresse-postale.validator";
import { coordonneesTelephoniquesValidator } from "../../common/validators/coordonnees-telephoniques.validator";
import { equalsValidator } from "../../common/validators/equals.validator";
import { notEqualsValidator } from "../../common/validators/not-equals.validator";
import { passwordValidator } from "../../common/validators/password.validator";
import { unParmisPlusieursValidator } from "../../common/validators/un-parmis-plusieurs.validator";
import { MesDonneesService } from "../mes-donnees.service";
import { Beneficiaire } from "../models/beneficiaire";

declare var $: any;

@Component({
  selector: "in-mes-informations",
  templateUrl: "./mes-informations.component.html",
  styleUrls: ["./mes-informations.component.scss"],
})
export class MesInformationsComponent
  implements OnInit, AfterViewChecked, OnDestroy
{
  @ViewChild("detailbeneficiaire") view;
  public beneficiaire: Beneficiaire;
  public beneficiaireRequete: Beneficiaire = null;
  public utilisateur: Utilisateur = null;

  public modifierAdresseEmailForm: FormGroup;
  public modifierMotDePasseForm: FormGroup;
  public modifierAdressePostaleForm: FormGroup;

  public soumisAdresseEmail: boolean = false;
  public succesAdresseEmail: boolean = true;
  public emailExistant: boolean = false;
  public adresseMailInvalide: boolean = false;
  public soumisMotDePasse: boolean = false;
  public succesMotDePasse: boolean = true;
  public soumisAdressePostale: boolean = false;
  public succesAdressePostale: boolean = true;
  public ancienIncorrect: boolean = false;
  public alignBloc: boolean = true;
  public showPassword = { oldPass: false, newPass: false, newPassBis: false };
  public hasIdFC: boolean = false;

  public modifierAdresseEmailSecondaireForm: FormGroup;
  public soumisAdresseEmailSecondaire: boolean = false;
  public succesAdresseEmailSecondaire: boolean = true;
  public adresseMailSecondaireInvalide: boolean = false;

  public modifierCoordonneesTelephoniquesForm: FormGroup;
  public soumisCoordonneesTelephoniques: boolean = false;
  public succesCoordonneesTelephoniques: boolean = true;

  utilisateurSubscription: Subscription;

  //Gestion de l'affichage des blocs
  displayMonAdresseEmailAffichee = true;
  displayMonAdresseEmailModifiable = false;
  displayMonAdresseEmailSecondaireAffichee = true;
  displayMonAdresseEmailSecondaireModifiable = false;
  displayMesCoordonneesTelephoniquesAffichees = true;
  displayMesCoordonneesTelephoniquesModifiables = false;
  displayMonMotDePasseAffiche = true;
  displayMonMotDePasseModifiable = false;
  displayAdressesPostaleAffichees = true;
  displayAdressesPostaleModifiables = false;

  constructor(
    private authentificationService: AuthentificationService,
    private mesDonneesService: MesDonneesService
  ) {}

  showModalFirstConnection() {
    $("#firstconnection").dialog({
      resizable: false,
      height: "auto",
      width: 600,
      modal: true,
    });
  }

  closeModalFirstConnection() {
    $("#firstconnection").dialog("destroy");
  }

  showModalDissocierFC() {
    $("#popinDissocierFC").dialog({
      resizable: false,
      height: "auto",
      width: 600,
      modal: true,
    });
  }

  closeModalDissocierFC() {
    $("#popinDissocierFC").dialog("destroy");
  }

  initDisplayBloc() {
    this.displayMonAdresseEmailAffichee = true;
    this.displayMonAdresseEmailModifiable = false;
    this.displayMonAdresseEmailSecondaireAffichee = true;
    this.displayMonAdresseEmailSecondaireModifiable = false;
    this.displayMesCoordonneesTelephoniquesAffichees = true;
    this.displayMesCoordonneesTelephoniquesModifiables = false;
    this.displayMonMotDePasseAffiche = true;
    this.displayMonMotDePasseModifiable = false;
    this.displayAdressesPostaleAffichees = true;
    this.displayAdressesPostaleModifiables = false;
  }

  public ngOnInit() {
    this.beneficiaire = new Beneficiaire();
    this.initDisplayBloc();

    this.modifierAdresseEmailForm = new FormGroup({
      emailSecondaire: new FormControl(this.beneficiaire.emailSecondaire),
      email: new FormControl(
        "",
        Validators.compose([
          Validators.required,
          Validators.maxLength(49),
          Validators.email,
          notEqualsValidator("emailSecondaire"),
        ])
      ),
      emailBis: new FormControl(
        "",
        Validators.compose([Validators.required, equalsValidator("email")])
      ),
    });
    this.modifierAdresseEmailForm.controls["email"].valueChanges.subscribe(
      (data) =>
        this.modifierAdresseEmailForm.controls[
          "emailBis"
        ].updateValueAndValidity()
    );

    this.modifierAdresseEmailSecondaireForm = new FormGroup({
      email: new FormControl(this.beneficiaire.email),
      emailSecondaire: new FormControl(
        "",
        Validators.compose([
          Validators.required,
          Validators.maxLength(49),
          Validators.email,
          notEqualsValidator("email"),
        ])
      ),
      emailSecondaireBis: new FormControl(
        "",
        Validators.compose([
          Validators.required,
          equalsValidator("emailSecondaire"),
        ])
      ),
    });
    this.modifierAdresseEmailSecondaireForm.controls[
      "emailSecondaire"
    ].valueChanges.subscribe((data) =>
      this.modifierAdresseEmailSecondaireForm.controls[
        "emailSecondaireBis"
      ].updateValueAndValidity()
    );

    this.modifierMotDePasseForm = new FormGroup({
      oldPass: new FormControl(
        "",
        Validators.compose([Validators.required, Validators.minLength(1)])
      ),
      newPass: new FormControl(
        "",
        Validators.compose([
          Validators.required,
          Validators.minLength(12),
          passwordValidator(),
        ])
      ),
      newPassBis: new FormControl(
        "",
        Validators.compose([Validators.required, equalsValidator("newPass")])
      ),
    });
    this.modifierMotDePasseForm.controls["oldPass"].valueChanges.subscribe(
      (data) =>
        this.modifierMotDePasseForm.controls["newPass"].updateValueAndValidity()
    );
    this.modifierMotDePasseForm.controls["newPass"].valueChanges.subscribe(
      (data) =>
        this.modifierMotDePasseForm.controls[
          "newPassBis"
        ].updateValueAndValidity()
    );
    this.showPassword = { oldPass: false, newPass: false, newPassBis: false };

    this.modifierAdressePostaleForm = new FormGroup({
      destinataire: new FormControl(
        "",
        Validators.compose([
          Validators.required,
          Validators.maxLength(38),
          Validators.pattern(
            "[0-9A-Za-z! \"$%&'()*+,-./:<=>?@[\\]\\\\^_{|}~ÀÁÂÃÄÅÆÇÈÉÊËÌÍÎÏÐÑÒÓÔÕÖ×ØÙÚÛÜÝÞßŠŒŸŽàáâãäåæçèéêëìíîïðñòóôõöøùúûüýþÿšœž€‚ƒ„…†‡ˆ‰‹‘'“”•–—˜™›¡¢£¤¥¦§¨©ª«¬¬®¯°±²³´µ¶•¸¹º»¼½¾¿÷]*"
          ),
        ])
      ),
      adresse1: new FormControl(
        "",
        Validators.compose([
          Validators.maxLength(38),
          adressePostaleValidator(""),
          unParmisPlusieursValidator(["adresse2", "adresse3", "adresse4"]),
        ])
      ),
      adresse2: new FormControl(
        "",
        Validators.compose([
          Validators.maxLength(38),
          adressePostaleValidator(""),
          unParmisPlusieursValidator(["adresse1", "adresse3", "adresse4"]),
        ])
      ),
      adresse3: new FormControl(
        "",
        Validators.compose([
          Validators.maxLength(38),
          adressePostaleValidator(""),
          unParmisPlusieursValidator(["adresse2", "adresse1", "adresse4"]),
        ])
      ),
      adresse4: new FormControl(
        "",
        Validators.compose([
          Validators.maxLength(38),
          adressePostaleValidator(""),
          unParmisPlusieursValidator(["adresse2", "adresse3", "adresse1"]),
        ])
      ),
      codePostal: new FormControl(
        "",
        Validators.compose([adressePostaleValidator("pays")])
      ),
      commune: new FormControl(
        "",
        Validators.compose([
          Validators.required,
          Validators.maxLength(32),
          adressePostaleValidator(""),
        ])
      ),
      pays: new FormControl(
        "",
        Validators.compose([
          Validators.maxLength(38),
          adressePostaleValidator(""),
        ])
      ),
      adresseIdentique: new FormControl("", null),
    });
    this.modifierAdressePostaleForm.controls["pays"].valueChanges.subscribe(
      (data) =>
        this.modifierAdressePostaleForm.controls[
          "codePostal"
        ].updateValueAndValidity()
    );

    this.modifierCoordonneesTelephoniquesForm = new FormGroup({
      numeroTelephoneFixe: new FormControl(
        "",
        Validators.compose([
          Validators.required,
          coordonneesTelephoniquesValidator(),
        ])
      ),
      numeroTelephoneMobile: new FormControl(
        "",
        Validators.compose([
          Validators.required,
          coordonneesTelephoniquesValidator(),
        ])
      ),
    });

    this.utilisateurSubscription = this.authentificationService
      .utilisateurConnecte()
      .subscribe((utilisateur) => {
        this.utilisateur = utilisateur;
        console.log("[MesInformations] utilisateur connecté", this.utilisateur);
        if (this.utilisateur != null) {
          //get infos benef
          this.mesDonneesService
            .informationsBeneficiaire(this.utilisateur.identifiant)
            .then((beneficiaire) => {
              this.beneficiaire = beneficiaire;
              if (this.beneficiaire != null) {
                console.log(
                  "[MesInformations] beneficiaire",
                  this.beneficiaire
                );
                if (this.beneficiaire.franceConnectId) {
                  this.hasIdFC = true;
                }
                this.initSoumissionForm();
              }
            });

          // is first connexion
          this.authentificationService
            .getFirstWebConnection(this.utilisateur.identifiant)
            .then((value) => {
              if (value) {
                this.showModalFirstConnection();
              }
            });
        }
      });
  }

  ngAfterViewChecked() {
    const isAlign = this.alignBloc;
    let isChecked = true;
    const hauteurBoutonModifier =
      this.beneficiaire && this.beneficiaire.physique ? 32 : 0;
    let cssActive = false;
    if ("rgba(0, 0, 0, 0)" !== $("body").css("background-color")) {
      cssActive = true;
    }

    if (this.beneficiaire) {
      isChecked = this.beneficiaire.adresseIdentique;
      $(this.view.nativeElement)
        .find(".pulldown")
        .each(function () {
          const $this = $(this);
          if (
            cssActive &&
            isAlign &&
            !isChecked &&
            window.matchMedia("(min-width: 1260px)").matches
          )
            $this.css(
              "margin-top",
              hauteurBoutonModifier +
                $this.parent().children(":first").height() -
                $this.height()
            );
          else $this.css("margin-top", 0);
        });
    }
  }

  private initSoumissionForm() {
    this.soumisAdresseEmail = false;
    this.soumisAdresseEmailSecondaire = false;
    this.soumisMotDePasse = false;
    this.beneficiaireRequete = Object.assign({}, this.beneficiaire);

    this.modifierAdresseEmailForm = new FormGroup({
      emailSecondaire: new FormControl(this.beneficiaire.emailSecondaire),
      email: new FormControl(
        "",
        Validators.compose([
          Validators.required,
          Validators.maxLength(49),
          Validators.email,
          notEqualsValidator("emailSecondaire"),
        ])
      ),
      emailBis: new FormControl(
        "",
        Validators.compose([Validators.required, equalsValidator("email")])
      ),
    });
    this.modifierAdresseEmailForm.markAsPristine();
    this.modifierAdresseEmailForm.controls["email"].valueChanges.subscribe(
      (data) =>
        this.modifierAdresseEmailForm.controls[
          "emailBis"
        ].updateValueAndValidity()
    );

    this.modifierAdresseEmailSecondaireForm = new FormGroup({
      email: new FormControl(this.beneficiaire.email),
      emailSecondaire: new FormControl(
        "",
        Validators.compose([
          Validators.required,
          Validators.maxLength(49),
          Validators.email,
          notEqualsValidator("email"),
        ])
      ),
      emailSecondaireBis: new FormControl(
        "",
        Validators.compose([
          Validators.required,
          equalsValidator("emailSecondaire"),
        ])
      ),
    });
    this.modifierAdresseEmailSecondaireForm.markAsPristine();
    this.modifierAdresseEmailSecondaireForm.controls[
      "emailSecondaire"
    ].valueChanges.subscribe((data) =>
      this.modifierAdresseEmailSecondaireForm.controls[
        "emailSecondaireBis"
      ].updateValueAndValidity()
    );

    this.modifierMotDePasseForm = new FormGroup({
      oldPass: new FormControl(
        "",
        Validators.compose([Validators.required, Validators.minLength(1)])
      ),
      newPass: new FormControl(
        "",
        Validators.compose([
          Validators.required,
          Validators.minLength(12),
          passwordValidator(),
        ])
      ),
      newPassBis: new FormControl(
        "",
        Validators.compose([Validators.required, equalsValidator("newPass")])
      ),
    });
    this.modifierMotDePasseForm.markAsPristine();
    this.modifierMotDePasseForm.controls["oldPass"].valueChanges.subscribe(
      (data) =>
        this.modifierMotDePasseForm.controls["newPass"].updateValueAndValidity()
    );
    this.modifierMotDePasseForm.controls["newPass"].valueChanges.subscribe(
      (data) =>
        this.modifierMotDePasseForm.controls[
          "newPassBis"
        ].updateValueAndValidity()
    );
    this.showPassword = { oldPass: false, newPass: false, newPassBis: false };

    this.modifierAdressePostaleForm = new FormGroup({
      destinataire: new FormControl(
        this.beneficiaire.adresseLivraison.destinataire,
        Validators.compose([
          Validators.required,
          Validators.maxLength(38),
          Validators.pattern(
            "[0-9A-Za-z! \"$%&'()*+,-./:<=>?@[\\]\\\\^_{|}~ÀÁÂÃÄÅÆÇÈÉÊËÌÍÎÏÐÑÒÓÔÕÖ×ØÙÚÛÜÝÞßŠŒŸŽàáâãäåæçèéêëìíîïðñòóôõöøùúûüýþÿšœž€‚ƒ„…†‡ˆ‰‹‘'“”•–—˜™›¡¢£¤¥¦§¨©ª«¬¬®¯°±²³´µ¶•¸¹º»¼½¾¿÷]*"
          ),
        ])
      ),
      adresse1: new FormControl(
        this.beneficiaire.adresseLivraison.adresse1,
        Validators.compose([
          Validators.maxLength(38),
          adressePostaleValidator(""),
          unParmisPlusieursValidator(["adresse2", "adresse3", "adresse4"]),
        ])
      ),
      adresse2: new FormControl(
        this.beneficiaire.adresseLivraison.adresse2,
        Validators.compose([
          Validators.maxLength(38),
          adressePostaleValidator(""),
          unParmisPlusieursValidator(["adresse1", "adresse3", "adresse4"]),
        ])
      ),
      adresse3: new FormControl(
        this.beneficiaire.adresseLivraison.adresse3,
        Validators.compose([
          Validators.maxLength(38),
          adressePostaleValidator(""),
          unParmisPlusieursValidator(["adresse2", "adresse1", "adresse4"]),
        ])
      ),
      adresse4: new FormControl(
        this.beneficiaire.adresseLivraison.adresse4,
        Validators.compose([
          Validators.maxLength(38),
          adressePostaleValidator(""),
          unParmisPlusieursValidator(["adresse2", "adresse3", "adresse1"]),
        ])
      ),
      codePostal: new FormControl(
        this.beneficiaire.adresseLivraison.codePostal,
        Validators.compose([adressePostaleValidator("pays")])
      ),
      commune: new FormControl(
        this.beneficiaire.adresseLivraison.commune,
        Validators.compose([
          Validators.required,
          Validators.maxLength(32),
          adressePostaleValidator(""),
        ])
      ),
      pays: new FormControl(
        this.beneficiaire.adresseLivraison.pays,
        Validators.compose([
          Validators.maxLength(38),
          adressePostaleValidator(""),
        ])
      ),
      adresseIdentique: new FormControl(
        this.beneficiaire.adresseIdentique,
        null
      ),
    });
    this.modifierAdressePostaleForm.controls["pays"].valueChanges.subscribe(
      (data) =>
        this.modifierAdressePostaleForm.controls[
          "codePostal"
        ].updateValueAndValidity()
    );
    this.modifierAdressePostaleForm.markAsPristine();

    this.modifierCoordonneesTelephoniquesForm = new FormGroup({
      numeroTelephoneFixe: new FormControl(
        this.beneficiaire.numeroTelephoneFixe,
        Validators.compose([
          Validators.required,
          coordonneesTelephoniquesValidator(),
        ])
      ),
      numeroTelephoneMobile: new FormControl(
        this.beneficiaire.numeroTelephoneMobile,
        Validators.compose([
          Validators.required,
          coordonneesTelephoniquesValidator(),
        ])
      ),
    });
    this.modifierCoordonneesTelephoniquesForm.markAsPristine();
  }

  public modifierAdresseEmail() {
    this.displayMonAdresseEmailAffichee = false;
    this.displayMonAdresseEmailModifiable = true;
    this.initSoumissionForm();
  }

  public modifierAdresseEmailSecondaire() {
    this.displayMonAdresseEmailSecondaireAffichee = false;
    this.displayMonAdresseEmailSecondaireModifiable = true;
    this.initSoumissionForm();
  }

  public showModalSupprimerAdresseEmailSecondaire(event: any) {
    if (event) event.preventDefault();

    $("#popinSupprimerAdresseEmailSecondaire").dialog({
      resizable: false,
      height: "auto",
      width: 550,
      modal: true,
    });
  }

  public closeModalSupprimerAdresseEmailSecondaire() {
    $("#popinSupprimerAdresseEmailSecondaire").dialog("destroy");
  }

  public supprimerAdresseEmailSecondaire() {
    this.beneficiaireRequete.emailSecondaire = null;
    this.enregistrerDonneesBeneficiaire("AdresseEmailSecondaire");

    $("#popinSupprimerAdresseEmailSecondaire").dialog("destroy");
  }

  public modifierCoordonneesTelephoniques() {
    this.displayMesCoordonneesTelephoniquesAffichees = false;
    this.displayMesCoordonneesTelephoniquesModifiables = true;
    this.initSoumissionForm();
  }

  onSubmitAdresseEmailForm(event) {
    event.stopPropagation();

    if (this.modifierAdresseEmailForm.valid) {
      let v: string = this.modifierAdresseEmailForm.value.email;
      this.beneficiaireRequete.email = v.trim();

      this.enregistrerDonneesBeneficiaire("AdresseEmail");

      this.displayMonAdresseEmailAffichee = true;
      this.displayMonAdresseEmailModifiable = false;
    } else {
      // Pour afficher les messages d'erreur en cas de formulaire vide
      for (const control in this.modifierAdresseEmailForm.controls) {
        if (typeof control === "string") {
          this.modifierAdresseEmailForm.controls[control].markAsTouched();
        }
      }
    }

    return false;
  }

  onSubmitAdresseEmailSecondaireForm(event) {
    event.stopPropagation();

    if (this.modifierAdresseEmailSecondaireForm.valid) {
      let v: string =
        this.modifierAdresseEmailSecondaireForm.value.emailSecondaire;
      this.beneficiaireRequete.emailSecondaire = v.trim();
      this.enregistrerDonneesBeneficiaire("AdresseEmailSecondaire");

      this.displayMonAdresseEmailSecondaireAffichee = true;
      this.displayMonAdresseEmailSecondaireModifiable = false;
    } else {
      // Pour afficher les messages d'erreur en cas de formulaire vide
      for (const control in this.modifierAdresseEmailSecondaireForm.controls) {
        if (typeof control === "string") {
          this.modifierAdresseEmailSecondaireForm.controls[
            control
          ].markAsTouched();
        }
      }
    }

    return false;
  }

  public modifierMotDePasse() {
    this.alignBloc = false;
    this.displayMonMotDePasseAffiche = false;
    this.displayMonMotDePasseModifiable = true;
    this.initSoumissionForm();
  }

  onSubmitMotDePasse(event) {
    event.stopPropagation();

    if (this.modifierMotDePasseForm.valid) {
      this.enregistrerMotDePasse();

      this.displayMonMotDePasseAffiche = true;
      this.displayMonMotDePasseModifiable = false;
    } else {
      // Pour afficher les messages d'erreur en cas de formulaire vide
      for (const control in this.modifierMotDePasseForm.controls) {
        if (typeof control === "string") {
          this.modifierMotDePasseForm.controls[control].markAsTouched();
        }
      }
    }

    return false;
  }

  public modifierAdressePostale() {
    this.alignBloc = false;
    this.displayAdressesPostaleAffichees = false;
    this.displayAdressesPostaleModifiables = true;
    this.initSoumissionForm();
  }

  onSubmitAdressePostaleForm(event) {
    event.stopPropagation();

    if (
      this.modifierAdressePostaleForm.valid ||
      this.modifierAdressePostaleForm.value.adresseIdentique
    ) {
      this.beneficiaireRequete.adresseLivraison.destinataire =
        this.modifierAdressePostaleForm.value.destinataire;
      this.beneficiaireRequete.adresseLivraison.adresse1 =
        this.modifierAdressePostaleForm.value.adresse1;
      this.beneficiaireRequete.adresseLivraison.adresse2 =
        this.modifierAdressePostaleForm.value.adresse2;
      this.beneficiaireRequete.adresseLivraison.adresse3 =
        this.modifierAdressePostaleForm.value.adresse3;
      this.beneficiaireRequete.adresseLivraison.adresse4 =
        this.modifierAdressePostaleForm.value.adresse4;
      this.beneficiaireRequete.adresseLivraison.codePostal =
        this.modifierAdressePostaleForm.value.codePostal;
      this.beneficiaireRequete.adresseLivraison.commune =
        this.modifierAdressePostaleForm.value.commune;
      this.beneficiaireRequete.adresseLivraison.pays =
        this.modifierAdressePostaleForm.value.pays;
      this.beneficiaireRequete.adresseIdentique =
        this.modifierAdressePostaleForm.value.adresseIdentique;
      if (this.beneficiaireRequete.adresseIdentique) {
        this.beneficiaireRequete.adresseLivraison.destinataire =
          this.beneficiaire.adresse.destinataire;
        this.beneficiaireRequete.adresseLivraison.adresse1 =
          this.beneficiaire.adresse.adresse1;
        this.beneficiaireRequete.adresseLivraison.adresse2 =
          this.beneficiaire.adresse.adresse2;
        this.beneficiaireRequete.adresseLivraison.adresse3 =
          this.beneficiaire.adresse.adresse3;
        this.beneficiaireRequete.adresseLivraison.adresse4 =
          this.beneficiaire.adresse.adresse4;
        this.beneficiaireRequete.adresseLivraison.codePostal =
          this.beneficiaire.adresse.codePostal;
        this.beneficiaireRequete.adresseLivraison.commune =
          this.beneficiaire.adresse.commune;
        this.beneficiaireRequete.adresseLivraison.pays =
          this.beneficiaire.adresse.pays;
      }

      this.enregistrerDonneesBeneficiaire("AdressePostale");

      this.displayAdressesPostaleAffichees = true;
      this.displayAdressesPostaleModifiables = false;
    } else {
      // Pour afficher les messages d'erreur en cas de formulaire vide
      for (const control in this.modifierAdressePostaleForm.controls) {
        if (typeof control === "string") {
          this.modifierAdressePostaleForm.controls[control].markAsTouched();
        }
      }
    }

    return false;
  }

  onSubmitCoordonneesTelephoniquesForm(event) {
    event.stopPropagation();

    if (this.modifierCoordonneesTelephoniquesForm.valid) {
      this.beneficiaireRequete.numeroTelephoneFixe =
        this.modifierCoordonneesTelephoniquesForm.value.numeroTelephoneFixe;
      this.beneficiaireRequete.numeroTelephoneMobile =
        this.modifierCoordonneesTelephoniquesForm.value.numeroTelephoneMobile;

      this.enregistrerDonneesBeneficiaire("CoordonneesTelephoniques");

      this.displayMesCoordonneesTelephoniquesAffichees = true;
      this.displayMesCoordonneesTelephoniquesModifiables = false;
    } else {
      // Pour afficher les messages d'erreur en cas de formulaire vide
      for (const control in this.modifierCoordonneesTelephoniquesForm
        .controls) {
        if (typeof control === "string") {
          this.modifierCoordonneesTelephoniquesForm.controls[
            control
          ].markAsTouched();
        }
      }
    }

    return false;
  }

  atteindreMessages(ancre: string) {
    const element = document.querySelector(ancre);
    element.scrollIntoView(true);
  }

  enregistrerDonneesBeneficiaire(nomFormulaire: string) {
    this.alignBloc = true;
    this.adresseMailInvalide = false;
    this.adresseMailSecondaireInvalide = false;

    // FIXME: ajouter l'identifiant dans l'objet BeneficiaireDTO
    this.mesDonneesService
      .modifyBeneficiaire(
        this.beneficiaireRequete,
        this.utilisateur.identifiant
      )
      .then((succes) => {
        if (nomFormulaire === "AdresseEmail") {
          this.soumisAdresseEmail = true;
          this.succesAdresseEmail = succes;
        } else if (nomFormulaire === "AdresseEmailSecondaire") {
          this.soumisAdresseEmailSecondaire = true;
          this.succesAdresseEmailSecondaire = succes;
        } else if (nomFormulaire === "CoordonneesTelephoniques") {
          this.soumisCoordonneesTelephoniques = true;
          this.succesCoordonneesTelephoniques = succes;
        } else {
          this.soumisAdressePostale = true;
          this.succesAdressePostale = succes;
        }
        this.atteindreMessages("#messages" + nomFormulaire);
        if (succes) {
          this.beneficiaire = this.beneficiaireRequete;
        }
      })
      .catch((err) => {
        if (nomFormulaire === "AdresseEmail") {
          this.soumisAdresseEmail = true;
          this.succesAdresseEmail = false;
          if (err.status === 406) {
            if (err["_body"] === "ADRESSE_MAIL_INVALIDE") {
              this.adresseMailInvalide = true;
            } else {
              this.adresseMailInvalide = false;
            }
            if (err["_body"] === "MAIL_DEJA_UTILISE") {
              this.emailExistant = true;
            } else {
              this.emailExistant = false;
            }
          }
        } else if (nomFormulaire === "AdresseEmailSecondaire") {
          this.soumisAdresseEmailSecondaire = true;
          this.succesAdresseEmailSecondaire = false;
          if (err.status === 406) {
            if (err["_body"] === "ADRESSE_MAIL_INVALIDE") {
              this.adresseMailSecondaireInvalide = true;
            } else {
              this.adresseMailSecondaireInvalide = false;
            }
          }
        } else if (nomFormulaire === "CoordonneesTelephoniques") {
          this.soumisCoordonneesTelephoniques = true;
          this.succesCoordonneesTelephoniques = false;
        } else {
          this.soumisAdressePostale = true;
          this.succesAdressePostale = false;
        }
        this.atteindreMessages("#messages" + nomFormulaire);
      });
  }

  enregistrerMotDePasse() {
    this.authentificationService
      .modifMotDePasse(
        this.modifierMotDePasseForm.controls["oldPass"].value,
        this.modifierMotDePasseForm.controls["newPass"].value,
        this.modifierMotDePasseForm.controls["newPassBis"].value
      )
      .then((succes) => {
        this.soumisMotDePasse = true;
        this.succesMotDePasse = true;
        this.atteindreMessages("#messagesMotDePasse");
      })
      .catch((err) => {
        if (err.status === 406) {
          this.ancienIncorrect = true;
        } else {
          this.ancienIncorrect = false;
        }
        this.soumisMotDePasse = true;
        this.succesMotDePasse = false;
        this.atteindreMessages("#messagesMotDePasse");
      });
  }

  formulaireDefautQualite() {
    this.mesDonneesService
      .genererFormulaireDefautQualite(this.utilisateur.identifiant)
      .then((regeneration) => {
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(
            regeneration.blob(),
            "Formulaire défaut qualité.pdf"
          );
        } else {
          const link = document.createElement("a");
          link.href = window.URL.createObjectURL(regeneration.blob());
          link.download = "Formulaire défaut qualité.pdf";
          document.body.appendChild(link);
          link.click();
        }
      });
  }

  toggleShowPassword(password: "oldPass" | "newPass" | "newPassBis") {
    this.showPassword[password] = !this.showPassword[password];
  }

  showDissocierFC() {
    this.showModalDissocierFC();
  }

  dissocierFC() {
    $("#popinDissocierFC").dialog("destroy");
    this.authentificationService
      .dissocierFC(this.utilisateur.identifiant)
      .then((value) => {
        this.hasIdFC = !value;
      });
  }

  ngOnDestroy() {
    $("#firstconnection").dialog("destroy");
    $("#popinSupprimerAdresseEmailSecondaire").dialog("destroy");
    $("#popinDissocierFC").dialog("destroy");
    this.utilisateurSubscription.unsubscribe();
  }
}
