import { Component, Input } from "@angular/core";

@Component({
  selector: "in-tab",
  templateUrl: "./tab.component.html",
  styles: [],
})
export class TabComponent {
  active: boolean;
  past: boolean;
  @Input() titre: string;
  // Si on veut utiliser un masquage du contenu des tabs par un display:none : hideMode = true
  // Si on veut utiliser un masquage du contenu des tabs par un ngIf : hideMode = false
  @Input() hideMode = false;
}
