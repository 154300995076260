import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";

import { AuthentificationService } from "./authentification.service";
import { FranceConnectService } from "./franceconnect/franceconnect.service";
import { DROITS } from "./models/droits.constants";

declare var $: any;

@Component({
  selector: "in-authentification",
  templateUrl: "./authentification.component.html",
})
export class AuthentificationComponent implements OnInit {
  loginIncorrect: boolean = false;
  sessionExpiree: boolean = false;
  compteBloque: boolean = false;
  showPassword: boolean = false;
  perteConnexion: boolean = false;
  unknownError: boolean = false;
  fcError: boolean = false;

  authentificationForm: FormGroup;

  constructor(
    private authentificationService: AuthentificationService,
    private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private franceconnectService: FranceConnectService
  ) {}

  ngOnInit() {
    if (this.authentificationService.estConnecte) {
      this.redirection();
    } else {
      this.fcError = window.sessionStorage.getItem("fcerror") == "1";
      window.sessionStorage.removeItem("fcerror");

      this.authentificationForm = this.formBuilder.group({
        identifiant: [
          "",
          Validators.compose([Validators.required, Validators.maxLength(49)]),
        ],
        motDePasse: ["", Validators.required],
      });

      this.showPassword = false;
      this.sessionExpiree = this.route.snapshot.url
        .toString()
        .endsWith("expired");

      this.authentificationForm.valueChanges.subscribe(() =>
        this.hideMessages()
      );

      this.authentificationService.expirer();
    }
  }

  fcEnabled(): boolean {
    return this.franceconnectService.isEnabled();
  }

  onSubmit(event: MouseEvent) {
    event.stopPropagation();
    this.hideMessages();

    this.authentificationService
      .connecter(
        this.authentificationForm.value["identifiant"],
        this.authentificationForm.value["motDePasse"]
      )
      .then((utilisateur) => {
        console.log(
          "[AuthentificationComponent] Utilisateur connecté",
          utilisateur
        );

        if (utilisateur.premiereConnexion) {
          localStorage.setItem(
            "tmp",
            this.authentificationForm.value["motDePasse"]
          );
          this.router.navigateByUrl("/premiereConnexion");
        } else if (utilisateur.compteBloque) {
          this.compteBloque = true;
        } else {
          console.log(`[AuthentificationComponent] Redirection.`);
          this.redirection();
        }

        return true;
      })
      .catch((err) => {
        console.warn(
          "[AuthentificationComponent] Erreur d'authentification",
          err
        );

        if (err.status === 401 && err._body === "COMPTE_BLOQUE") {
          this.compteBloque = true;
        } else if (
          err.status === 401 &&
          err._body === "UTILISATEUR_MOT_PASSE_INCONNU"
        ) {
          this.loginIncorrect = true;
        } else if (!navigator.onLine) {
          this.perteConnexion = true;
        } else {
          this.unknownError = true;
        }

        return false;
      });

    return false;
  }

  showModalInfosLoginOublie(): boolean {
    $("#popinInfosLoginOublie").dialog({
      closeText: "Fermer",
      modal: true,
    });
    return false;
  }

  showModalInfosMotDePasseOublie(): boolean {
    $("#popinInfosMotDePasseOublie").dialog({
      closeText: "Fermer",
      modal: true,
    });
    return false;
  }

  toggleShowPassword() {
    this.showPassword = !this.showPassword;
  }

  loginFC() {
    this.franceconnectService.login(this.franceconnectService.CHANNEL_PORTAL);
  }

  hideMessages() {
    this.sessionExpiree = false;
    this.compteBloque = false;
    this.loginIncorrect = false;
    this.perteConnexion = false;
    this.unknownError = false;
  }

  redirection() {
    this.authentificationService.initialiserDroits().then(() => {
      if (this.authentificationService.getDroit(DROITS.PREMIERECONNEXION)) {
        this.router.navigateByUrl("premiereConnexion");
      } else if (this.authentificationService.getDroit(DROITS.MESINFOS)) {
        this.router.navigateByUrl("mesdonnees/mesinformations");
      } else if (this.authentificationService.getDroit(DROITS.SUIVIDEMANDE)) {
        this.router.navigateByUrl("mesdonnees/suividemande");
      } else if (this.authentificationService.getDroit(DROITS.PHOTO)) {
        this.router.navigateByUrl("photo");
      } else if (this.authentificationService.getDroit(DROITS.DUPLICATA)) {
        this.router.navigateByUrl("demande");
      } else if (this.authentificationService.getDroit(DROITS.ALERTES)) {
        this.router.navigateByUrl("alertes");
      } else if (this.authentificationService.getDroit(DROITS.VEHICULES)) {
        this.router.navigateByUrl("vehicules");
      } else {
        this.router.navigateByUrl("authentification");
      }
    });
  }
}
