import { NgModule } from "@angular/core";

import { CgruComponent } from "./cgru.component";

@NgModule({
  imports: [],
  exports: [CgruComponent],
  declarations: [CgruComponent],
  providers: [],
})
export class CgruModule {}
