import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";

import { AdministrateurService } from "src/app/authentification/administrateur.service";
import { AuthentificationService } from "src/app/authentification/authentification.service";
import { environment } from "src/environments/environment";
import { Organisme } from "../../../authentification/models/organisme";
import { Utilisateur } from "../../../authentification/models/utilisateur";

@Component({
  selector: "in-header",
  templateUrl: "./header.component.html",
})
export class HeaderComponent implements OnInit {
  utilisateur: Utilisateur = null;
  organisme: Organisme = null;
  logoOrganisme: string;
  titreSite = environment.title;

  constructor(
    private authentificationService: AuthentificationService,
    private administrateurService: AdministrateurService,
    private router: Router
  ) {}

  ngOnInit() {
    this.authentificationService
      .utilisateurConnecte()
      .subscribe((utilisateur) => {
        this.utilisateur = utilisateur;

        this.administrateurService.informationsOrganisme().then((organisme) => {
          this.organisme = organisme;

          if (this.organisme && this.organisme.logo) {
            this.logoOrganisme = this.organisme.logo.startsWith("data:image")
              ? "this.organisme.logo"
              : "data:image/jpg;base64," + this.organisme.logo;
          } else {
            this.logoOrganisme = null;
          }
        });
      });
  }

  get showConnectButton(): boolean {
    const route = this.router.url;
    return !(
      route.endsWith("/authentification") ||
      route.endsWith("/authentification/expired")
    );
  }

  logout() {
    this.authentificationService.deconnecter();
  }

  skipToContent() {
    const element: HTMLElement = document.querySelector('[role="main"]');
    element.setAttribute("tabindex", "-1");
    element.focus();
  }

  skipToMenu() {
    const element: HTMLElement = document.getElementById("menu");
    element.setAttribute("tabindex", "-1");
    element.focus();
  }
}
