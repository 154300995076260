export const DROITS = {
  PHOTO: "photo",
  MESINFOS: "mesinfos",
  SUIVIDEMANDE: "suividemande",
  DUPLICATA: "duplicata",
  CONTACT: "contact",
  AIDE: "aide",
  FAQ: "faq",
  ALERTES: "alertes",
  VEHICULES: "vehicules",
  PREMIERECONNEXION: "premiereconnexion",
};
