import { Component, OnDestroy } from "@angular/core";
import { Subscription } from "rxjs";

import { AuthentificationService } from "../../authentification/authentification.service";
import { Utilisateur } from "../../authentification/models/utilisateur";
import { DemandeService } from "../demande.service";
import { STEPS } from "../enums/steps";
import { TYPE_TITRE } from "../enums/type-titre";
import { GenericStepComponent } from "../generic-step.component";
import { PanierCommandeDuplicata2ndExemplaire } from "../models/panier-commande-duplicata-2nd-exemplaire";
import { Titre } from "../models/titre";
import { TitreDuplicata2ndExemplaire } from "../models/titre-duplicata-2nd-exemplaire";

declare var $: any;

@Component({
  selector: "in-step-choix-demande",
  templateUrl: "./step-choix-demande.component.html",
  styleUrls: ["./step-choix-demande.component.scss"],
})
export class StepChoixDemandeComponent
  extends GenericStepComponent
  implements OnDestroy
{
  public titresEligiblesDuplicata: TitreDuplicata2ndExemplaire[] = null;
  public titresEligiblesSecondExemplaire: TitreDuplicata2ndExemplaire[] = null;

  public utilisateur: Utilisateur = null;
  public choixDuplicataSecondExemplaire: string = "";
  public choixValide: boolean = false;

  selectionValide: boolean = false;
  motifChoisi: boolean = false;
  soumis: boolean = false;
  engagement: boolean = false;

  TYPE_TITRE = TYPE_TITRE;

  utilisateurSubscription: Subscription;

  constructor(
    private authentificationService: AuthentificationService,
    private demandeService: DemandeService
  ) {
    super();
  }

  public loadStep() {
    this.selectionValide = false;
    this.motifChoisi = false;
    this.engagement = false;
    this.soumis = false;

    this.utilisateurSubscription = this.authentificationService
      .utilisateurConnecte()
      .subscribe((utilisateur) => {
        this.utilisateur = utilisateur;
        if (this.utilisateur != null) {
          this.demandeService
            .titreEligibleDuplicata(this.utilisateur.identifiant)
            .subscribe((titresDuplicata) => {
              if (titresDuplicata != null) {
                this.titresEligiblesDuplicata = titresDuplicata.map(
                  this.initTitreDuplicata2ndExemplaire
                );
                console.log(
                  "[StepChoixDemandeComponent] titresEligiblesDuplicata",
                  this.titresEligiblesDuplicata
                );
              }
            });

          this.demandeService
            .titreEligibleSecondExemplaire(this.utilisateur.identifiant)
            .subscribe((titresSecondEx) => {
              if (titresSecondEx != null) {
                this.titresEligiblesSecondExemplaire = titresSecondEx.map(
                  this.initTitreDuplicata2ndExemplaire
                );
                console.log(
                  "[StepChoixDemandeComponent] titresEligiblesSecondExemplaire",
                  this.titresEligiblesSecondExemplaire
                );
                this.atLeastOneSelection();
              }
            });
        }
      });
  }

  private atLeastOneSelection() {
    const titresSelectionnes: TitreDuplicata2ndExemplaire[] =
      this.titresEligiblesDuplicata.filter((element) => {
        return element.titre.selectionne;
      });
    this.selectionValide = titresSelectionnes.every((element) => {
      return element.titre.selectionne;
    });
  }

  private initTitreDuplicata2ndExemplaire(
    titre: Titre
  ): TitreDuplicata2ndExemplaire {
    const titreDuplicata2ndExemplaire = new TitreDuplicata2ndExemplaire();
    titreDuplicata2ndExemplaire.titre = titre;
    titreDuplicata2ndExemplaire.motifCommandeDuplicata = null;
    return titreDuplicata2ndExemplaire;
  }

  validerChoixDemande() {
    this.soumis = true;
    if (this.choixDuplicataSecondExemplaire) {
      this.soumis = false;
      this.choixValide = true;
    }
  }

  goToEngagement() {
    this.choixValide = false;
    this.onGoToStep.emit(STEPS.ENGAGEMENT);
  }

  goToLivraison() {
    this.soumis = true;
    if (this.choixDuplicataSecondExemplaire !== "") {
      if (
        this.choixDuplicataSecondExemplaire === "dup" &&
        this.titresEligiblesDuplicata != null
      ) {
        const titresSelectionnes: TitreDuplicata2ndExemplaire[] =
          this.titresEligiblesDuplicata.filter((element) => {
            return element.titre.selectionne;
          });
        const titresAvecMotif: TitreDuplicata2ndExemplaire[] =
          this.titresEligiblesDuplicata.filter((element) => {
            return element.motifCommandeDuplicata != null;
          });

        if (titresAvecMotif.length > 0) {
          this.motifChoisi = true;
        } else {
          this.motifChoisi = false;
        }

        if (titresSelectionnes.length > 0) {
          this.selectionValide = titresSelectionnes.every((element) => {
            return element.titre.selectionne;
          });
          this.motifChoisi = titresSelectionnes.every((element) => {
            return element.motifCommandeDuplicata != null;
          });
        } else {
          this.selectionValide = false;
        }

        if (this.motifChoisi && this.selectionValide && this.engagement) {
          this.showModalValiderDemandeDuplicata(titresSelectionnes);
        }
      } else if (
        this.choixDuplicataSecondExemplaire === "2nd" &&
        this.titresEligiblesSecondExemplaire != null
      ) {
        const titresSelectionnes: TitreDuplicata2ndExemplaire[] =
          this.titresEligiblesSecondExemplaire.filter((element) => {
            return element.titre.selectionne;
          });

        if (titresSelectionnes.length > 0) {
          this.selectionValide = true;
          this.showModalValiderDemande2ndExemplaire(titresSelectionnes);
        } else {
          this.selectionValide = false;
        }
      }
    }
  }

  showModalValiderDemandeDuplicata(titres: TitreDuplicata2ndExemplaire[]) {
    const composant: StepChoixDemandeComponent = this;
    $("#popinValiderDemandeDuplicata").dialog({
      resizable: false,
      height: "auto",
      width: 400,
      modal: true,
      buttons: {
        Oui: function () {
          $(this).dialog("close");
          const panier: PanierCommandeDuplicata2ndExemplaire =
            new PanierCommandeDuplicata2ndExemplaire();
          panier.typeCommande = "DUPLICATA";
          panier.titres = titres;

          composant.demandeService.setPanier(panier);
          composant.onGoToStep.emit(STEPS.LIVRAISON);
        },
        Non: function () {
          $(this).dialog("close");
        },
      },
    });
  }

  atLeastOneMotif() {
    const titresSelectionnes: TitreDuplicata2ndExemplaire[] =
      this.titresEligiblesDuplicata.filter((element) => {
        return element.titre.selectionne;
      });
    this.motifChoisi = titresSelectionnes.every((element) => {
      return element.motifCommandeDuplicata != null;
    });
  }

  showModalValiderDemande2ndExemplaire(titres: TitreDuplicata2ndExemplaire[]) {
    const composant: StepChoixDemandeComponent = this;
    $("#popinValiderDemande2ndExemplaire").dialog({
      resizable: false,
      height: "auto",
      width: 450,
      modal: true,
      buttons: {
        Oui: function () {
          $(this).dialog("close");
          const panier: PanierCommandeDuplicata2ndExemplaire =
            new PanierCommandeDuplicata2ndExemplaire();
          panier.typeCommande = "SECOND_EXEMPLAIRE";
          panier.titres = titres;

          composant.demandeService.setPanier(panier);
          composant.onGoToStep.emit(STEPS.LIVRAISON);
        },
        Non: function () {
          $(this).dialog("close");
        },
      },
    });
  }

  ngOnDestroy() {
    if (this.utilisateurSubscription)
      this.utilisateurSubscription.unsubscribe();
  }
}
