import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";

import { AuthentificationService } from "../authentification.service";
import { Utilisateur } from "../models/utilisateur";
import { FranceConnectService } from "./franceconnect.service";

@Component({
  selector: "fc-mail",
  templateUrl: "./fc-mail.component.html",
})
export class FCMailComponent implements OnInit {
  utilisateur: Utilisateur = null;
  fcMail: string;
  actionForMail: string;
  principal: boolean = false;
  secondaire: boolean = false;
  errorOne: boolean = false;

  constructor(
    private franceconnectService: FranceConnectService,
    private authentificationService: AuthentificationService,
    private router: Router
  ) {}

  ngOnInit() {
    // get user
    this.authentificationService
      .utilisateurConnecte()
      .subscribe((utilisateur) => {
        this.utilisateur = utilisateur;
      });

    // get data
    this.fcMail = sessionStorage.getItem("fc_mail");
    this.actionForMail = sessionStorage.getItem("actionForMail");
    if (!this.fcMail || !this.actionForMail) {
      this.router.navigateByUrl("mesdonnees/mesinformations");
    } else if (
      this.franceconnectService.ACTIONFORMAIL_LIST.indexOf(
        this.actionForMail
      ) == -1
    ) {
      //TODO à tester
      this.router.navigateByUrl("mesdonnees/mesinformations");
    }
  }

  isMain(): boolean {
    return this.actionForMail == this.franceconnectService.ACTIONFORMAIL_MAIN;
  }

  isSecondary(): boolean {
    return (
      this.actionForMail == this.franceconnectService.ACTIONFORMAIL_SECONDARY
    );
  }

  isOne(): boolean {
    return this.actionForMail == this.franceconnectService.ACTIONFORMAIL_ONE;
  }

  setPrincipal() {
    this.principal = true;
    this.secondaire = false;
  }

  setSecondaire() {
    this.principal = false;
    this.secondaire = true;
  }

  acceptUpdate() {
    this.errorOne = this.isOne() && !this.principal && !this.secondaire;
    if (!this.errorOne) {
      let setPrincipal = this.isMain() || (this.isOne() && this.principal);
      this.authentificationService.updateMail(
        this.utilisateur.identifiant,
        this.fcMail,
        setPrincipal
      );
      sessionStorage.removeItem("fc_mail");
      sessionStorage.removeItem("actionForMail");
      this.router.navigateByUrl("mesdonnees/mesinformations");
    }
  }

  refuseUpdate() {
    sessionStorage.removeItem("fc_mail");
    sessionStorage.removeItem("actionForMail");
    this.router.navigateByUrl("mesdonnees/mesinformations");
  }
}
