import { Component, OnDestroy, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Subscription } from "rxjs";

import { AuthentificationService } from "../../authentification/authentification.service";
import { Utilisateur } from "../../authentification/models/utilisateur";
import { DemandeService } from "../demande.service";
import { TYPE_TITRE } from "../enums/type-titre";
import { GenericStepComponent } from "../generic-step.component";
import { ReponseCommandeDuplicata2ndExemplaire } from "../models/reponse-commande-duplicata-2nd-exemplaire";

@Component({
  selector: "in-step-recapitulatif",
  templateUrl: "./step-recapitulatif.component.html",
})
export class StepRecapitulatifComponent
  extends GenericStepComponent
  implements OnInit, OnDestroy
{
  private numeroIdCommande: string;
  public commandeDuplicata: ReponseCommandeDuplicata2ndExemplaire = null;
  public utilisateur: Utilisateur = null;

  public paiementAnnule: boolean = false;
  public afficherBoutonDownloadAttestation: boolean = false;

  TYPE_TITRE = TYPE_TITRE;

  utilisateurSubscription: Subscription;

  constructor(
    private router: Router,
    private authentificationService: AuthentificationService,
    private demandeService: DemandeService
  ) {
    super();
  }

  public ngOnInit() {
    console.log("-------[StepRecapitulatifComponent][ngOnInit] getIdentifiantCommande");
    //if (!this.demandeService.getPanier()) this.router.navigateByUrl("/demande");

    this.commandeDuplicata = new ReponseCommandeDuplicata2ndExemplaire();

    this.utilisateurSubscription = this.authentificationService
      .utilisateurConnecte()
      .subscribe((utilisateur) => {
        this.utilisateur = utilisateur;
      });
  }

  public loadStep() {
    // This is intentional
    console.log("-------[StepRecapitulatifComponent][loadStep]");
  }

  public loadDataCommande(numeroIdCommandeDuplicata: string) {
    this.numeroIdCommande = numeroIdCommandeDuplicata;
    if (this.numeroIdCommande === "annule") {
      this.paiementAnnule = true;
    } else {
      this.demandeService
        .getCommande(this.numeroIdCommande)
        .subscribe((reponseCommandeDuplicata) => {
          if (reponseCommandeDuplicata != null) {
            this.commandeDuplicata = reponseCommandeDuplicata;
            console.log(
              "[StepRecapitulatifComponent] commandeDuplicata",
              this.commandeDuplicata
            );
          }
        });
    }
  }

  public recommencer() {
    this.router.navigateByUrl("/demande");
  }

  public downloadAttestation() {
    this.demandeService
      .getAttestation(this.commandeDuplicata.numeroIdCommande)
      .then((reponseAttestation) => {
        console.log(
          "[StepRecapitulatifComponent] downloadAttestation",
          reponseAttestation
        );

        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(
            reponseAttestation.blob(),
            "attestationDemande.pdf"
          );
        } else {
          const link = document.createElement("a");
          link.href = window.URL.createObjectURL(reponseAttestation.blob());
          link.download = "attestationDemande.pdf";
          document.body.appendChild(link);
          link.click();

          // On libère la mémoire après 1s
          setTimeout(() => {
            document.body.removeChild(link);
            window.URL.revokeObjectURL(link.href);
          }, 1000);
        }
      });
  }

  ngOnDestroy() {
    this.utilisateurSubscription.unsubscribe();
  }
}
