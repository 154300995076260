import { Directive, forwardRef, Attribute } from "@angular/core";
import {
  Validator,
  AbstractControl,
  NG_VALIDATORS,
  ValidatorFn,
} from "@angular/forms";

/*
 * Méthode de validation.
 */
export function passwordValidator(): ValidatorFn {
  return function (c: AbstractControl): { [key: string]: boolean } {
    const v = c.value;
    if (v) {
      let res = true;

      /* Au moins 1 maj, 1 min, 1 chiffre ; uniquement des maj/min/chiffres (pas de spéciaux) ; minimum 12 caractères */
      if (!v.match(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])[A-Za-z0-9]{12,}$/)) {
        res = false;
      }

      if (!res) {
        return {
          validatePassword: true,
        };
      }
    }
    return null;
  };
}

@Directive({
  selector:
    "[password][formControlName],[password][formControl],[password][ngModel]",
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => PasswordValidator),
      multi: true,
    },
  ],
})
export class PasswordValidator implements Validator {
  constructor(@Attribute("password") public equals: string) {}

  validate(c: AbstractControl): { [key: string]: boolean } {
    return passwordValidator()(c);
  }
}
