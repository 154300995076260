import {
  Directive,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  Renderer,
} from "@angular/core";
import { Subscription } from "rxjs/Subscription";
import { AuthentificationService } from "src/app/authentification/authentification.service";

@Directive({
  selector: "[inADroit]",
})
export class ADroitDirective implements OnInit, OnDestroy {
  @Input("inADroit") droit: string;

  private droitSubscription: Subscription = null;

  constructor(
    private el: ElementRef,
    private renderer: Renderer,
    private authentificationService: AuthentificationService
  ) {}

  ngOnInit() {
    this.droitSubscription = this.authentificationService
      .droit(this.droit)
      .subscribe(this.afficher.bind(this));
  }

  ngOnDestroy() {
    this.droitSubscription.unsubscribe();
  }

  afficher(droit: boolean) {
    const display = droit ? null : "none";
    this.renderer.setElementStyle(this.el.nativeElement, "display", display);
  }
}
