import { Component, OnInit } from "@angular/core";

import { SpinnerGeneralService } from "./spinner-general.service";

@Component({
  selector: "in-spinner-general",
  templateUrl: "./spinner-general.component.html",
})
export class SpinnerGeneralComponent implements OnInit {
  visible: boolean;

  constructor(private spinnerGeneralService: SpinnerGeneralService) {
    this.visible = false;
  }

  ngOnInit() {
    this.spinnerGeneralService.visible.subscribe((v) => {
      this.visible = v.show;
    });
  }
}
