import { Injectable } from "@angular/core";
import { Http, ResponseContentType } from "@angular/http";
import { Observable } from "rxjs/Observable";

import { Beneficiaire } from "./models/beneficiaire";
import { SuiviDemandeTitre } from "./models/suivi-demande-titre";

@Injectable()
export class MesDonneesService {
  constructor(private http: Http) {}

  /**
   * Méthode à appeler pour récupérer les informations du bénéficiaire
   */
  informationsBeneficiaire(
    identifiantBeneficiaire: string
  ): Promise<Beneficiaire> {
    console.log(
      "[MesDonneesService] informationsBeneficiaire",
      identifiantBeneficiaire
    );
    return this.http
      .get(`/api/organismeBeneficiaire/beneficiaire/${identifiantBeneficiaire}`)
      .toPromise()
      .then((response) => response.json() as Beneficiaire);
  }

  /**
   * Méthode à appeler pour modifier les informations du bénéficiaire
   */
  public modifyBeneficiaire(
    beneficiaire: Beneficiaire,
    idBeneficiaire
  ): Promise<boolean> {
    console.log(
      `[MesDonneesService] Modification du beneficiaire ${beneficiaire.nom}`,
      beneficiaire
    );
    return new Promise((resolve, reject) => {
      this.http
        .put(
          `/api/organismeBeneficiaire/beneficiaire/${idBeneficiaire}`,
          beneficiaire
        )
        .toPromise()
        .then((response) => resolve(response.status === 200))
        .catch(reject);
    });
  }

  /**
   * Méthode à appeler pour générer le formulaire de défaut qualité
   */
  genererFormulaireDefautQualite(identifiant: string) {
    console.log(
      `[MesDonneesService] Generer formulaire defaut qualite identifiant`,
      identifiant
    );
    return this.http
      .get(
        `/api/organismeBeneficiaire/beneficiaire/genererFormulaireDefautQualite/${identifiant}`,
        {
          responseType: ResponseContentType.Blob,
        }
      )
      .toPromise();
  }

  /**
   * Méthode à appeler pour récupérer l'historique des statuts d'avancement des demandes de titre du bénéficiaire
   */
  titresBeneficiaire(
    identifiantBeneficiaire: string
  ): Observable<SuiviDemandeTitre[]> {
    console.log(
      "[MesDonneesService] titresBeneficiaire",
      identifiantBeneficiaire
    );
    return this.http
      .get(
        `/api/organismeBeneficiaire/titre/beneficiaire/${identifiantBeneficiaire}`
      )
      .map((response) => response.json() as SuiviDemandeTitre[]);
  }
}
