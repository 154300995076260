import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

import { MessagesModule } from "../common/components/messages/messages.module";
import { TabsModule } from "../common/components/tabs/tabs.module";
import { ADroitModule } from "../common/directives/a-droit/a-droit.module";
import { TooltipModule } from "../common/directives/tooltip/tooltip.module";
import { MomentPipe } from "../common/pipes/moment.pipe";
import { DemandeComponent } from "./demande.component";
import { DemandeService } from "./demande.service";
import { StepChoixDemandeComponent } from "./step-choix-demande/step-choix-demande.component";
import { StepEngagementComponent } from "./step-engagement/step-engagement.component";
import { StepLivraisonComponent } from "./step-livraison/step-livraison.component";
import { StepPaiementComponent } from "./step-paiement/step-paiement.component";
import { StepRecapitulatifComponent } from "./step-recapitulatif/step-recapitulatif.component";

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TooltipModule,
    MessagesModule,
    TabsModule,
    ADroitModule,
  ],
  exports: [
    DemandeComponent,
    StepEngagementComponent,
    StepChoixDemandeComponent,
    StepLivraisonComponent,
    StepPaiementComponent,
    StepRecapitulatifComponent,
  ],
  declarations: [
    DemandeComponent,
    StepEngagementComponent,
    StepChoixDemandeComponent,
    StepLivraisonComponent,
    StepPaiementComponent,
    StepRecapitulatifComponent,
    MomentPipe,
  ],
  providers: [DemandeService],
})
export class DemandeModule {}
