import { CommonModule } from "@angular/common";
import { HttpClientModule } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { Http, RequestOptions, XHRBackend } from "@angular/http";
import { Router, RouterModule } from "@angular/router";

import { CanActivateADroitGuard } from "../common/directives/a-droit/a-droit.guard";
import { TooltipModule } from "../common/directives/tooltip/tooltip.module";
import { AdministrateurService } from "./administrateur.service";
import { AuthentificationComponent } from "./authentification.component";
import { AuthentificationService } from "./authentification.service";
import { FCAuthorizeMobileComponent } from "./franceconnect/fc-authorize-mobile.component";
import { FCLoginCallbackComponent } from "./franceconnect/fc-login-callback.component";
import { FCLogoutCallbackComponent } from "./franceconnect/fc-logout-callback.component";
import { FCLogoutMobileComponent } from "./franceconnect/fc-logout-mobile.component";
import { FCMailComponent } from "./franceconnect/fc-mail.component";
import { FCNumeroTitreComponent } from "./franceconnect/fc-numerotitre.component";
import { FranceConnectService } from "./franceconnect/franceconnect.service";
import { LoginOublieComponent } from "./login-oublie/login-oublie.component";
import { MotDePasseOublieComponent } from "./mot-de-passe-oublie/mot-de-passe-oublie.component";
import { NouveauMotPasseComponent } from "./nouveau-mot-de-passe/nouveau-mot-passe.component";
import { PremiereConnexionComponent } from "./premiere-connexion/premiere-connexion.component";
import { PropertiesService } from "./properties.service";
import { SecuredHttpService } from "./secured-http.service";
import { CanActivateUtilisateurConnecteGuard } from "./utilisateur-connecte.guard";

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    TooltipModule,
  ],
  exports: [
    AuthentificationComponent,
    LoginOublieComponent,
    MotDePasseOublieComponent,
    NouveauMotPasseComponent,
    PremiereConnexionComponent,
  ],
  declarations: [
    AuthentificationComponent,
    FCAuthorizeMobileComponent,
    FCLoginCallbackComponent,
    FCLogoutCallbackComponent,
    FCLogoutMobileComponent,
    FCMailComponent,
    FCNumeroTitreComponent,
    LoginOublieComponent,
    MotDePasseOublieComponent,
    NouveauMotPasseComponent,
    PremiereConnexionComponent,
  ],
  providers: [
    {
      provide: Http,
      useFactory: (
        backend: XHRBackend,
        defaultOptions: RequestOptions,
        router: Router
      ) => {
        return new SecuredHttpService(backend, defaultOptions, router);
      },
      deps: [XHRBackend, RequestOptions, Router],
    },
    AdministrateurService,
    AuthentificationService,
    PropertiesService,
    FranceConnectService,
    CanActivateADroitGuard,
    CanActivateUtilisateurConnecteGuard,
  ],
})
export class AuthentificationModule {}
