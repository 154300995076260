import { Component, OnInit } from "@angular/core";

@Component({
  selector: "in-haut-de-page",
  templateUrl: "./haut-de-page.component.html",
  styleUrls: ["./haut-de-page.component.scss"],
})
export class HautDePageComponent implements OnInit {
  visible: boolean = false;

  constructor() {}

  ngOnInit() {
    window.addEventListener("scroll", () => {
      const scroll = window.scrollY || document.documentElement.scrollTop;
      this.visible = scroll > 32;
    });
  }

  scrollToTop() {
    window.scrollTo({ top: 0 });
  }
}
