import { Component, OnDestroy, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { Http } from "@angular/http";
import { Router } from "@angular/router";
import { Subscription } from "rxjs";

import { AuthentificationService } from "../../authentification/authentification.service";
import { Utilisateur } from "../../authentification/models/utilisateur";
import { DemandeService } from "../demande.service";
import { STEPS } from "../enums/steps";
import { GenericStepComponent } from "../generic-step.component";
import { PanierCommandeDuplicata2ndExemplaire } from "../models/panier-commande-duplicata-2nd-exemplaire";
import { ReponseCalculCommandeDuplicata2ndExemplaire } from "../models/reponse-calcul-commande-duplicata-2nd-exemplaire";

declare var $: any;

@Component({
  selector: "in-step-paiement",
  templateUrl: "./step-paiement.component.html",
})
export class StepPaiementComponent
  extends GenericStepComponent
  implements OnInit, OnDestroy
{
  public utilisateur: Utilisateur = null;

  private panier: PanierCommandeDuplicata2ndExemplaire = null;
  public calcul: ReponseCalculCommandeDuplicata2ndExemplaire = null;

  private paiementChequeForm: FormGroup;
  public activationMoyenPaiementCheque: boolean = true;

  utilisateurSubscription: Subscription;

  constructor(
    private router: Router,
    private http: Http,
    private authentificationService: AuthentificationService,
    private demandeService: DemandeService
  ) {
    super();
  }

  ngOnInit() {
    if (this.activationMoyenPaiementCheque) {
      this.paiementChequeForm = new FormGroup({
        nomBanque: new FormControl(
          "",
          Validators.compose([
            Validators.required,
            Validators.maxLength(40),
            Validators.pattern(
              "[0-9A-Za-z! \"$%&'()*+,-./:<=>?@[\\]\\\\^_{|}~ÀÁÂÃÄÅÆÇÈÉÊËÌÍÎÏÐÑÒÓÔÕÖ×ØÙÚÛÜÝÞßŠŒŸŽàáâãäåæçèéêëìíîïðñòóôõöøùúûüýþÿšœž€‚ƒ„…†‡ˆ‰‹‘'“”•–—˜™›¡¢£¤¥¦§¨©ª«¬¬®¯°±²³´µ¶•¸¹º»¼½¾¿÷]*"
            ),
          ])
        ),
        titulaireCompte: new FormControl(
          "",
          Validators.compose([
            Validators.required,
            Validators.maxLength(40),
            Validators.pattern(
              "[0-9A-Za-z! \"$%&'()*+,-./:<=>?@[\\]\\\\^_{|}~ÀÁÂÃÄÅÆÇÈÉÊËÌÍÎÏÐÑÒÓÔÕÖ×ØÙÚÛÜÝÞßŠŒŸŽàáâãäåæçèéêëìíîïðñòóôõöøùúûüýþÿšœž€‚ƒ„…†‡ˆ‰‹‘'“”•–—˜™›¡¢£¤¥¦§¨©ª«¬¬®¯°±²³´µ¶•¸¹º»¼½¾¿÷]*"
            ),
          ])
        ),
        numeroCheque: new FormControl(
          "",
          Validators.compose([
            Validators.required,
            Validators.maxLength(7),
            Validators.pattern("[0-9]*"),
          ])
        ),
      });
    }
  }

  public loadStep() {
    this.calcul = new ReponseCalculCommandeDuplicata2ndExemplaire();
    this.panier = this.demandeService.getPanier();
    this.initSoumissionForm();

    this.utilisateurSubscription = this.authentificationService
      .utilisateurConnecte()
      .subscribe((utilisateur) => {
        this.utilisateur = utilisateur;
        if (this.utilisateur != null) {
          this.demandeService
            .calculerMontantCommande(this.utilisateur.identifiant, this.panier)
            .then((reponseCalcul) => {
              this.calcul = reponseCalcul;
              console.log(
                "[StepPaiementComponent] reponseCalcul",
                reponseCalcul
              );
            })
            .catch((err) => {
              console.log("[StepPaiementComponent] Erreur reponseCalcul", err);
              this.router.navigateByUrl("/erreurService");
            });
        }
      });
  }

  private initSoumissionForm() {
    if (this.activationMoyenPaiementCheque) {
      this.paiementChequeForm = new FormGroup({
        nomBanque: new FormControl(
          this.panier.nomBanque,
          Validators.compose([
            Validators.required,
            Validators.maxLength(49),
            Validators.pattern(
              "[0-9A-Za-z! \"$%&'()*+,-./:<=>?@[\\]\\\\^_{|}~ÀÁÂÃÄÅÆÇÈÉÊËÌÍÎÏÐÑÒÓÔÕÖ×ØÙÚÛÜÝÞßŠŒŸŽàáâãäåæçèéêëìíîïðñòóôõöøùúûüýþÿšœž€‚ƒ„…†‡ˆ‰‹‘'“”•–—˜™›¡¢£¤¥¦§¨©ª«¬¬®¯°±²³´µ¶•¸¹º»¼½¾¿÷]*"
            ),
          ])
        ),
        titulaireCompte: new FormControl(
          this.panier.titulaireCompte,
          Validators.compose([
            Validators.required,
            Validators.maxLength(49),
            Validators.pattern(
              "[0-9A-Za-z! \"$%&'()*+,-./:<=>?@[\\]\\\\^_{|}~ÀÁÂÃÄÅÆÇÈÉÊËÌÍÎÏÐÑÒÓÔÕÖ×ØÙÚÛÜÝÞßŠŒŸŽàáâãäåæçèéêëìíîïðñòóôõöøùúûüýþÿšœž€‚ƒ„…†‡ˆ‰‹‘'“”•–—˜™›¡¢£¤¥¦§¨©ª«¬¬®¯°±²³´µ¶•¸¹º»¼½¾¿÷]*"
            ),
          ])
        ),
        numeroCheque: new FormControl(
          this.panier.numeroCheque,
          Validators.compose([
            Validators.required,
            Validators.maxLength(7),
            Validators.pattern("[0-9]*"),
          ])
        ),
      });
      this.paiementChequeForm.markAsPristine();
    }
  }

  goToLivraison() {
    this.onGoToStep.emit(STEPS.LIVRAISON);
  }

  goToPaiementCarteBancaire() {
    this.panier.modeReglement = "CB";

    this.http
      .post(
        `/api/front/effectuerPaiement/${this.utilisateur.identifiant}`,
        this.panier
      )
      .subscribe((reponsePaiement) => {
        console.log("[StepPaiementComponent] reponsePaiement", reponsePaiement);
        if (reponsePaiement.status === 200) {
          const html = $.parseHTML(reponsePaiement["_body"]);

          $("body").empty();
          $("body").append(html);
          $('form[name="formReturnAWL"]').submit();
        }
      });
  }

  goToPaiementChequeBancaire() {
    this.panier.modeReglement = "CHEQUE";

    if (this.paiementChequeForm.valid) {
      this.panier.nomBanque = this.paiementChequeForm.value.nomBanque;
      this.panier.titulaireCompte =
        this.paiementChequeForm.value.titulaireCompte;
      this.panier.numeroCheque = this.paiementChequeForm.value.numeroCheque;

      this.demandeService
        .effectuerPaiementCheque(this.utilisateur.identifiant, this.panier)
        .then((reponsePaiement) => {
          console.log(
            "[StepPaiementComponent] reponsePaiement",
            reponsePaiement
          );

          this.demandeService
            .couponReglementCheque(reponsePaiement.numeroIdCommande)
            .then((reponseCoupon) => {
              console.log(
                "[StepPaiementComponent] reponseCoupon",
                reponseCoupon
              );

              if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                window.navigator.msSaveOrOpenBlob(
                  reponseCoupon.blob(),
                  "couponReglementCheque.pdf"
                );
                this.router.navigate([
                  `/demande/${reponsePaiement.numeroIdCommande}`,
                ]);
              } else {
                const link = document.createElement("a");
                link.href = window.URL.createObjectURL(reponseCoupon.blob());
                link.download = "couponReglementCheque.pdf";
                document.body.appendChild(link);
                link.click();

                // On libère la mémoire après 1s
                setTimeout(() => {
                  document.body.removeChild(link);
                  window.URL.revokeObjectURL(link.href);

                  this.router.navigate([
                    `/demande/${reponsePaiement.numeroIdCommande}`,
                  ]);
                }, 1000);
              }
            });
        });
    } else {
      // Pour afficher les messages d'erreur en cas de formulaire vide
      for (const control in this.paiementChequeForm.controls) {
        if (typeof control === "string") {
          this.paiementChequeForm.controls[control].markAsTouched();
        }
      }
    }
  }

  ngOnDestroy() {
    if (this.utilisateurSubscription)
      this.utilisateurSubscription.unsubscribe();
  }
}
