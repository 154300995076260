import { TitreDuplicata2ndExemplaire } from "./titre-duplicata-2nd-exemplaire";

export class Enumeration {
  valeur: string;
}

export class ReponseCommandeDuplicata2ndExemplaire {
  numeroIdCommande: number;
  titres: TitreDuplicata2ndExemplaire[];
  montantTotalCommande: number;
  statutCommande: Enumeration;
  motifRejet: string;
}
