import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

import { MessagesModule } from "../common/components/messages/messages.module";
import { VehiculesComponent } from "./vehicules.component";
import { VehiculesService } from "./vehicules.service";

@NgModule({
  imports: [CommonModule, FormsModule, ReactiveFormsModule, MessagesModule],
  exports: [VehiculesComponent],
  declarations: [VehiculesComponent],
  providers: [VehiculesService],
})
export class VehiculesModule {}
