import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";

import { MessagesModule } from "../common/components/messages/messages.module";
import { AlertesComponent } from "./alertes.component";
import { AlertesService } from "./alertes.service";

@NgModule({
  imports: [CommonModule, RouterModule, MessagesModule],
  exports: [AlertesComponent],
  declarations: [AlertesComponent],
  providers: [AlertesService],
})
export class AlertesModule {}
