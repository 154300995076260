import { Adresse } from "../../common/models/adresse";

export class Beneficiaire {
  nom: string;
  prenom: string;
  physique: boolean;
  dateNaissance: string;
  nomNaissance: string;
  lieuNaissance: string;
  adresse: Adresse;
  adresseLivraison: Adresse;
  adresseIdentique: boolean;
  immatriculation: string;
  email: string;
  titreDisponible: boolean;
  numeroIdentifiant: string;
  raisonSociale: string;
  emailSecondaire: string;
  numeroTelephoneMobile: string;
  numeroTelephoneFixe: string;
  franceConnectId: string;
}
