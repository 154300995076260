import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";

import { AideComponent } from "./aide.component";
import { AideService } from "./aide.service";

@NgModule({
  imports: [CommonModule],
  exports: [AideComponent],
  declarations: [AideComponent],
  providers: [AideService],
})
export class AideModule {}
