import { Component, OnDestroy, OnInit } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { Subscription } from "rxjs";
import { TimerObservable } from "rxjs/observable/TimerObservable";

import { environment } from "src/environments/environment";
import { AuthentificationService } from "./authentification/authentification.service";
import { FranceConnectService } from "./authentification/franceconnect/franceconnect.service";

@Component({
  selector: "in-beneficiaire",
  templateUrl: "./beneficiaire.component.html",
})
export class BeneficiaireComponent implements OnInit, OnDestroy {
  tokenSubscription: Subscription;

  constructor(
    private authentificationService: AuthentificationService,
    private franceconnectService: FranceConnectService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private title: Title
  ) {}

  ngOnInit() {
    this.franceconnectService.loadProps();

    this.authentificationService.initialiserDroits().then(() => {
      this.authentificationService
        .utilisateurConnecte()
        .subscribe((utilisateur) => {
          if (this.tokenSubscription) {
            this.tokenSubscription.unsubscribe();
          }

          if (utilisateur) {
            this.tokenSubscription = TimerObservable.create(
              60000,
              60000
            ).subscribe(() =>
              this.authentificationService.validerTokenPeriodique()
            );
          }
        });
    });

    this.router.events
      .filter((event) => event instanceof NavigationEnd)
      .map(() => {
        let route = this.activatedRoute.snapshot;

        while (route.firstChild) {
          route = route.firstChild;
        }

        return route.data["breadcrumb"];
      })
      .subscribe((title: string) => {
        if (title) this.title.setTitle(`${title} | ${environment.title}`);
      });
  }

  ngOnDestroy() {
    this.tokenSubscription.unsubscribe();
  }
}
