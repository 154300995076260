import { NgModule } from "@angular/core";

import { CguComponent } from "./cgu.component";

@NgModule({
  imports: [],
  exports: [CguComponent],
  declarations: [CguComponent],
  providers: [],
})
export class CguModule {}
