import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

import { MessagesModule } from "../common/components/messages/messages.module";
import { SpinnerGeneralModule } from "../common/components/spinner-general/spinner-general.module";
import { ImageService } from "./image.service";
import { PhotoComponent } from "./photo.component";
import { PhotoService } from "./photo.service";

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MessagesModule,
    SpinnerGeneralModule,
  ],
  exports: [PhotoComponent],
  declarations: [PhotoComponent],
  providers: [PhotoService, ImageService],
})
export class PhotoModule {}
