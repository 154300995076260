import { NgModule } from "@angular/core";

import { TooltipDirective } from "./tooltip.directive";

@NgModule({
  imports: [],
  exports: [TooltipDirective],
  declarations: [TooltipDirective],
  providers: [],
})
export class TooltipModule {}
