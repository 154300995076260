import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";

import { TabsModule } from "../common/components/tabs/tabs.module";
import { FaqComponent } from "./faq.component";
import { FaqService } from "./faq.service";

@NgModule({
  imports: [CommonModule, TabsModule],
  exports: [FaqComponent],
  declarations: [FaqComponent],
  providers: [FaqService],
})
export class FaqModule {}
