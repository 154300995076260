export const PaysEnum: Array<{ label: string; code: string }> = [
  { label: "ALLEMAGNE", code: "D" },
  { label: "ANDORRE", code: "AND" },
  { label: "BELGIQUE", code: "B" },
  { label: "ESPAGNE", code: "E" },
  { label: "FRANCE", code: "F" },
  { label: "ITALIE", code: "I" },
  { label: "LUXEMBOURG", code: "L" },
  { label: "MONACO", code: "MC" },
  { label: "PORTUGAL", code: "P" },
  { label: "SUISSE", code: "CH" },
];
