import { Adresse } from "../../common/models/adresse";
import { TitreDuplicata2ndExemplaire } from "./titre-duplicata-2nd-exemplaire";

export class PanierCommandeDuplicata2ndExemplaire {
  typeCommande: string;
  titres: Array<TitreDuplicata2ndExemplaire>;
  adresseLivraison: Adresse;
  modeReglement: string;
  nomBanque: string;
  titulaireCompte: string;
  numeroCheque: string;
}
